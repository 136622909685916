import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'; 


@Component({

	templateUrl: './privacypolicy.component.html' 
})
export class PrivacyPolicyComponent implements OnInit { 
	constructor( ) {  
    }

    ngOnInit() { 
console.log("Hellow")
	}
}