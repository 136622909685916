export class Choice {
    constructor(
        public ChoiceID?: string,
        public ChoiceName?: string,
        public IsActiveChoice?: boolean,
        public IsReadOnly?: boolean,
        public ReportKind?: string,
        public ClientDiesAtAge?: Number,
        public SpouseDiesAtAge?: Number,
        public ReportTitleLine1?: string,
        public ReportTitleLine2?: string,
        public ReportTitleLine3?: string,
        public ModelStartYear?: string,
        public ModelEndYear?: string,
        public ViewStartYear?: string,
        public ViewEndYear?: string,
        public NumYearsInView?: string,
        public DisplayOrder?: Number,
    ) {}
}