import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LandingComponent } from './landing/landing.component';
import { HomeComponent } from './home/home.component';
import { FP4FAComponent } from './fp4fa/fp4fa.component';
import { PrivacyPolicyComponent  } from './privacypolicy/privacypolicy.component';
import { FTAStrategyComponent } from './ftastrategy/ftastrategy.component'; 
import { BlendComponent } from './blend/blend.component';
import { ReportsComponent } from './reports/reports.component';
import { IndexBlendComponent } from './index-blend/index-blend.component';
import { TickerBlendComponent } from './ticker-blend/ticker-blend.component';
import { MondayAllocationRequestComponent } from './mondayallocationrequest/mondayallocationrequest.component';
import { MondayDepositWithdrawCashComponent } from './mondaydepositwithdrawcash/mondaydepositwithdrawcash.component';

import { CompanyConfigurationComponent } from './fp4fa/company-configuration/company-configuration.component';
import { ExpenseComponent } from './fp4fa/expense/expense.component';
import { RevenueComponent } from './fp4fa/revenue/revenue.component';
import { MarketingProductionComponent } from './fp4fa/marketing-production/marketing-production.component';
import { DashboardComponent } from './fp4fa/dashboard/dashboard.component';
import { Landingfp4faComponent } from './fp4fa/landingfp4fa/landingfp4fa.component';

const routes: Routes = [
	{ path: '', component: LoginComponent, data: { allowed: false } },
	{ path: 'landing', component: LandingComponent, data: { allowed: false } },
	{ path: 'home', component: HomeComponent, data: { allowed: false } },
	{ path: 'privacypolicy', component: PrivacyPolicyComponent, data: { allowed: true } },
	{ path: 'FTAStrategies', component: FTAStrategyComponent, data: { allowed: true } },
	{ path: 'Blends', component: BlendComponent, data: { allowed: true } },
	{ path: 'MyBlends', component: BlendComponent, data: { allowed: true } },
	{ path: 'IndexBlends', component: IndexBlendComponent, data: { allowed: true } },
	{ path: 'MyIndexBlends', component: IndexBlendComponent, data: { allowed: true } },
	{ path: 'TickerBlends', component: TickerBlendComponent, data: { allowed: true } },
	{ path: 'MyTickerBlends', component: TickerBlendComponent, data: { allowed: true } },
	{ path: 'RiskGraphReports', component: ReportsComponent, data: { allowed: true } },
	{ path: 'InvestmentReports', component: ReportsComponent, data: { allowed: true } },
	{ path: 'ArchivedReports', component: ReportsComponent, data: { allowed: true } },
	{ path: 'MondayAllocationRequest', component: MondayAllocationRequestComponent, data: { allowed: true } },
	{ path: 'MondayDepositWithdrawCash', component: MondayDepositWithdrawCashComponent, data: { allowed: true } },
	{ path: 'fp4fa', component: FP4FAComponent, data: { allowed: false } },
	{ path: 'landingfp4fa', component: Landingfp4faComponent, data: { allowed: false } },
	{ path: 'company-configuration', component: CompanyConfigurationComponent, data: { allowed: false } },
	{ path: 'expense', component: ExpenseComponent, data: { allowed: false } },
	{ path: 'revenue', component: RevenueComponent, data: { allowed: false } },
	{ path: 'marketing-production', component: MarketingProductionComponent, data: { allowed: false } },
	{ path: 'dashboard', component: DashboardComponent, data: { allowed: false } }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
	public static getRoutingPaths(): Route[] {
		return routes
	}
}
