export class Blend {
    constructor( 
        public BlendID?: string,
        public BlendName?: string,
        public RebalanceFrequency?: string,
        public BeginDate?: string,
        public IsMyBlend?: any,
        public ProductCount?: any,
        public CreatedBy?: string,
        public CreatedOn?: Date,
        public bDeleted?: any,
        public DeletedBy?: string,
        public DeletedOn?: Date, 
        public BlendDetail?: any
    ) {}
}

export class BlendDetail {
    constructor( 
        public BlendDetailID?: string,
        public BlendID?: string,
        public FTAStrategyID?: string,
        public ProductName?: string,
        public ProductBeginDate?: string,
        public Allocation?: any
    ) {}
}
