import { Pipe, PipeTransform } from '@angular/core';
import { BusinessCalculatorInput } from '../models/business-calculator-input.model';

@Pipe({
	name: 'businessplanFilter'
})
export class BusinessPlanFilterPipe implements PipeTransform {

	transform(businessplans: BusinessCalculatorInput[], searchTerm: string): BusinessCalculatorInput[] {
		if(!businessplans || !searchTerm) {
			return businessplans
		}
		return businessplans.filter(businessplan => 
			businessplan.BusinessPlanName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 );
	}

}
