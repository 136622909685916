import { Injectable } from '@angular/core';
import { Constants } from '../util/constants';
import { ClientInfoComponent } from './client-info/client-info.component';
import { IncomeSourceComponent } from './income-source/income-source.component';
import { IncomeAssertComponent } from './income-assets/income-assets.component';
import { IncomeLifeReportComponent } from './income-life-report/income-life-report.component';
import { RiskGraphComponent } from './risk-graph/risk-graph.component';
import { InvestmentReportComponent } from './investment-report/investment-report.component';
import { CompanyConfigurationComponent } from '../fp4fa/company-configuration/company-configuration.component';
import { ExpenseComponent } from '../fp4fa/expense/expense.component';
import { RevenueComponent } from '../fp4fa/revenue/revenue.component';
import { MarketingProductionComponent } from '../fp4fa/marketing-production/marketing-production.component';
import { DashboardComponent } from '../fp4fa/dashboard/dashboard.component';

@Injectable({
	providedIn: 'root'
})
export class AppCompLoaderService {

	constructor() { }

	public loadComponentByName(compName: string) {
		switch (compName) {
			case Constants.COMP_NAME.cli_inf:
				return ClientInfoComponent
				break;
			case Constants.COMP_NAME.inc_sou:
				return IncomeSourceComponent
				break;
			case Constants.COMP_NAME.inc_fro_ass:
				return IncomeAssertComponent
				break;
			case Constants.COMP_NAME.inc_lif_rep:
				return IncomeLifeReportComponent
				break;
			case Constants.COMP_NAME.ris_gra:
				return RiskGraphComponent
				break;
			case Constants.COMP_NAME.inv_rep:
				return InvestmentReportComponent
				break;
			case Constants.COMP_NAME.dashboard:
				return DashboardComponent
				break;
			case Constants.COMP_NAME.company_config:
					return CompanyConfigurationComponent
					break;
			case Constants.COMP_NAME.expense:
				return ExpenseComponent
				break;
			case Constants.COMP_NAME.revenue:
				return RevenueComponent
				break;		
			case Constants.COMP_NAME.marketing_production:
				return MarketingProductionComponent
				break;				
			default:
				break;
		}
	}
}
