import { Injectable } from '@angular/core';
import { Constants } from '../util/constants';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Agent } from '../models/agent.model';
import { Ftastrategy } from '../models/ftastrategy.model';

@Injectable({
	providedIn: 'root'
})
export class FtastrategyDataService {

	constructor(private http: HttpClient) { }

	/**
	 * this is the sample method to get data from server
	 * hardcoded URL need to put 
	 */
	getftastrategies(loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
		}
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getftastrategies',null , httpOptions).pipe()
	}

	getftastrategydetailsbyid(FTAStrategyID : string ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getftastrategydetailsbyid/' + FTAStrategyID,null , httpOptions).pipe()
	}

	getsettings(loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
		}
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getsettings',null , httpOptions).pipe()
	}

	saveStrategy(FTAstrategy : Ftastrategy ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/savestrategy',JSON.stringify(FTAstrategy) , httpOptions).pipe()
	}

	saveStrategyDetails(strategyDataValueList : any ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/savestrategydetails',JSON.stringify(strategyDataValueList) , httpOptions).pipe()
	}

	
	updateCommonDisclosure(CommonStrategyDisclosure: FormData, loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json'
			})
        }
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/updatecommondisclosure',CommonStrategyDisclosure , httpOptions).pipe()
	}

	postFile(fileToUpload : FormData ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				'authorization': auth,
				'Accept': 'application/json' 
			})
		} 
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/saveallstrategydetails', fileToUpload , httpOptions).pipe()
	}

	getblendbystrategyid(FTAStrategyID: string, loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
		}
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getblendbystrategyid/' + FTAStrategyID,null , httpOptions).pipe()
	}

}