import { Component, OnInit, EventEmitter ,ComponentFactoryResolver} from '@angular/core';
//import { Component, OnInit, EventEmitter ,ViewChild,ComponentRef,
//				ViewContainerRef,ComponentFactoryResolver} from '@angular/core'; 
import { Constants } from '../util/constants';
import { DynamicSidebarService } from './dynamic-sidebar.service';
import { DynamicSideBarItem } from './dynamic-sidebar-item.model';
//import { AppCompLoaderService } from '../appComp/app-comp-loader.service';
import { LocalStorage } from '../util/localstorage.service';
import { SharedServiceService } from '../util/shared-service.service';
import { Subscription } from 'rxjs';  
import { IncomeSourceAccount } from '../models/income-source-accout.model';
import { IncomeFromAsset } from '../models/income-from-asset.model';
import { Client } from '../models/client.model';
import { Agent } from '../models/agent.model';  
import { Choice } from '../models/choice.model'; 
import { ClientInfoDataService } from '../appComp/client-info/client-info-data.service';
import { AlertService } from '../util/alert/alert.service';
import { CommonFunctions } from '../util/common-functions';   
import { Router } from '@angular/router';
import { debug } from 'util';
import { FP4FACommonFunctionsService } from '../fp4fa/fp4fa-common-functions.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	inputs: ['moduleType'],
	outputs: ['changeSidebar']
})
export class SidebarComponent implements OnInit {

	public selectedMenuItem: string = Constants.COMP_NAME.cli_inf
	changeSidebar = new EventEmitter<any>()
	public componentNames: any = Constants.COMP_NAME
	isCommCollapsed: boolean = false
	public dsbList: Array<DynamicSideBarItem> = []
	public expandedDynItem: string = ''
	public editingDynItem: string = ''
	public moduleType: any
	public isShowFP4FA: boolean = false

	private subsVar: Subscription

	client:Client = new Client
	public loggedAgent: Agent 

	private commonFunctions: CommonFunctions = new CommonFunctions
	//component: any;
	//@ViewChild('longHost', { read: ViewContainerRef }) componentHost: ViewContainerRef
	//componentRef: ComponentRef<Component>

	constructor( private dynSidebarService: DynamicSidebarService,
						private sharedService: SharedServiceService,
						private ldService: ClientInfoDataService, 
						private alertService: AlertService,
						private router: Router,
						private fp4facommonFunctions: FP4FACommonFunctionsService ) { 		
		this.dsbList =this.dynSidebarService.dynamicSidebar 
		//console.log(this.dsbList )
	}

	ngOnInit() { 
		/* this.dynSidebarService.loadDynamicSidebar		
		this.dsbList =this.dynSidebarService.dynamicSidebar 
		console.log(this.dsbList ) */
		this.client   = JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) as Client; 
		this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
		if ((this.loggedAgent != null) && (this.loggedAgent != undefined)) { 
			// check whther the user allowed to see FP4FA
			if (this.loggedAgent.bShowBizIQ== true)
				this.isShowFP4FA = true
			else
				this.isShowFP4FA = false  
		}

		//set default selected menu item
		if (this.moduleType==Constants.MODULE_TYPE.HOME)
		{
			this.selectedMenuItem = Constants.COMP_NAME.cli_inf
		}
		else if(this.moduleType==Constants.MODULE_TYPE.FP4FA && localStorage.getItem(LocalStorage.COMPANY_CONFIG) == "0") 
		{
			this.selectedMenuItem = Constants.COMP_NAME.dashboard
		} 
		else if(this.moduleType==Constants.MODULE_TYPE.FP4FA && localStorage.getItem(LocalStorage.COMPANY_CONFIG) == "1"){
			this.selectedMenuItem = Constants.COMP_NAME.company_config
		}
	}

	ngAfterContentInit(): void {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.sharedService.changeSideBarRespond().subscribe(option => this.reloadDynamicSidebar())
		this.sharedService.changeComponentsRespond().subscribe(
			(option: any) => this.sideItemClick(option.componentName,"")
		)
	 }

	 private reloadDynamicSidebar() {
		this.dsbList =this.dynSidebarService.loadDynamicSidebar()
		//console.log(this.dsbList) 
	 }
	 
	 ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		 
	}

	
	public sideItemClick(sidebarItem: string,id :string) {
		//FP4FA Validation - Validate Save Business Plan
		if(this.moduleType==Constants.MODULE_TYPE.FP4FA)
		{
			this.fp4facommonFunctions.ValidateSaveBusinessPlan()
		}

		//FP4FA Validation - If there are any Financial Product errors do not allow to navigate to other tabs
		if(this.moduleType==Constants.MODULE_TYPE.FP4FA && localStorage.getItem(LocalStorage.HAS_COMPANY_CONFIG_ERRORS) =="1")
		{
			sidebarItem = Constants.COMP_NAME.company_config
		}

		//FP4FA Validation - If there are any Company Config errors do not allow to navigate to other tabs
		else if(this.moduleType==Constants.MODULE_TYPE.FP4FA && 
			(localStorage.getItem(LocalStorage.HAS_FINANCIAL_PRODUCT_ERRORS) =="1"
			|| localStorage.getItem(LocalStorage.HAS_REP_DATA_ERRORS) =="1"))
		{
			sidebarItem = Constants.COMP_NAME.marketing_production
		}

		this.selectedMenuItem = sidebarItem
		this.changeSidebar.emit({
			selectedItem: sidebarItem,
			componentType: null
		})  

		if(id!="")
		{
			localStorage.setItem(LocalStorage.SELECTED_CHOICEID,  id );
		}		
	}

	public dynamicSideItemClick(sidebarItem: string, component: string) {
		this.selectedMenuItem = sidebarItem
		this.changeSidebar.emit({
			selectedItem: sidebarItem,
			componentType: component
		})


		/*alert(component)
		this.component = this.aclService.loadComponentByName(component)
        this.updateComponent()*/
	}

	/*updateComponent() {
		this.componentHost.clear()
		const authFormFactory = this.resolver.resolveComponentFactory(this.component)
		const componentRef = this.componentHost.createComponent(authFormFactory);
	}*/

	public expandDynamicItem(item: DynamicSideBarItem) {
		if (this.expandedDynItem != item.text) {
			this.expandedDynItem = item.text
		} else {
			this.expandedDynItem = ''
		}
	}

	public copyItem(item: DynamicSideBarItem) {   
		   this.client   = JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) as Client
		   this.setChoiceInfomation(item.id) 
		   this.saveClient()  
	}

	public editItem(item: DynamicSideBarItem) {
		if(this.editingDynItem != item.id) { 
			this.editingDynItem = item.id
		} else {

		}
	}

	public deleteItem(item: DynamicSideBarItem) {
		//this.dynSidebarService.deleteSideBarItem(item)
		this.client   = JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) as Client
		let index = this.client.Choice.findIndex(x => x.ChoiceID ==  item.id)
		this.client.Choice.splice(index,1)
		this.deleteChoice(item.id)
	}

	public exitEditMode(item: DynamicSideBarItem) {
		this.editingDynItem = ''
	}

	public saveSideBarItem(item: DynamicSideBarItem) {
		let inputId: string = 'item_text_' + item.id
		let str : string = (<HTMLInputElement>document.getElementById(inputId)).value; 
		if(str.length > 0) { 
			this.dynSidebarService.renameSideBarItem(item, str)
			this.editingDynItem = ''
		} else {
			this.editingDynItem = ''
		}
	}

	public itemMoveUp(item: DynamicSideBarItem) {		
		// this.dynSidebarService.itemMoveUp(item)

		this.client   = JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) as Client

		let currentOrderNumber: number = item.orderNum
		let currentIndex : number = this.dsbList.findIndex(x => x.id == item.id);
		
		let prevItem: DynamicSideBarItem  = this.dsbList[currentIndex-1]
		let prevOrderNumber: number = prevItem.orderNum 

		let oCurrentChoice : Choice =  this.client.Choice.find(x => x.ChoiceID == item.id) 
		let oPrevChoice : Choice =  this.client.Choice.find(x => x.ChoiceID == prevItem.id) 

		oCurrentChoice.DisplayOrder = prevOrderNumber
		oPrevChoice.DisplayOrder = currentOrderNumber

		this.updateChoice(this.client.Choice) 
	}

	public itemMoveDown(item: DynamicSideBarItem) {
		//this.dynSidebarService.itemMoveDown(item)

		this.client   = JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) as Client

		let currentOrderNumber: number = item.orderNum
		let currentIndex : number = this.dsbList.findIndex(x => x.id == item.id);
		
		let nextitem: DynamicSideBarItem  = this.dsbList[currentIndex+1]
		let nextOrderNumber: number = nextitem.orderNum 

		let oCurrentChoice : Choice =  this.client.Choice.find(x => x.ChoiceID == item.id) 
		let oNextChoice : Choice =  this.client.Choice.find(x => x.ChoiceID == nextitem.id) 

		oCurrentChoice.DisplayOrder = nextOrderNumber
		oNextChoice.DisplayOrder = currentOrderNumber

		this.updateChoice(this.client.Choice) 
	}

	setChoiceInfomation(selectedChoiceID:string)
	{
  
	  var oChoice : Choice = new Choice 
	  var ChoiceID : string = this.commonFunctions.getnewguid() 
   
	  var oSelectedChoice  : Choice = new Choice 
	  oSelectedChoice = this.client.Choice.find(x => x.ChoiceID ==  selectedChoiceID)

	  oChoice.ChoiceID = ChoiceID
	  oChoice.IsActiveChoice = false
	  oChoice.IsReadOnly = false 
 
	  oChoice.ReportKind = oSelectedChoice.ReportKind
	  oChoice.ClientDiesAtAge = oSelectedChoice.ClientDiesAtAge
	  oChoice.SpouseDiesAtAge = oSelectedChoice.SpouseDiesAtAge
	  oChoice.ReportTitleLine1 =oSelectedChoice.ReportTitleLine1
	  oChoice.ReportTitleLine2 = oSelectedChoice.ReportTitleLine2
	  oChoice.ReportTitleLine3 = oSelectedChoice.ReportTitleLine3

	  oChoice.ChoiceName = oSelectedChoice.ChoiceName + "(1)"
	  oChoice.DisplayOrder = this.getNextOrder(Constants.TABLE_NAME.Choice)
	  oChoice.ModelStartYear = oSelectedChoice.ModelStartYear 
	  oChoice.ModelEndYear = oSelectedChoice.ModelEndYear 
	  oChoice.ViewStartYear = oSelectedChoice.ViewStartYear 
	  oChoice.ViewEndYear = oSelectedChoice.ViewEndYear 
	  oChoice.NumYearsInView = oSelectedChoice.NumYearsInView

	  this.client.Choice.push(oChoice)

	  // Add IncomeSourceAccount
	  if (typeof this.client.IncomeSourceAccount !== 'undefined') {
	 this.client.IncomeSourceAccount.filter(x => x.ChoiceID ==  selectedChoiceID).forEach((oIncomeSourceAccount:IncomeSourceAccount) => { 
		let tempIncomeSourceAccount:IncomeSourceAccount= new  IncomeSourceAccount()

		tempIncomeSourceAccount.IncomeSourceID =  this.commonFunctions.getnewguid() 
		tempIncomeSourceAccount.ChoiceID = ChoiceID
		tempIncomeSourceAccount.Owner = oIncomeSourceAccount.Owner
		tempIncomeSourceAccount.Source = oIncomeSourceAccount.Source
		tempIncomeSourceAccount.StartYear = oIncomeSourceAccount.StartYear
		tempIncomeSourceAccount.EndYear = oIncomeSourceAccount.EndYear
		tempIncomeSourceAccount.COLAFlag = oIncomeSourceAccount.COLAFlag
		tempIncomeSourceAccount.COLAPct = oIncomeSourceAccount.COLAPct
		tempIncomeSourceAccount.SurvivorBenefitFlag = oIncomeSourceAccount.SurvivorBenefitFlag
		tempIncomeSourceAccount.SurvivorBenefitPct = oIncomeSourceAccount.SurvivorBenefitPct
		tempIncomeSourceAccount.AnnualAmount =oIncomeSourceAccount.AnnualAmount
		tempIncomeSourceAccount.IncomeForLifeComment = oIncomeSourceAccount.IncomeForLifeComment
		tempIncomeSourceAccount.DisplayOrder = oIncomeSourceAccount.DisplayOrder
		tempIncomeSourceAccount.StartDate = oIncomeSourceAccount.StartDate
		tempIncomeSourceAccount.EndDate = oIncomeSourceAccount.EndDate
		this.client.IncomeSourceAccount.push(tempIncomeSourceAccount)
	   })  
	}

	  // Add IncomeFromAsset
	  if (typeof this.client.IncomeFromAsset !== 'undefined') {
	  this.client.IncomeFromAsset.filter(x => x.ChoiceID == selectedChoiceID).forEach((oIncomeFromAsset:IncomeFromAsset) => { 
		 

		let tempIncomeFromAsset:IncomeFromAsset =new IncomeFromAsset()

		tempIncomeFromAsset.IFAID =  this.commonFunctions.getnewguid() 
		tempIncomeFromAsset.ChoiceID = ChoiceID
		tempIncomeFromAsset.Owner = oIncomeFromAsset.Owner
		tempIncomeFromAsset.Source = oIncomeFromAsset.Source
		tempIncomeFromAsset.Deposit = oIncomeFromAsset.Deposit
		tempIncomeFromAsset.BonusFlag = oIncomeFromAsset.BonusFlag
		tempIncomeFromAsset.BonusPct = oIncomeFromAsset.BonusPct
		tempIncomeFromAsset.YearsOfDeferral = oIncomeFromAsset.YearsOfDeferral
		tempIncomeFromAsset.StartYear = oIncomeFromAsset.StartYear
		tempIncomeFromAsset.RollUpHow = oIncomeFromAsset.RollUpHow
		tempIncomeFromAsset.RollUpPct = oIncomeFromAsset.RollUpPct
		tempIncomeFromAsset.AccountValue = oIncomeFromAsset.AccountValue
		tempIncomeFromAsset.PayoutPct =oIncomeFromAsset.PayoutPct
		tempIncomeFromAsset.SurvivorshipHow = oIncomeFromAsset.SurvivorshipHow
		tempIncomeFromAsset.SurvivorshipPct = oIncomeFromAsset.SurvivorshipPct
		tempIncomeFromAsset.COLAFlag = oIncomeFromAsset.COLAFlag
		tempIncomeFromAsset.COLAPct = oIncomeFromAsset.COLAPct
		tempIncomeFromAsset.DisplayOrder = oIncomeFromAsset.DisplayOrder
		tempIncomeFromAsset.AnnualContribution = oIncomeFromAsset.AnnualContribution
		tempIncomeFromAsset.StartDate = oIncomeFromAsset.StartDate

		this.client.IncomeFromAsset.push(tempIncomeFromAsset)
	   })  
	}
  
	}

	saveClient()
	{
		this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
	 	this.ldService.saveclient(this.client, this.loggedAgent).subscribe(date =>
		{ 
			localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(this.client));  
			this.reloadDynamicSidebar()
		},
		error => {
		  this.alertService.clear()
		  this.alertService.error('Error occured while saving data')
		 });
	}

	deleteChoice(choiceID:string)
	{  
	  this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
	  this.ldService.deletechoice(choiceID, this.loggedAgent).subscribe(date =>
		{ 
			 localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(this.client));  
			 this.reloadDynamicSidebar()
		},
		error => {
		  this.alertService.clear()
		  this.alertService.error('Error occured while saving data')
		 });
	} 

	updateChoice(choicelist:Array<Choice>)
	{  
	  this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
	  this.ldService.updatechoice(choicelist, this.loggedAgent).subscribe(date =>
		{ 
			 localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(this.client));  
			 this.reloadDynamicSidebar()
		},
		error => {
		  this.alertService.clear()
		  this.alertService.error('Error occured while saving data')
		 });
	} 
	

	getNextOrder(incometype :string) : any
	{ 
		 var currentOrderID :number = 0
		 if (incometype == Constants.TABLE_NAME.IncomeSources)  
		 {
		   /*  var tempIncomeSourceAccount As New List(Of Models.IncomeSourceAccount)
			 tempIncomeSourceAccount = oClient.IncomeSourceAccount.Where(Function(x) x.ChoiceID.ToString = mSelectedChoiceID.ToString()).ToList
   
			 If tempIncomeSourceAccount.Count > 0 Then
				 currentOrderID = tempIncomeSourceAccount.Max(Function(g) g.DisplayOrder)
			 Else
				 currentOrderID = 0
			 End If
			 */
		 }
		 else  if (incometype == Constants.TABLE_NAME.IncomeFromAssets)  
		 {
			/* var tempIncomeFromAsset As New List(Of Models.IncomeFromAsset)
			 tempIncomeFromAsset = oClient.IncomeFromAsset.Where(Function(x) x.ChoiceID.ToString = mSelectedChoiceID.ToString()).ToList
			 If tempIncomeFromAsset.Count > 0 Then
				 currentOrderID = tempIncomeFromAsset.Max(Function(g) g.DisplayOrder) + 20 ' Adding 20 to make sure new IncomeFromAssest goes to last
			 Else
				 currentOrderID = 0 + 20 ' Adding 20 to make sure new IncomeFromAssest goes to last
			 End If*/
		 }
		 else if (incometype == Constants.TABLE_NAME.Choice)     
		 {       
			 if (this.client.Choice.length > 0 ) 
				 currentOrderID = Math.max.apply(Math, this.client.Choice.map(function(o) { return o.DisplayOrder; }))
		     else
				 currentOrderID = 0          
		 } 
		 let orderID = currentOrderID + 1 //iOrder + 1
		 return orderID
	
   }

   loadModule()
	  { 
		if (this.moduleType==Constants.MODULE_TYPE.HOME)
		{
			//redirect to FP4FA
			this.router.navigateByUrl('fp4fa'); 
		}
		else
		{
			//redirect to Home
			this.router.navigateByUrl('landing'); 
		}
	  }
}
