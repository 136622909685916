import { Injectable } from '@angular/core';
import { Constants } from '../util/constants';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Agent } from '../models/agent.model';
import { IndexBlend, IndexBlendDetail } from '../models/index-blend.model';

@Injectable({
	providedIn: 'root'
})
export class IndexBlendDataService {

	constructor(private http: HttpClient) { }

	/**
	 * this is the sample method to get data from server
	 * hardcoded URL need to put 
	 */
	getindexblends(loggedAgent:Agent, ismyindexblend:string): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		var agentID = loggedAgent.AgentID
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
		}
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getindexblends/' + agentID + '/' + ismyindexblend,null , httpOptions).pipe()
	}

  getindexes(loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
		}
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getindexes',null , httpOptions).pipe()
	}

	getindexblenddetailsbyid(IndexBlendID : string ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getindexblenddetailsbyid/' + IndexBlendID,null , httpOptions).pipe()
	}

	saveIndexBlend(IndexBlend : IndexBlend ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/saveindexblend',JSON.stringify(IndexBlend) , httpOptions).pipe()
	}
}