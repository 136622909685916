import { Injectable } from '@angular/core';
import { Constants } from '../util/constants';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginCredential } from '../models/login-credential.model';
import { map } from 'rxjs/operators' 
import { Agent } from '../models/agent.model';
import { BusinessCalculatorInput } from '../models/business-calculator-input.model';

@Injectable({
	providedIn: 'root'
})
export class FP4FADataService {

	constructor(private http: HttpClient ) { }	   

	generatebusinesscalculator(loggedAgent: Agent, businesscalculatorinput:BusinessCalculatorInput): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/generatebusinesscalculator',JSON.stringify(businesscalculatorinput) , httpOptions).pipe()
	}

	getbusinesscalculatorinputwithdefaults(loggedAgent: Agent, businesscalculatorinput:BusinessCalculatorInput): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getbusinesscalculatorinputwithdefaults',JSON.stringify(businesscalculatorinput) , httpOptions).pipe()
	}

	calculaterepdatasubtotals(loggedAgent: Agent, businesscalculatorinput:BusinessCalculatorInput): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/calculaterepdatasubtotals',JSON.stringify(businesscalculatorinput) , httpOptions).pipe()
	}

	getemployeetypes(loggedAgent: Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getemployeetypes',null , httpOptions).pipe()
	}

	savebusinessplan(loggedAgent: Agent, businesscalculatorinput:BusinessCalculatorInput): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/savebusinessplan',JSON.stringify(businesscalculatorinput) , httpOptions).pipe()
	}

	getbusinessplanbyid(businessplanid : string ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getbusinessplanbyid/' + businessplanid,null , httpOptions).pipe()
	} 

	getallbusinessplansbyagentid(loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getallbusinessplansbyagentid/' + loggedAgent.AgentID,null , httpOptions).pipe()
	} 


	deletebusinessplanbyid(loggedAgent: Agent, businesscalculatorinput:BusinessCalculatorInput): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/deletebusinessplanbyid',JSON.stringify(businesscalculatorinput) , httpOptions).pipe()
	}

	getbusinesscalculatordefaults(loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getbusinesscalculatordefaults',null , httpOptions).pipe()
	} 


	getbusinessplandashboard(businessplanid : string ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getbusinessplandashboard/' + businessplanid,null , httpOptions).pipe()
	} 

}
