import { Pipe, PipeTransform } from '@angular/core';
/*
 * Reorder an array for a given type
 * Takes string as an argumnet to sort array
 * Usage:
 *   array | orderBy: <type>
 * Example:
 *   *ngFor = "let ccItem of jobProfileModel.coreCompetencyList | orderBy : 'alphabetic'; let k = index"
 *   *ngFor = "let task of ccItem.taskList | orderBy : 'orderNum'; let t = index "
*/

@Pipe({
	name: 'orderBy',
	pure: false
})
export class OrderArrayByTypePipe implements PipeTransform {

	transform(value: any, args?: any): any {
		switch(args) {
			case 'orderNum': 
			value = this.orderByOrderNumber(value)
		}
		return value;
	}

	private orderByOrderNumber(array: any): any {
		if (array != null && array.length != 0) {
			array.sort((a, b) => {
				if (a.orderNum < b.orderNum) {
					return -1;
				} else if (a.orderNum > b.orderNum) {
					return 1;
				} else {
					return 0
				}
			});
			return array
		} else {
			return array
		}
	}

}
