import { ProductList } from './product-list.model';

export class InvestmentReportRequest {
    constructor(
        public Tickers?: string,
        public StartDate?: Date,
        public EndDate?: Date, 
        public ProductList?: Array<ProductList>,
        public IndexList?:  Array<ProductList>, 
        public TickerBlendList?:  Array<ProductList>, 
        public AgentID?: string, 
        public ReportName?: string,
        public ClientID?: string
    ) {}
}
