import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Constants } from '../../util/constants' 
import { Client } from '../../models/client.model';
import { Agent } from '../../models/agent.model';
import { IncomeSourceAccount } from '../../models/income-source-accout.model';
import { IncomeFromAsset } from '../../models/income-from-asset.model';
import { IncomeForLife } from '../../models/income-for-life.model';
import { LocalStorage } from '../../util/localstorage.service'; 
import { ClientInfoDataService } from './../client-info/client-info-data.service';
import { AlertService } from '../../util/alert/alert.service';
import { Choice } from 'src/app/models/choice.model';
import { formatDate } from '@angular/common'; 
import { CommonFunctions } from '../../util/common-functions';
import { SharedServiceService } from '../../util/shared-service.service';
import { GridOptions } from 'ag-grid-community'
import { CustomHeader} from '../../util//customHeader'

@Component({
	selector: 'app-income-life-report',
	templateUrl: './income-life-report.component.html',
	styleUrls: ['./income-life-report.component.scss'],
	providers: [DatePipe]
})
export class IncomeLifeReportComponent implements OnInit {

	public client:Client
	public loggedAgent: Agent 
	public selectedChoiceID: string = "";
	public incomeForLifeList : Array<IncomeForLife> =  []
	public clientagelist : Array<any> =  []
	public spouseagelist : Array<any> =  []
	public currentChoice: Choice = new Choice()
	public clientDiesAt: any 
	public spouseDiesAt: any 
	public SelectedSum : any = "$0"// "$0.00" removed cents
	public hasSpouse : boolean = false
	public showhide :boolean = false
	
	private commonFunctions: CommonFunctions = new CommonFunctions
	
	private selectedoption:any
	private passAt:any

	private gridApi: any;
	private gridColumnApi: any;

	public columnDefs: any;
	public rowData: any;

	public today: Date = new Date()
	public gridOptions : GridOptions  
	public currentSelectedColumn;
	public gridHeight :any
	public reportOptionHeight: any =240

	constructor(private router: Router,  private ldService: ClientInfoDataService,
					 private alertService: AlertService,
					 private sharedService: SharedServiceService) {
	/*	this.columnDefs = [
			{ 
				headerName: 'Year', 
				field: 'Year', 
				sortable: true, 
				width: 125,
				filter: true,
				cellClass: "rag-gray"
			},
			{ 
				headerName: 'Scott Age', 
				field: 'Age', 
				sortable: true, 
				width: 125,
				filter: true,
				cellClass: "rag-blue"
			} ,
			{ 
				headerName: 'Gwen Age', 
				field: 'age_b', 
				sortable: true, 
				width: 125,
				filter: true,
				cellClass: "rag-blue"
			},
			{ 
				headerName: 'Scott Brooks Social Security', 
				field: 'social_sec_a',  
				sortable: true, 
				filter: true,
				cellClass: "rag-yellow"
			},
			{ 
				headerName: 'Gwen Brooks Social Security', 
				field: 'social_sec_b', 
				sortable: true, 
				filter: true,
				cellClass: "rag-yellow"
			},
			{ 
				headerName: 'Scott Brooks Boeing Pension', 
				field: 'boeing_pen', 
				sortable: true, 
				filter: true,
				cellClass: "rag-yellow"
			},
			{ 
				headerName: 'Scott Brooks PP', 
				field: 'pp', 
				sortable: true, 
				filter: true,
				cellClass: "rag-yellow"
			},
			{ 
				headerName: 'Scott Brooks LM1', 
				field: 'lm_1', 
				sortable: true, 
				filter: true,
				cellClass: "rag-yellow"
			},
			{ 
				headerName: 'Scott Brooks LM2', 
				field: 'lm_2', 
				sortable: true, 
				filter: true,
				cellClass: "rag-yellow"
			},
			{ 
				headerName: 'Scott Brooks LM3', 
				field: 'lm_3', 
				sortable: true, 
				filter: true,
				cellClass: "rag-yellow"
			},
			{ 
				headerName: 'Scott Brooks LM4', 
				field: 'lm_4', 
				sortable: true, 
				filter: true,
				cellClass: "rag-yellow"
			},
			{ 
				headerName: 'Total', 
				field: 'total', 
				sortable: true, 
				filter: true,
				width: 150,
				cellClass: "rag-orange"
			} 
		];*/

		this.gridOptions = {
			columnDefs: null, 
			rowData: null,
			components: {
				agColumnHeader: CustomHeader
			},
			defaultColDef: { 
				headerComponentParams: {
					setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
					getSelectedColumn: () =>  this.getCurrentSelectedColumn()
				} 
			}
			//onRowSelected: onRowSelected,
			//onSelectionChanged: onSelectionChanged
		};
	}

ngOnInit() {

	this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
	this.selectedChoiceID = localStorage.getItem(LocalStorage.SELECTED_CHOICEID)  
		
	this.client   = JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) as Client; 
	this.currentChoice =this.client.Choice.find(x => x.ChoiceID == this.selectedChoiceID) 

	let activeTabName = this.client.Choice.find(x => x.ChoiceID == this.selectedChoiceID).ChoiceName
	this.alertService.clear()
	if (activeTabName !== Constants.CURRENT_CHOICE_NAME)
	{      
	   this.alertService.warn('Warning! You are editing the '+ activeTabName +' Tab, new tabs should be created from the '+ Constants.CURRENT_CHOICE_NAME +' tab.')      
	} 
	
	this.loadOptions()	
	this.assignDataForIncomeForLifeReport()
	this.loadReportData()
	this.gridHeight = window.innerHeight -this.reportOptionHeight
	
}

getCurrentSelectedColumn() {
	return this.currentSelectedColumn;
  }
  
setCurrentSelectedColumn(colId) {
	this.currentSelectedColumn = colId;
  }

createColumnDef()
{
	let arrYear : Array<any> = []
	let arrSpouseAge: Array<any> = []
	let arrDynamicCols: Array<any> = []
	let arrTotal: Array<any> = []
	let arrFinal:Array<any> = []

	arrYear.push( {
		headerName: 'Year', 
		field: 'Year', 
		suppressMovable: true,
		lockPosition: true,
		suppressNavigable: true,
		//sortable: true, 
		width: 125,
		//filter: true,
		resizable: true,
		cellClass: "rag-gray",
		cellStyle:  {textAlign: 'center','border-right': '1px dotted  #aaa'}})

	arrYear.push({
		headerName: this.client.ClientFirstName + ' Age', 
		field: 'ClientAge', 
		suppressMovable: true,
		lockPosition: true,
		suppressNavigable: true,
		//sortable: true, 
		width: 125,
		//filter: true,
		resizable: true,
		cellClass: "rag-blue",
		cellStyle: {textAlign: 'center','border-right': '1px dotted  #aaa'}})

		arrFinal=arrYear

	if (typeof this.client.SpouseFirstName === 'undefined' || typeof this.client.SpouseFirstName === 'undefined')
	{
		// doing nothing
	}
	else
	{
		if  (this.client.SpouseFirstName != "" && this.client.SpouseLastName != "") 
		{
			arrSpouseAge.push({
				headerName: this.client.SpouseFirstName + ' Age', 
				field: 'SpouseAge',
				suppressMovable: true, 
				lockPosition: true,
				suppressNavigable: true,
				//sortable: true, 
				width: 125,
				//filter: true,
				resizable: true,
				cellClass: "rag-blue",
				cellStyle: {textAlign: 'center','border-right': '1px dotted  #aaa'}})
				arrFinal = arrFinal.concat(arrSpouseAge)
		}
	}

	

 // adding dynamic column
	this.client.IncomeForLife.sort((a, b) => Number(a.DisplayOrder) - Number(b.DisplayOrder)).forEach((oIncomeForLife:IncomeForLife) => { 
	  	arrDynamicCols.push({ 
			headerName: oIncomeForLife.Owner + " " + oIncomeForLife.Source, 
			field: oIncomeForLife.IncomeID, 
			//sortable: true, 
			//filter: true,
			resizable: true,
			cellClass: "rag-yellow",
			cellStyle: {textAlign: 'center','border-right': '1px dotted  #aaa'}})
		
	})
	arrFinal = arrFinal.concat(arrDynamicCols)

// adding total col
	arrTotal.push({ 
		headerName: 'Total', 
		field: 'Total', 
		suppressMovable: true, 
		suppressNavigable: true,
		//sortable: true, 
		//filter: true,
		//width: 150,
		resizable: true,
		cellClass: "rag-orange",
		cellStyle: {textAlign: 'center','border-right': '1px dotted  #aaa'},
		headerClass :  "ag-header-cell-text"})

	arrFinal = arrFinal.concat(arrTotal)
	this.columnDefs = arrFinal
 
} 

loadReportData()
{
 
	setTimeout(() => { 
		this.ldService.getincomeforlifereportdata(this.selectedChoiceID,this.client, this.loggedAgent).subscribe(data => {
  			this.createColumnDef()  
			//setTimeout(() => { 
					this.rowData = JSON.parse(data)  
					this.gridOptions.api.setColumnDefs(this.getColumnDef()); 
					this.gridOptions.api.setRowData(this.getrowData());   
			//}, 50); 
			
			setTimeout(() => { 				
				this.gridOptions.api.sizeColumnsToFit();
			}, 50);
			
		},
			error => {
				this.alertService.clear()
				this.alertService.error('Error occured while generating report')
		}); 
	}, 50);
}

assignDataForIncomeForLifeReport()
{
	this.incomeForLifeList = []   
	if (typeof this.client.IncomeSourceAccount !== 'undefined') {
		this.client.IncomeSourceAccount.filter(x => x.ChoiceID == this.selectedChoiceID).forEach((oIncomeSourceAccount:IncomeSourceAccount) => { 
			
			let incomeForLife :IncomeForLife = new IncomeForLife()
			incomeForLife.ChoiceID = oIncomeSourceAccount.ChoiceID
			incomeForLife.DisplayOrder = oIncomeSourceAccount.DisplayOrder
			incomeForLife.IncomeID = oIncomeSourceAccount.IncomeSourceID
			incomeForLife.Owner = oIncomeSourceAccount.Owner
			incomeForLife.SourceType = Constants.IncomeType.IncomeSources 
			incomeForLife.Source = oIncomeSourceAccount.Source
			incomeForLife.StartYear = oIncomeSourceAccount.StartYear
			incomeForLife.EndYear = oIncomeSourceAccount.EndYear
			incomeForLife.COLAFlag = oIncomeSourceAccount.COLAFlag
			incomeForLife.COLAPct = oIncomeSourceAccount.COLAPct
			incomeForLife.SurvivorBenefitFlag = String(oIncomeSourceAccount.SurvivorBenefitFlag)
			incomeForLife.SurvivorBenefitPct = oIncomeSourceAccount.SurvivorBenefitPct
			incomeForLife.Amount = oIncomeSourceAccount.AnnualAmount
			incomeForLife.StartDate = oIncomeSourceAccount.StartDate
			incomeForLife.EndDate = oIncomeSourceAccount.EndDate
			this.incomeForLifeList.push(incomeForLife)
			
		});  
	}

	if (typeof this.client.IncomeFromAsset !== 'undefined') {
		this.client.IncomeFromAsset.filter(x => x.ChoiceID == this.selectedChoiceID).forEach((oIncomeFromAsset:IncomeFromAsset) => { 
			
			let StartYear = new Date (oIncomeFromAsset.StartDate).getFullYear()
			let StartMonth = new Date(oIncomeFromAsset.StartDate).getMonth() 

			let incomeForLife :IncomeForLife  = new IncomeForLife()
			incomeForLife.ChoiceID = oIncomeFromAsset.ChoiceID
			incomeForLife.DisplayOrder = oIncomeFromAsset.DisplayOrder
			incomeForLife.IncomeID = oIncomeFromAsset.IFAID
			incomeForLife.Owner = oIncomeFromAsset.Owner
			incomeForLife.SourceType =  Constants.IncomeType.IncomeFromAssets
			incomeForLife.Source = oIncomeFromAsset.Source
			incomeForLife.StartYear = oIncomeFromAsset.StartYear
			incomeForLife.COLAFlag = oIncomeFromAsset.COLAFlag
			incomeForLife.COLAPct = oIncomeFromAsset.COLAPct
			incomeForLife.SurvivorBenefitFlag = oIncomeFromAsset.SurvivorshipHow
			incomeForLife.SurvivorBenefitPct = oIncomeFromAsset.SurvivorshipPct
			incomeForLife.Amount = oIncomeFromAsset.AccountValue
			incomeForLife.PayoutPct = oIncomeFromAsset.PayoutPct
			incomeForLife.StartDate = formatDate(new Date(new Date(StartYear,StartMonth,1).setMonth( StartMonth + Number(oIncomeFromAsset.YearsOfDeferral))), 'MM/dd/yyyy', 'en') // oIncomeFromAsset.StartDate
			incomeForLife.EndDate = new Date(8640000000000000).toString() //max date 
			//LTC
			incomeForLife.HasLTC = oIncomeFromAsset.HasLTC
			incomeForLife.LTCNotAvailableFor = oIncomeFromAsset.LTCNotAvailableFor
			incomeForLife.LTCMultiplier = oIncomeFromAsset.LTCMultiplier
			incomeForLife.LTCPeriod = oIncomeFromAsset.LTCPeriod 
			this.incomeForLifeList.push(incomeForLife) 
			//Monthly Deposit 
			incomeForLife.ClientMonthlyDeposit = oIncomeFromAsset.ClientMonthlyDeposit
			incomeForLife.ClientMonthlyDepositStartDate = oIncomeFromAsset.ClientMonthlyDepositStartDate
			incomeForLife.ClientMonthlyDepositEndDate = oIncomeFromAsset.ClientMonthlyDepositEndDate
			incomeForLife.SpouseMonthlyDeposit =oIncomeFromAsset.SpouseMonthlyDeposit
			incomeForLife.SpouseMonthlyDepositStartDate = oIncomeFromAsset.SpouseMonthlyDepositStartDate
			incomeForLife.SpouseMonthlyDepositEndDate = oIncomeFromAsset.SpouseMonthlyDepositEndDate

			//Other info needed for calculation in addtional monthly deposit
			incomeForLife.ChoiceID = oIncomeFromAsset.ChoiceID
			incomeForLife.Deposit = oIncomeFromAsset.Deposit
			incomeForLife.BonusFlag = oIncomeFromAsset.BonusFlag
			incomeForLife.BonusPct = oIncomeFromAsset.BonusPct
			incomeForLife.RollUpHow = oIncomeFromAsset.RollUpHow
			incomeForLife.RollUpPct = oIncomeFromAsset.RollUpPct
			incomeForLife.MonthsOfDeferral = oIncomeFromAsset.YearsOfDeferral // Even though this named as YearsOdDeferral it contains MonthsOfDeferral

			 

		});  
	} 

	this.client.IncomeForLife = this.incomeForLifeList   
}


onGridReady(params: any) { 
	//console.log(params.api) 
		//this.gridApi = params.api ;
	//	this.gridColumnApi = params.columnApi;  
	}

gridSizeChanged(params: any) {
		 //  console.info(params)
		setTimeout(() => { 
			params.api.sizeColumnsToFit();
		}, 50);  
		
	}

onSelectionChanged(event: any)  
	{ 
	}

onCellClicked(event: any) {    
 
	let yearSelection :boolean = false
	var cellRanges = event.api.getCellRanges(); 
	let tempString: Array<any>=[] 
	for (var rangeIndex = 0; rangeIndex<cellRanges.length; rangeIndex++) { 
		if ( cellRanges[rangeIndex].columns[0].colId=="Year")	
		{
			yearSelection = true
			let rowIndex = cellRanges[rangeIndex].startRow.rowIndex 
			 tempString.push( rowIndex )   
		}
	} 

	if (yearSelection) {
		event.api.clearRangeSelection();

		//select rows 
		for (var i = 0; i<tempString.length; i++) {  
			event.api.addCellRange({
						rowStartIndex: tempString[i],
						rowEndIndex: tempString[i],
						columnStart: "Year",
						columnEnd: "Total"
						});  
		}
	}
 
	/* if ( event.api.getCellRanges()[0].columns[0].colId=="Year")	
	{
		let rowIndex = event.api.getCellRanges()[0].startRow.rowIndex 
		event.api.addCellRange({
			rowStartIndex: rowIndex,
			rowEndIndex: rowIndex,
			columnStart: "Year",
			columnEnd: "Total"
		  });
	}	  */
}

onColumnMoved()
{
	// console.log("onColumnMoved")

	var cols = this.gridOptions.columnApi.getAllGridColumns();

	//console.log(cols)

	let colIndex: number = 0;
	cols.forEach((element:any) => { 

		let oIncomeSourceAccount: IncomeSourceAccount = this.client.IncomeSourceAccount.find(x => x.IncomeSourceID == element.colId)
		if(	typeof oIncomeSourceAccount !== 'undefined')
		{
			oIncomeSourceAccount.DisplayOrder = colIndex
		}

		let oIncomeFromAsset: IncomeFromAsset = this.client.IncomeFromAsset.find(x => x.IFAID == element.colId)
		if(	typeof oIncomeFromAsset !== 'undefined')
		{
			oIncomeFromAsset.DisplayOrder = colIndex
		}
		
		//console.log(element.colId) 
		 colIndex += 1
	}); 

	this.ldService.updateaccounttables(this.selectedChoiceID,this.client, this.loggedAgent).subscribe(data => {
	 	    localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(this.client));  
  	},
	  error => {
		  this.alertService.clear()
		  this.alertService.error('Error occured while generating report')
  	});  
	 
}

onRangeSelectionChanged(event: any)
{ 
	/* if (!event.finished) {
		return;
	}  */
	this.calculateSum() 
}

calculateSum()
{
	var cellRanges = this.gridOptions.api.getCellRanges();

	var sum = 0;
	for (var rangeIndex = 0; rangeIndex<cellRanges.length; rangeIndex++) { 
		var firstRange = cellRanges[rangeIndex]; 

		// get starting and ending row, remember rowEnd could be before rowStart
		var startRow = Math.min(firstRange.startRow.rowIndex, firstRange.endRow.rowIndex);
		var endRow = Math.max(firstRange.startRow.rowIndex, firstRange.endRow.rowIndex);

		var api = this.gridOptions.api;
		for (var rowIndex = startRow; rowIndex<=endRow; rowIndex++) {
			firstRange.columns.forEach( function(column) { 
			 
				if(column.getColId()=="Year"||column.getColId()=="ClientAge"||column.getColId()=="SpouseAge")
				{
					//console.log("Don't add me")
				}
				else
				{
					var rowModel = api.getModel();
					var rowNode = rowModel.getRow(rowIndex);
					var value = api.getValue(column, rowNode); 
					
					var additup :Number
					if(isNaN(value.replace('$','').replace(/,/g,'')))
					{
						additup= 0
					}
					else
					{
						additup= Number(value.replace('$','').replace(/,/g,''))
					}   

					sum += Number(additup);
				}
			});
		}
	}

	this.SelectedSum = this.commonFunctions.currencyFormat(Number(sum))  //.toFixed(2)
}

getColumnDef():any{
	return this.columnDefs
}

getrowData():any{
	return this.rowData
}



exitBtnClickEvent()
	{
	localStorage.setItem(LocalStorage.SELECTED_CLIENTID, "");
	//localStorage.setItem(LocalStorage.CURRENT_CLIENT,JSON.stringify(new Client));
	localStorage.removeItem(LocalStorage.CURRENT_CLIENT);
	this.router.navigateByUrl('landing'); 
	}

radioClick(value: any)
	{ 
		if (value=="default")
		{
			this.clientagelist = []
			this.spouseagelist = []
			this.selectedoption = Constants.REPORT_TYPE.Rpt_Default
			this.passAt = 0
			this.setReportOptions(Constants.REPORT_TYPE.Rpt_Default,0)
			this.loadReportData()
		}
		else if (value=="client_passes")
		{
			this.clientagelist = this.loadPassesAge(this.client.ClientDOB)
			this.spouseagelist = []
		}
		else if (value=="spouse_passes")
		{
			this.clientagelist = []
			this.spouseagelist = this.loadPassesAge(this.client.SpouseDOB)
		}
	}
changeClientPassesAt(passAt: any)
	{ 
		this.selectedoption = Constants.REPORT_TYPE.Rpt_ClientDies
		this.passAt = passAt
		this.setReportOptions(Constants.REPORT_TYPE.Rpt_ClientDies,passAt)
		this.loadReportData()
	}

changeSpousePassesAt(passAt: any)
	{ 
		this.selectedoption = Constants.REPORT_TYPE.Rpt_SpouseDies
		this.passAt = passAt
		this.setReportOptions(Constants.REPORT_TYPE.Rpt_SpouseDies,passAt)
		this.loadReportData()
	}

loadOptions()
{


	if  (this.client.SpouseFirstName != "" && this.client.SpouseLastName != "") 
    { 
      this.hasSpouse=true
    }
    else
    { 
      this.hasSpouse=false
    }

	if (this.currentChoice.ReportKind===Constants.REPORT_TYPE.Rpt_Default)
		{
			this.clientagelist = []
			this.spouseagelist = []
			this.clientDiesAt = -1
			this.spouseDiesAt  = -1

			this.selectedoption = Constants.REPORT_TYPE.Rpt_Default
			this.passAt = 0
		}
		else if (this.currentChoice.ReportKind===Constants.REPORT_TYPE.Rpt_ClientDies)
		{
			this.clientagelist = this.loadPassesAge(this.client.ClientDOB)
			this.spouseagelist = []
			this.clientDiesAt = this.currentChoice.ClientDiesAtAge
			this.spouseDiesAt  = -1

			this.selectedoption = Constants.REPORT_TYPE.Rpt_ClientDies
			this.passAt =this.currentChoice.ClientDiesAtAge
		}
		else if (this.currentChoice.ReportKind===Constants.REPORT_TYPE.Rpt_SpouseDies)
		{
			this.clientagelist = []
			this.spouseagelist = this.loadPassesAge(this.client.SpouseDOB)
			this.clientDiesAt = -1
			this.spouseDiesAt  = this.currentChoice.SpouseDiesAtAge

			this.selectedoption = Constants.REPORT_TYPE.Rpt_SpouseDies
			this.passAt =this.currentChoice.SpouseDiesAtAge
		}
}

loadPassesAge(DOB :Date) : Array<any>
	{
		let CurrentAge:number
		let CurrentYear: number

		let agelist :Array<any> = []

		// if the client records are from provious software we need to start from the ModelStartyear field
		if (this.client.Choice.length > 0)
		{    
			let MinYear:number =Number(this.client.Choice.find(x => x.ChoiceID == this.selectedChoiceID).ModelStartYear)
 	 
			CurrentAge = MinYear - new Date(DOB).getFullYear() 
			CurrentYear = MinYear
		}
		else
		{ 
			CurrentAge =  new Date().getFullYear() - new Date(DOB).getFullYear() 
			CurrentYear = new Date().getFullYear()
		} 

		agelist.push({value:"-1", text:""})

		for (var i=CurrentAge; i <= 100; i++) {
			agelist.push({value:CurrentAge, text: CurrentYear + ", " + "Age=" + CurrentAge })
			CurrentAge += 1
			CurrentYear += 1
		} 
		return agelist
		

	}

setReportOptions(selectedoption: any,passAt: number)
	{  
		if (selectedoption===Constants.REPORT_TYPE.Rpt_Default){
			this.currentChoice.ReportKind = Constants.REPORT_TYPE.Rpt_Default
			this.currentChoice.ClientDiesAtAge = 0
			this.currentChoice.SpouseDiesAtAge = 0
			this.currentChoice.ReportTitleLine1 = Constants.REPORT_NAME
			this.currentChoice.ReportTitleLine2 = this.client.ClientFirstName + " " + this.client.ClientLastName
			this.currentChoice.ReportTitleLine3 = formatDate(new Date(this.client.GeneratedOn), 'MM/dd/yyyy', 'en') 
		}else if (selectedoption===Constants.REPORT_TYPE.Rpt_ClientDies){
 
			this.currentChoice.ReportKind = Constants.REPORT_TYPE.Rpt_ClientDies 
			this.currentChoice.ClientDiesAtAge = passAt
			this.currentChoice.SpouseDiesAtAge = 0
			this.currentChoice.ReportTitleLine1 = Constants.REPORT_NAME
			this.currentChoice.ReportTitleLine2 = "Report scenario is " + this.client.ClientFirstName + " " + this.client.ClientLastName  + " " + " dies at age " + passAt
			this.currentChoice.ReportTitleLine3 = formatDate(new Date(this.client.GeneratedOn), 'MM/dd/yyyy', 'en') 
		}else if (selectedoption===Constants.REPORT_TYPE.Rpt_SpouseDies){ 

			this.currentChoice.ReportKind = Constants.REPORT_TYPE.Rpt_SpouseDies 
			this.currentChoice.ClientDiesAtAge = 0
			this.currentChoice.SpouseDiesAtAge = passAt
			this.currentChoice.ReportTitleLine1 = Constants.REPORT_NAME
			this.currentChoice.ReportTitleLine2 = "Report scenario is " + this.client.SpouseFirstName  + " " + this.client.SpouseLastName  + " " + " dies at age " + passAt
			this.currentChoice.ReportTitleLine3 = formatDate(new Date(this.client.GeneratedOn), 'MM/dd/yyyy', 'en') 
		}  

	} 

saveReportBtnClickEvent()
	{		 
		setTimeout(() => { 
			this.setChoiceInfomation()
			this.saveClient()

			localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(this.client));  
			this.sharedService.changeSideBarRequest()   
	}, 50); 
	}

printBtnClickEvent()
	{
		setTimeout(() => { 
			this.ldService.getgeneratedpdf(this.selectedChoiceID,this.client, this.loggedAgent).subscribe(data => {  
				var file = new Blob([data], {type: 'application/pdf'});
				var fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			},
				error => {

					//console.log(error)
					this.alertService.clear()
					this.alertService.error('Error occured while generating pdf report')
			}); 
		}, 50);
	}

showHideBtnClickEvent()
	{
		if (this.showhide){		
			this.showhide = false
			this.gridHeight = window.innerHeight - this.reportOptionHeight
		}
		else{
			this.showhide = true
			this.gridHeight = window.innerHeight - this.reportOptionHeight-60//12
		} 	
			
	}

setChoiceInfomation()
	{
  
	  var oChoice : Choice = new Choice 
	  var ChoiceID : string = this.commonFunctions.getnewguid() 
  
	 /*  var CurrentYear : number = new Date().getFullYear()
	  var ClientAge : number = new Date().getFullYear() - new Date(this.clientDOB).getFullYear();  
	  var SpouseAge : number=  new Date().getFullYear() - new Date(this.spouseDOB).getFullYear();  
	 */
	 //var LastYear : Number= CurrentYear + ( Constants.LAST_YEAR - ClientAge)
	  var oSelectedChoice  : Choice = new Choice 
	  oSelectedChoice = this.client.Choice.find(x => x.ChoiceID == this.selectedChoiceID)

	  oChoice.ChoiceID = ChoiceID
	  oChoice.IsActiveChoice = false
	  oChoice.IsReadOnly = false 

	  if (this.selectedoption==Constants.REPORT_TYPE.Rpt_Default){ 
		oChoice.ChoiceName = Constants.DEFAULT_CHOICE_NAME + "(1)"
		oChoice.ReportKind = Constants.REPORT_TYPE.Rpt_Default
		oChoice.ClientDiesAtAge = 0
		oChoice.SpouseDiesAtAge = 0
		oChoice.ReportTitleLine1 = Constants.REPORT_NAME
		oChoice.ReportTitleLine2 = this.client.ClientFirstName + " " + this.client.ClientLastName
		oChoice.ReportTitleLine3 = formatDate(new Date(this.client.GeneratedOn), 'MM/dd/yyyy', 'en') 
	}else if (this.selectedoption==Constants.REPORT_TYPE.Rpt_ClientDies){
		oChoice.ChoiceName = this.client.ClientFirstName+ " " +  " dies at age " + this.passAt
		oChoice.ReportKind = Constants.REPORT_TYPE.Rpt_ClientDies
		oChoice.ClientDiesAtAge = this.passAt
		oChoice.SpouseDiesAtAge = 0
		oChoice.ReportTitleLine1 = Constants.REPORT_NAME
		oChoice.ReportTitleLine2 = "Report scenario is " + this.client.ClientFirstName + " " + this.client.ClientLastName  + " " + " dies at age " + this.passAt
		oChoice.ReportTitleLine3 = formatDate(new Date(this.client.GeneratedOn), 'MM/dd/yyyy', 'en') 
	}else if (this.selectedoption==Constants.REPORT_TYPE.Rpt_SpouseDies){ 
		oChoice.ChoiceName =this.client.SpouseFirstName+ " " +  " dies at age " + this.passAt
		oChoice.ReportKind = Constants.REPORT_TYPE.Rpt_SpouseDies
		oChoice.ClientDiesAtAge = 0
		oChoice.SpouseDiesAtAge = this.passAt
		oChoice.ReportTitleLine1 = Constants.REPORT_NAME
		oChoice.ReportTitleLine2 = "Report scenario is " + this.client.SpouseFirstName  + " " + this.client.SpouseLastName  + " " + " dies at age " + this.passAt
		oChoice.ReportTitleLine3 = formatDate(new Date(this.client.GeneratedOn), 'MM/dd/yyyy', 'en') 
	}  

	 // oChoice.ChoiceName = oSelectedChoice.ChoiceName + "(1)"
	  oChoice.DisplayOrder = this.getNextOrder(Constants.TABLE_NAME.Choice)
	  oChoice.ModelStartYear = oSelectedChoice.ModelStartYear 
	  oChoice.ModelEndYear = oSelectedChoice.ModelEndYear 
	  oChoice.ViewStartYear = oSelectedChoice.ViewStartYear 
	  oChoice.ViewEndYear = oSelectedChoice.ViewEndYear 
	  oChoice.NumYearsInView = oSelectedChoice.NumYearsInView

	  this.client.Choice.push(oChoice)

	  // Add IncomeSourceAccount
	if (typeof this.client.IncomeSourceAccount !== 'undefined') {
		this.client.IncomeSourceAccount.filter(x => x.ChoiceID == this.selectedChoiceID).forEach((oIncomeSourceAccount:IncomeSourceAccount) => { 
		let tempIncomeSourceAccount:IncomeSourceAccount= new  IncomeSourceAccount()

		tempIncomeSourceAccount.IncomeSourceID =  this.commonFunctions.getnewguid() 
		tempIncomeSourceAccount.ChoiceID = ChoiceID
		tempIncomeSourceAccount.Owner = oIncomeSourceAccount.Owner
		tempIncomeSourceAccount.Source = oIncomeSourceAccount.Source
		tempIncomeSourceAccount.StartYear = oIncomeSourceAccount.StartYear
		tempIncomeSourceAccount.EndYear = oIncomeSourceAccount.EndYear
		tempIncomeSourceAccount.COLAFlag = oIncomeSourceAccount.COLAFlag
		tempIncomeSourceAccount.COLAPct = oIncomeSourceAccount.COLAPct
		tempIncomeSourceAccount.SurvivorBenefitFlag = oIncomeSourceAccount.SurvivorBenefitFlag
		tempIncomeSourceAccount.SurvivorBenefitPct = oIncomeSourceAccount.SurvivorBenefitPct
		tempIncomeSourceAccount.AnnualAmount =oIncomeSourceAccount.AnnualAmount
		tempIncomeSourceAccount.IncomeForLifeComment = oIncomeSourceAccount.IncomeForLifeComment
		tempIncomeSourceAccount.DisplayOrder = oIncomeSourceAccount.DisplayOrder
		tempIncomeSourceAccount.StartDate = oIncomeSourceAccount.StartDate
		tempIncomeSourceAccount.EndDate = oIncomeSourceAccount.EndDate
		this.client.IncomeSourceAccount.push(tempIncomeSourceAccount)
	   })  
	}

	  // Add IncomeFromAsset
	  if (typeof this.client.IncomeFromAsset !== 'undefined') {
	  	this.client.IncomeFromAsset.filter(x => x.ChoiceID == this.selectedChoiceID).forEach((oIncomeFromAsset:IncomeFromAsset) => { 
		
		let tempIncomeFromAsset:IncomeFromAsset =new IncomeFromAsset()

		tempIncomeFromAsset.IFAID =  this.commonFunctions.getnewguid() 
		tempIncomeFromAsset.ChoiceID = ChoiceID
		tempIncomeFromAsset.Owner = oIncomeFromAsset.Owner
		tempIncomeFromAsset.Source = oIncomeFromAsset.Source
		tempIncomeFromAsset.Deposit = oIncomeFromAsset.Deposit
		tempIncomeFromAsset.BonusFlag = oIncomeFromAsset.BonusFlag
		tempIncomeFromAsset.BonusPct = oIncomeFromAsset.BonusPct
		tempIncomeFromAsset.YearsOfDeferral = oIncomeFromAsset.YearsOfDeferral
		tempIncomeFromAsset.StartYear = oIncomeFromAsset.StartYear
		tempIncomeFromAsset.RollUpHow = oIncomeFromAsset.RollUpHow
		tempIncomeFromAsset.RollUpPct = oIncomeFromAsset.RollUpPct
		tempIncomeFromAsset.AccountValue = oIncomeFromAsset.AccountValue
		tempIncomeFromAsset.PayoutPct =oIncomeFromAsset.PayoutPct
		tempIncomeFromAsset.SurvivorshipHow = oIncomeFromAsset.SurvivorshipHow
		tempIncomeFromAsset.SurvivorshipPct = oIncomeFromAsset.SurvivorshipPct
		tempIncomeFromAsset.COLAFlag = oIncomeFromAsset.COLAFlag
		tempIncomeFromAsset.COLAPct = oIncomeFromAsset.COLAPct
		tempIncomeFromAsset.DisplayOrder = oIncomeFromAsset.DisplayOrder
		tempIncomeFromAsset.AnnualContribution = oIncomeFromAsset.AnnualContribution
		tempIncomeFromAsset.StartDate = oIncomeFromAsset.StartDate

		this.client.IncomeFromAsset.push(tempIncomeFromAsset)
	   })
	}
  
	}

	
	getNextOrder(incometype :string) : any
	{ 
		 var currentOrderID :number = 0
		 if (incometype == Constants.TABLE_NAME.IncomeSources)  
		 {
		   /*  var tempIncomeSourceAccount As New List(Of Models.IncomeSourceAccount)
			 tempIncomeSourceAccount = oClient.IncomeSourceAccount.Where(Function(x) x.ChoiceID.ToString = mSelectedChoiceID.ToString()).ToList
   
			 If tempIncomeSourceAccount.Count > 0 Then
				 currentOrderID = tempIncomeSourceAccount.Max(Function(g) g.DisplayOrder)
			 Else
				 currentOrderID = 0
			 End If
			 */
		 }
		 else  if (incometype == Constants.TABLE_NAME.IncomeFromAssets)  
		 {
			/* var tempIncomeFromAsset As New List(Of Models.IncomeFromAsset)
			 tempIncomeFromAsset = oClient.IncomeFromAsset.Where(Function(x) x.ChoiceID.ToString = mSelectedChoiceID.ToString()).ToList
			 If tempIncomeFromAsset.Count > 0 Then
				 currentOrderID = tempIncomeFromAsset.Max(Function(g) g.DisplayOrder) + 20 ' Adding 20 to make sure new IncomeFromAssest goes to last
			 Else
				 currentOrderID = 0 + 20 ' Adding 20 to make sure new IncomeFromAssest goes to last
			 End If*/
		 }
		 else if (incometype == Constants.TABLE_NAME.Choice)     
		 {       
			 if (this.client.Choice.length > 0 ) 
				 currentOrderID = Math.max.apply(Math, this.client.Choice.map(function(o) { return o.DisplayOrder; }))
		     else
				 currentOrderID = 0          
		 } 
		 let orderID = currentOrderID + 1 //iOrder + 1
		 return orderID
	
   }

   saveClient()
  {
    this.ldService.saveclient(this.client, this.loggedAgent).subscribe(date =>
      {
        this.alertService.clear()
        //this.alertService.success('Successfully saved')
      },
      error => {
        this.alertService.clear()
        this.alertService.error('Error occured while saving data')
       });
  }
   
 
	 
}