import { Injectable , OnInit} from '@angular/core';
import { DynamicSideBarItem } from './dynamic-sidebar-item.model'; 
import { Client } from '../models/client.model';
import { Agent } from '../models/agent.model';  
import { Choice } from '../models/choice.model';
import { LocalStorage } from '../util/localstorage.service';
import { ClientInfoDataService } from '../appComp/client-info/client-info-data.service';
import { AlertService } from '../util/alert/alert.service';
import { CommonFunctions } from '../util/common-functions';  


@Injectable({
	providedIn: 'root'
})
export class DynamicSidebarService  {

	client:Client = new Client
	public loggedAgent: Agent 
	private selectClientID:any
	public dynamicSidebar: Array<DynamicSideBarItem>  =[] 
	private commonFunctions: CommonFunctions = new CommonFunctions

	constructor(private ldService: ClientInfoDataService, private alertService: AlertService ) {  
		// this.loadDynamicSidebar()
	}

	ngOnInit() {  
		
	}  

 	public loadDynamicSidebar() : Array<DynamicSideBarItem>
	{ 
		this.dynamicSidebar = []
		if (localStorage.hasOwnProperty(LocalStorage.CURRENT_CLIENT))	
		{ 
		   this.client   = JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) as Client; 
		  
		   let sortedChoice : Array<Choice> = this.client.Choice.sort((a, b) => Number(a.DisplayOrder) - Number(b.DisplayOrder));
		   sortedChoice.forEach((element: Choice) => {  
			   let choice: Choice = element   
			   //choice.ChoiceName = element.ClientFirstName + ' ' + element.ClientLastName
			   let tempDynamicSideBarItem: DynamicSideBarItem  = new DynamicSideBarItem
			   tempDynamicSideBarItem.id = choice.ChoiceID
			   tempDynamicSideBarItem.text = choice.ChoiceName //+ " {" +  Number(choice.DisplayOrder) + "}" 

			   tempDynamicSideBarItem.canCopy = true 
			   tempDynamicSideBarItem.canMove=true  
				 
				if (choice.IsActiveChoice==true)
				{
					//console.log("true")
					tempDynamicSideBarItem.canDelete = false 
					tempDynamicSideBarItem.canEdit= false
				}
				else
				{
					//.log("false")
					tempDynamicSideBarItem.canDelete = true 
					tempDynamicSideBarItem.canEdit= true
				}
				tempDynamicSideBarItem.orderNum = Number(choice.DisplayOrder)
			 
			   this.dynamicSidebar.push(tempDynamicSideBarItem) 
		   });   
		}
		/* else
		{
		   this.dynamicSidebar=[]
		} */
		return this.dynamicSidebar
	} 

	 

/*	dynamicSidebar: Array<DynamicSideBarItem> = [
 

		 {
			id: '1', text: 'Scott', canCopy: true,
			canDelete: false, canEdit: true, canMove:true,
			orderNum: 1
		},
		{
			id: '2', text: 'Scott Die 70', canCopy: true,
			canDelete: true, canEdit: true, canMove:true,
			orderNum: 2
		} 
	 ]*/

	addNewDynamicSideBarItem(item: DynamicSideBarItem) {
	 	 
	} 

	deleteSideBarItem(item: DynamicSideBarItem) {
		let index = this.dynamicSidebar.findIndex(obj => obj.id === item.id)
		this.dynamicSidebar.splice(index, 1)
	}

	renameSideBarItem(item: DynamicSideBarItem, newName: string) {
		let dyeItem: DynamicSideBarItem = this.dynamicSidebar.find(x => x.id == item.id);
		dyeItem.text = newName

		let oSelectedChoice:Choice = new Choice
		oSelectedChoice = this.client.Choice.find(x => x.ChoiceID == item.id) 

		oSelectedChoice.ChoiceName = newName

		let choicelist:Array<Choice> = []
		choicelist.push(oSelectedChoice)
		this.updateChoice(choicelist) 
	}


	itemMoveUp(item: DynamicSideBarItem) {
		let on: number = item.orderNum
		let prevItem: DynamicSideBarItem = this.dynamicSidebar.find(x => x.orderNum == (on - 1));
		prevItem.orderNum = prevItem.orderNum + 1
		item.orderNum = item.orderNum - 1
	}

	itemMoveDown(item: DynamicSideBarItem) {
		let on: number = item.orderNum
		let nextItem: DynamicSideBarItem = this.dynamicSidebar.find(x => x.orderNum == (on + 1));
		nextItem.orderNum = nextItem.orderNum - 1
		item.orderNum = item.orderNum + 1
	} 

	updateChoice(choicelist:Array<Choice>)
	{  
	  this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
	  this.ldService.updatechoice(choicelist, this.loggedAgent).subscribe(date =>
		{
		 // this.alertService.clear()
		 // this.alertService.success('Successfully saved')
		 	localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(this.client));  
		},
		error => {
		  this.alertService.clear()
		  this.alertService.error('Error occured while saving data')
		 });
	} 
}
