export class IncomeFromAsset {
    constructor(
        public IFAID?: string,
        public ChoiceID?: string,
        public Owner?: string,
        public Source?: string,
        public Deposit?: string,
        public BonusFlag?: boolean,
        public BonusPct?: Number,
        public YearsOfDeferral?: string,
        public StartYear?: string,
        public RollUpHow?: string,
        public RollUpPct?: Number,
        public AccountValue?: Number,
        public PayoutPct?: Number,
        public SurvivorshipHow?: string,
        public SurvivorshipPct?: Number,
        public COLAFlag?: boolean,
        public COLAPct?: Number,
        public DisplayOrder?: Number,
        public AnnualContribution?: Number,
        public StartDate?: string,
        public HasLTC?: boolean,
        public LTCNotAvailableFor?: Number,
        public LTCMultiplier?: Number,
        public LTCPeriod ?: Number,

        public ClientMonthlyDeposit ?: Number,
        public ClientMonthlyDepositGrowthRate ?: Number,
        public ClientMonthlyDepositStartDate?: Date,
        public ClientMonthlyDepositEndDate?: Date,
        public SpouseMonthlyDeposit ?: Number,
        public SpouseMonthlyDepositGrowthRate ?: Number,
        public SpouseMonthlyDepositStartDate?: Date,
        public SpouseMonthlyDepositEndDate?: Date,
    ) {}
}