//import { Injectable } from "../../../node_modules/@angular/core";
//import { Constants} from './constants';

 
export class CommonFunctions {
    constructor(){}
    private s4(): string {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
  }
  
  public getnewguid(): string {
		return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }

  public currencyFormat(num) {
    //return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // removing cents
   // return '$' + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    
    return '$' + Math.round(num).toString()
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  public currencyFormatWithNegative(num) {
    //return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // removing cents
   // return '$' + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    
    return '$' + num.toString() 
    .replace(/[^0-9.-]/g, "") 
    .replace(/,/g,'')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  public AddThousandSeparatorFormat(num) { 
    var roundedoffnum = this.RoundOffDecimal(this.RemoveThousandSeparatorFormat(num))
    return roundedoffnum.toString() 
          .replace(/[^0-9.]/g, "") 
          .replace(/,/g,'')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    
   /*  return  num.toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") */
  }

  public IsFloat(num) {return !!(num % 1);}

  RoundTo(num: number, places: number) {
    var factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  public RoundOffDecimal(num){
    var returnednum = num
    if(this.IsFloat(num)){
      var text = num.toString();
      var index = text.indexOf(".");
      if((text.length - index - 1) > 2){
         returnednum = this.RoundTo(parseFloat(returnednum),2)
      }
    }
    if(num != null && num != "" && num != undefined){
      if(num[num.length - 3] == "." && num[num.length - 2] == "0" && num[num.length - 1] == "0"){
        returnednum = parseFloat(returnednum)
      }
    }
    return returnednum
  }

  public RemoveThousandSeparatorFormat(num) { 
    if(num == null || num == undefined){
      num = 0
    }
    var roundingoffnum = num.toString().replace('$','').replace(/,/g,'')
    return this.RoundOffDecimal(roundingoffnum)
  }
  
  // public AddThousandSeparatorFormat(num) { 
  //   return  num.toString() 
  //         .replace(/[^0-9.]/g, "") 
  //         .replace(/,/g,'')
  //         .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  //  /*  return  num.toString()
  //         .replace(/\D/g, "")
  //         .replace(/\B(?=(\d{3})+(?!\d))/g, ",") */
  // }

  // public RemoveThousandSeparatorFormat(num) { 
  //   return  num.toString()
  //         .replace('$','').replace(/,/g,'')
  // }

  public formatDatetime(date: Date, format: string) {
    const _padStart = (value: number): string => value.toString().padStart(2, '0');
 return format
     .replace(/yyyy/g, _padStart(date.getFullYear()))
     .replace(/dd/g, _padStart(date.getDate()))
     .replace(/mm/g, _padStart(date.getMonth() + 1))
     .replace(/hh/g, _padStart(date.getHours()))
     .replace(/ii/g, _padStart(date.getMinutes()))
     .replace(/ss/g, _padStart(date.getSeconds()));
 }
}