import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../util/localstorage.service'; 
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Agent } from '../models/agent.model';
import { AlertService } from '../util/alert/alert.service';
import { BsModalService,BsModalRef}   from 'ngx-bootstrap/modal';
import { SharedServiceService } from '../util/shared-service.service';
import { MondayallocationrequestDataService } from './mondayallocationrequest-data.service';
import { MondayAllocation } from '../models/monday-allocation.model';
import { GridOptions } from 'ag-grid-community'
import { CustomHeader} from '../util//customHeader'
  
@Component({
  selector: 'app-mondayallocationrequest',
  templateUrl: './mondayallocationrequest.component.html',
  styleUrls: ['./mondayallocationrequest.component.scss']
})

export class MondayAllocationRequestComponent implements OnInit {
  
  public paddingtop: number = 60;
  public selectedChoiceID: string = ""; 
  public loggedAgent: Agent 
  public iSForm: FormGroup;
  public uploadforms: FormData;
  public fileInfos: Array<any> = [];
  public file: string;
  public mondayallocation: MondayAllocation

  public name:any
  public advisorsArray: Array<any>
  public advisorname: any
  public companyname:any
  public login:any
  public lastname:any
  public firstname:any
  public lastnameclient2:any
  public firstnameclient2:any
  public accountname: any
  public accounttype: any
  public accountnumber: any
  public accounttypequalified: any
  public accounttypenonqualified: any
  public accounttypeentity: any
  public accountclassification: any
  public showAccountTypeQualified: boolean = true
  public showAccountTypeNonQualified: boolean = true
  public showAccountTypeEntity: boolean = true
  public custodian:any
  public instructiontype: any
  public highyieldcorporatebond: any
  public strategicenhancedbond: any
  public logicinvestsleepwellbond: any
  public strategichedgedincome: any
  public economiccycle: any
  public foundation: any
  public logicinvestglobalsector: any
  public logicinvestcountryrotation: any
  public employmenttrendsstrategy: any
  public primedividendinternational: any
  public usprimedividendjr: any
  public strategicmidcap: any
  public usprimedividend: any
  public NASDAQleaders: any
  public sectorrotation: any
  public valuediscount: any
  public valueexposure :any
  public reflationstrategy:any
  public accountsize: any
  public pendingannuities: any
  public heldpositions: any
  public notes: any
  private gridApi1: any;
  private gridColumnApi1: any;
  private gridApi2: any;
  private gridColumnApi2: any;
  private gridApi3: any;
  private gridColumnApi3: any;
  public columnDefs1: any;
  public columnDefs2: any;
  public columnDefs3: any;
  public rowData1: any;	
  public rowData2: any;
  public rowData3: any;
  public gridOptions1 : GridOptions 
  public gridOptions2 : GridOptions 
  public gridOptions3 : GridOptions 
  public currentSelectedColumn;
  public totalPercentage: number = 0
  public showClient2Details:boolean = false
  private clearalert:boolean = false
  public disablesubmitbtn: boolean = false

  getRowStyle: (params: any) => { "font-weight": string; };
  pinnedBottomRowData: any;
  frameworkComponents: { customPinnedRowRenderer: any; };

  constructor( private router: Router,
    private formBuilder: FormBuilder, private alertService: AlertService,
    private bsModalService :BsModalService, private mondayallocationrequestdataservice: MondayallocationrequestDataService,
    private sharedService: SharedServiceService) {

      this.gridOptions1 = { 
        components: {
          agColumnHeader: CustomHeader
        },
        defaultColDef: { 
          headerComponentParams: {
            setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
            getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          } 
        },
        groupHeaderHeight: 30,
        enableColResize: true
      };

      this.columnDefs1 = [
              { 
                headerName: 'Investment Name', 
                field: 'InvestmentName',
                //sortable: true, 
                width: 200,
                cellStyle: {textAlign: "left"},
                lockPosition: true
                // filter: true//,
                //cellClass: "rag-blue"
              },
              { 
                headerName: 'Min. Investment', 
                field: 'MinInvestment',
                width: 110,
                cellStyle: {textAlign: "right"},
                lockPosition: true
                // width: 125,
                // filter: true//,
                //cellClass: "rag-blue"
              },
              { 
                headerName: '%', 
                field: 'Percentage',
                editable: true,
                width: 60,
                cellStyle: {textAlign: "right"},
                lockPosition: true
                // sortable: true, 
                // filter: true,
                // cellClass: "rag-blue"
              },
              { 
                headerName: 'Mgmt Fee', 
                field: 'MgmtFee',
                width: 80, 
                cellStyle: {textAlign: "right"},
                lockPosition: true
                // sortable: true, 
                // filter: true,
                // cellClass: "rag-yellow"
              }        
      ];

        this.gridOptions2 = { 
          components: {
            agColumnHeader: CustomHeader
          },
          defaultColDef: { 
            headerComponentParams: {
              setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
              getSelectedColumn: () =>  this.getCurrentSelectedColumn()
            } 
          },
          //alignedGrids: [this.gridOptions1],
          groupHeaderHeight: 30,
          enableColResize: true  
        };
  
        this.columnDefs2 = [
                { 
                  headerName: 'Investment Name', 
                  field: 'InvestmentName',
                  width: 200,
                  cellStyle: {textAlign: "left"},
                  lockPosition: true
                  //sortable: true, 
                  // width: 125,
                  // filter: true//,
                  //cellClass: "rag-blue"
                },
                { 
                  headerName: 'Min. Investment', 
                  field: 'MinInvestment',
                  width: 110,
                  cellStyle: {textAlign: "right"},
                  lockPosition: true
                  // width: 125,
                  // filter: true//,
                  //cellClass: "rag-blue"
                },
                { 
                  headerName: '%', 
                  field: 'Percentage',
                  editable: true,
                  width: 60,
                  cellStyle: {textAlign: "right"},
                  lockPosition: true
                  // sortable: true, 
                  // filter: true,
                  // cellClass: "rag-blue"
                },
                { 
                  headerName: 'Mgmt Fee', 
                  field: 'MgmtFee',
                  width: 80,
                  cellStyle: {textAlign: "right"},
                  lockPosition: true
                  // sortable: true, 
                  // filter: true,
                  // cellClass: "rag-yellow"
                }
        ];

        this.gridOptions3 = { 
            components: {
              agColumnHeader: CustomHeader
            },
            defaultColDef: { 
              headerComponentParams: {
                setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
                getSelectedColumn: () =>  this.getCurrentSelectedColumn()
              } 
            },
            //alignedGrids: [this.gridOptions2],
            groupHeaderHeight: 30, 
            enableColResize: true  
        };
    
        this.columnDefs3 = [
                  {
                    headerName: 'Investment Name', 
                    field: 'InvestmentName',
                    width: 200,
                    lockPosition: true,
                    //sortable: true, 
                    // width: 125,
                    // filter: true//,
                    //cellClass: "rag-blue"
                    cellStyle:  function(params) {
                      if (params.node.rowPinned){
                        return {backgroundColor: "#dcddde", textAlign: "right"}
                      }else{
                        return {textAlign: "left"}
                      }
                    }
                  },
                  { 
                    headerName: 'Min. Investment', 
                    field: 'MinInvestment',
                    width: 110,
                    lockPosition: true,
                    // filter: true//,
                    //cellClass: "rag-blue"
                    cellStyle:  function(params) {
                      if (params.node.rowPinned){
                        return {backgroundColor: "#dcddde", textAlign: "left"}
                      }else{
                        return {textAlign: "right"}
                      }
                    },
                  },
                  { 
                    headerName: '%', 
                    field: 'Percentage',
                    width: 60,
                    lockPosition: true,
                    // sortable: true, 
                    // filter: true,
                    // cellClass: "rag-blue"
                    cellStyle:  function(params) {
                      if (params.node.rowPinned){
                        return {backgroundColor: "#dcddde", textAlign: "right"}
                      }else{
                        return {textAlign: "right"}
                      }
                    },
                    editable: function(params) {
                      if (params.node.rowPinned){
                        return false;
                      }else{
                        return true;
                      }
                    }
                  },
                  { 
                    headerName: 'Mgmt Fee', 
                    field: 'MgmtFee',
                    width: 80,
                    cellStyle:  function(params) {
                      if (params.node.rowPinned){
                        return {backgroundColor: "#dcddde", textAlign: "right"}
                      }else{
                        return {textAlign: "right"}
                      }
                    }, 
                    lockPosition: true
                    // sortable: true, 
                    // filter: true,
                    // cellClass: "rag-yellow"
                  },
        ];

        this.getRowStyle = function(params) {
          if (params.node.rowPinned) {
            return { "font-weight": "bold"};
          }
        };
    }

    onPinnedRowBottomCount() {
      var footerRowsToFloat = 10;
      var count = Number(footerRowsToFloat);
      var rows = createData(count, "Bottom", this.totalPercentage + "%");
      setTimeout(()=> {
      this.gridApi3.setPinnedBottomRowData(rows);
      }, 50); 
    }

    gridSizeChanged(params: any) {
      params.api.sizeColumnsToFit();
    }

  ngOnInit() {

   this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
   this.login = this.loggedAgent.FirstName + " " + this.loggedAgent.LastName
   this.companyname = this.loggedAgent.OrganizationName
   this.iSForm = this.formBuilder.group({
      name: new FormControl(null) ,
      advisorname: new FormControl(null) ,
      companyname: new FormControl(null), 
      login: new FormControl(null),
      lastname: new FormControl(null),
      firstname: new FormControl(null),
      lastnameclient2: new FormControl(null),
      firstnameclient2: new FormControl(null),
      accountname: new FormControl(null) ,
      accounttype: new FormControl(null) ,
      accountclassification: new FormControl(null) ,
      accountnumber: new FormControl(null) ,
      accounttypequalified: new FormControl(null) ,
      accounttypenonqualified: new FormControl(null) ,
      accounttypeentity: new FormControl(null) ,
      custodian: new FormControl(null),
      instructiontype: new FormControl(null) ,
      highyieldcorporatebond: new FormControl(null) ,
      strategicenhancedbond: new FormControl(null) ,
      logicinvestsleepwellbond: new FormControl(null) ,
      strategichedgedincome: new FormControl(null) ,
      economiccycle: new FormControl(null) ,
      foundation: new FormControl(null) ,
      logicinvestglobalsector: new FormControl(null) ,
      logicinvestcountryrotation: new FormControl(null) ,
      employmenttrendsstrategy: new FormControl(null) ,
      primedividendinternational: new FormControl(null) ,
      usprimedividendjr: new FormControl(null) ,
      strategicmidcap: new FormControl(null) ,
      usprimedividend: new FormControl(null) ,
      NASDAQleaders: new FormControl(null) ,
      sectorrotation: new FormControl(null) ,
      valuediscount: new FormControl(null) ,
      valueexposure : new FormControl(null) ,
      reflationstrategy: new FormControl(null) ,
      accountsize : new FormControl(null),
      pendingannuities: new FormControl(null) ,
      heldpositions: new FormControl(null) ,
      notes: new FormControl(null) ,
      file: new FormControl(null)
    } );   

    this.getAdvisors()
    this.pinnedBottomRowData = createData(1, "Bottom", this.totalPercentage + "%");
    if(this.gridApi3 != undefined){
      this.gridApi3.setPinnedBottomRowData(this.pinnedBottomRowData);
    }
  }

  
  onGridReady1(params: any) { 
    this.rowData1 = []
		this.gridApi1  = params.api;
		this.gridColumnApi1 = params.columnApi;  
    this.rowData1.push({InvestmentName: "Strategic Hedged Income",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "High Yield Corporate Bond",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Strategic Enhanced Bond",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Sleep Well Bond",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"})    
    this.gridOptions1.api.hideOverlay()
    this.gridOptions1.api.setRowData(this.rowData1);
    //params.columnApi.autoSizeAllColumns();
    this.gridOptions1.api.sizeColumnsToFit();
  }

  onGridReady2(params: any) { 
    this.rowData2 = []
		this.gridApi2  = params.api;
		this.gridColumnApi2 = params.columnApi;  
    this.rowData2.push({InvestmentName: "Foundation",MinInvestment: "$3,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "U. S. Prime Dividend",MinInvestment: "$20,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "U. S. Prime Dividend Jr",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Global Sector",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Prime Dividend International",MinInvestment: "$10,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Economic Cycle",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Strategic Mid Cap",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Country Rotation",MinInvestment: "$5,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Employment Trends Strategy",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Sector Growth",MinInvestment: "$5,000",Percentage: "",MgmtFee: "0.75%"})    
    this.gridOptions2.api.hideOverlay()
    this.gridOptions2.api.setRowData(this.rowData2);
    //params.columnApi.autoSizeAllColumns();
    this.gridOptions2.api.sizeColumnsToFit();
  }

  onGridReady3(params: any) { 
    this.rowData3 = []
		this.gridApi3  = params.api;
		this.gridColumnApi3 = params.columnApi;  
    this.rowData3.push({InvestmentName: "NASDAQ Leaders",MinInvestment: "$20,000",Percentage: "",MgmtFee: "0.75%"}, 
                      {InvestmentName: "Value Discount",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Reflation Strategy",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"},
                      {InvestmentName: "Broad Value",MinInvestment: "$1,000",Percentage: "",MgmtFee: "0.75%"})    
    this.gridOptions3.api.hideOverlay()
    this.gridOptions3.api.setRowData(this.rowData3);
    //params.columnApi.autoSizeAllColumns();
    this.gridOptions3.api.sizeColumnsToFit();
  }

  getCurrentSelectedColumn() {
    return this.currentSelectedColumn;
  }
    
  setCurrentSelectedColumn(colId) {
    this.currentSelectedColumn = colId;
  }

  onCellValueChanged(event: any)
  {
    this.gridOptions1.api.stopEditing();
    this.gridOptions2.api.stopEditing();
    this.gridOptions3.api.stopEditing();
    
    this.totalPercentage = 0
    this.rowData1.forEach(data => {
      if(data.Percentage != ""){
        this.totalPercentage = this.totalPercentage + Number(data.Percentage)
      }  
    });
    this.rowData2.forEach(data => {
      if(data.Percentage != ""){
        this.totalPercentage = this.totalPercentage + Number(data.Percentage)
      } 
    });
    this.rowData3.forEach(data => {
      if(data.Percentage != ""){
        this.totalPercentage = this.totalPercentage + Number(data.Percentage)
      } 
    });
    setTimeout(()=> {
      this.pinnedBottomRowData = createData(1, "Bottom", this.totalPercentage+ "%");
      if(this.clearalert){
        this.alertService.clear()
      }
      this.gridOptions1.api.setRowData(this.rowData1);
      this.gridOptions2.api.setRowData(this.rowData2);
      this.gridOptions3.api.setRowData(this.rowData3);
      this.gridApi3.setPinnedBottomRowData(this.pinnedBottomRowData);
    }, 50);
    if(event.newValue != ""){

      if(event.newValue.match(/^\d+$/)){
        if((this.totalPercentage < 100) || (this.totalPercentage == 100)){   
  
          switch (event.data.InvestmentName) {
            case "High Yield Corporate Bond":
              this.highyieldcorporatebond = event.newValue
              break;
            case "Strategic Enhanced Bond": 
              this.strategicenhancedbond = event.newValue
              break;
            case "Sleep Well Bond":
              this.logicinvestsleepwellbond = event.newValue
              break;
            case "Strategic Hedged Income":
              this.strategichedgedincome = event.newValue
              break;
            case "Economic Cycle":
              this.economiccycle = event.newValue
              break;
            case "Foundation":
              this.foundation = event.newValue
              break;
            case "Global Sector":
              this.logicinvestglobalsector = event.newValue
              break;
            case "Country Rotation":
              this.logicinvestcountryrotation = event.newValue
              break;
            case "Prime Dividend International":
              this.primedividendinternational = event.newValue
              break;
            case "Strategic Mid Cap":
              this.strategicmidcap = event.newValue
              break;
            case "U. S. Prime Dividend":
              this.usprimedividend = event.newValue
              break;
            case "U. S. Prime Dividend Jr":
              this.usprimedividendjr= event.newValue
              break;
            case "Employment Trends Strategy":
              this.employmenttrendsstrategy= event.newValue
              break;
            case "NASDAQ Leaders":
              this.NASDAQleaders = event.newValue
              break;
            case "Sector Growth":
              this.sectorrotation = event.newValue
              break;
            case "Value Discount":
              this.valuediscount = event.newValue
              break;
            case "Broad Value":
              this.valueexposure = event.newValue
              break;
            case "Reflation Strategy":
              this.reflationstrategy = event.newValue
              break;

             
            default:
          }  
          
          //Validate for Minimum Investment amount
          this.ValidateMinimumInvestment(event.data.MinInvestment,event.newValue,event.data.InvestmentName)
          
        } else{
          this.alertService.clear()
          this.alertService.error('Total % should not exceed 100%')
        }
      } else{
        this.alertService.clear()
        event.newValue.replace(/\D/g, "")
        this.alertService.error('Please enter a whole number for the percentage')
      }
    }
  
  }

  getAdvisors(){
    this.advisorsArray = []
    this.mondayallocationrequestdataservice.getcompanyadvisors(this.loggedAgent.OrganizationID, this.loggedAgent).subscribe(data =>
    {
      this.alertService.clear()
      if(data != null && data != undefined){
        var i
        for(i=0; i < data.length; i++){
          let AdvisorName = data[i].FirstName + " " + data[i].LastName
          this.advisorsArray.push({id: AdvisorName, text: AdvisorName})
        } 
      }
      this.advisorname = this.advisorsArray[0].id
      return this.advisorsArray
      
    },
    error => { 
        this.alertService.clear()
        this.alertService.error('Error occured')       
    });
  }

  ValidateMinimumInvestment(minInvetment,newValue,investmentName):boolean
  { 
     let currentAccountSize 
     let currentPendingAnnuity
     let currentTotalAccountSize
     let currentMinInvestment = Number(minInvetment.replace('$','').replace(/,/g,''))
     let currentInvetment 
    // if ((isNaN(this.accountsize) || this.accountsize == undefined || this.accountsize == null ))
     if (this.accountsize == undefined || this.accountsize == null )
     { 
       currentAccountSize = 0;
     }  
     else 
     {
       currentAccountSize = Number(this.accountsize.replace('$','').replace(/,/g,''));
     }
 

     //if ((isNaN(this.pendingannuities) || this.pendingannuities == undefined || this.pendingannuities == null ))
     if (this.pendingannuities == undefined || this.pendingannuities == null )
     { 
       currentPendingAnnuity = 0;
     }  
     else 
     {
       currentPendingAnnuity = Number(this.pendingannuities.replace('$','').replace(/,/g,''));
     }
     currentTotalAccountSize = currentAccountSize - currentPendingAnnuity;
     currentInvetment = (currentTotalAccountSize*Number(newValue))/100
     if (currentInvetment<currentMinInvestment){ 
         this.alertService.error('Investment is less than the minimum value for ' + investmentName);
         return false
     } 
     else 
      return true;
  }

  validateMondayAllocationForm():boolean
  { 
    let percentageval = false
    if ((this.iSForm.controls.advisorname.value == ""  || 
      this.iSForm.controls.advisorname.value == null))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Advisor Name')
      return false
    } 

    if ((this.iSForm.controls.companyname.value == ""  || 
      this.iSForm.controls.companyname.value == null))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Company Name')
      return false
    }

    if ((this.iSForm.controls.login.value == ""  || 
      this.iSForm.controls.login.value == null))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Login')
      return false
    }

    if ((this.iSForm.controls.lastname.value == ""  || 
          this.iSForm.controls.lastname.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Client Last Name')
      return false
    }  

    if ((this.iSForm.controls.firstname.value == ""  || 
          this.iSForm.controls.firstname.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Client First Name')
      return false
    }  

    if(this.showClient2Details){
      if ((this.iSForm.controls.lastnameclient2.value == ""  || 
      this.iSForm.controls.lastnameclient2.value == null ))
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Client 2 Last Name')
        return false
      }  

      if ((this.iSForm.controls.firstnameclient2.value == ""  || 
            this.iSForm.controls.firstnameclient2.value == null ))
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Client 2 First Name')
        return false
      }  
    }

    if ((this.iSForm.controls.accountclassification.value == ""  || this.iSForm.controls.accountclassification.value == "---" ||
    this.iSForm.controls.accountclassification.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Account Classification')
      return false
    }

    if ((this.accounttype == ""  || this.accounttype == "---" ||
          this.accounttype == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Account Type')
      return false
    } 

    if ((this.iSForm.controls.accountname.value == ""  || 
          this.iSForm.controls.accountname.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Account Name')
      return false
    }   
 
    if ((this.iSForm.controls.accountnumber.value == ""  || 
          this.iSForm.controls.accountnumber.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Account Number')
      return false
    }  

    if ((this.iSForm.controls.custodian.value == ""  || this.iSForm.controls.custodian.value == "---" ||
          this.iSForm.controls.custodian.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Custodian')
      return false
    } 
    

    if ((this.iSForm.controls.instructiontype.value == ""  || this.iSForm.controls.instructiontype.value == "---" ||
          this.iSForm.controls.instructiontype.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Instruction Type')
      return false
    } 
    this.rowData1.forEach(data => {
      if(!data.Percentage.match(/^\d+$/) && data.Percentage != ""){
        percentageval = true
      }  
    });
    this.rowData2.forEach(data => {
      if(!data.Percentage.match(/^\d+$/) && data.Percentage != ""){
        percentageval = true
      } 
    });
    this.rowData3.forEach(data => {
      if(!data.Percentage.match(/^\d+$/) && data.Percentage != ""){
        percentageval = true
      } 
    });
    if(percentageval){
      this.alertService.clear()
      this.alertService.error('Please enter a whole number for the percentage')
      return false
    }
    if ((this.totalPercentage > 100))
    { 
      this.alertService.clear()
      this.alertService.error('Total % should not exceed 100%')
      return false
    }
    if ((isNaN(this.totalPercentage) || this.totalPercentage == undefined || this.totalPercentage == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid percentage value')
      return false
    } else{
      if(this.totalPercentage > 100 || this.totalPercentage < 100){
        this.alertService.clear()
        this.alertService.error('Percentage value should be 100%')
        return false
      }
    }
    // if (this.iSForm.controls.highyieldcorporatebond.value != ""  && 
    //       this.iSForm.controls.highyieldcorporatebond.value != null && !(/^\d+$/.test(this.iSForm.controls.highyieldcorporatebond.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid High Yield Corporate Bond Value')
    //   return false
    // }

    // if (this.iSForm.controls.strategicenhancedbond.value != ""  && 
    //       this.iSForm.controls.strategicenhancedbond.value != null && !(/^\d+$/.test(this.iSForm.controls.strategicenhancedbond.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Strategic Enhanced Bond Value')
    //   return false
    // }


    // if (this.iSForm.controls.logicinvestsleepwellbond.value != ""  && 
    //       this.iSForm.controls.logicinvestsleepwellbond.value != null && !(/^\d+$/.test(this.iSForm.controls.logicinvestsleepwellbond.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Logic Invest Sleep Well Bond Value')
    //   return false
    // }


    // if (this.iSForm.controls.strategichedgedincome.value != ""  && 
    //       this.iSForm.controls.strategichedgedincome.value != null && !(/^\d+$/.test(this.iSForm.controls.strategichedgedincome.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Strategic Hedged Income Value')
    //   return false
    // }


    // if (this.iSForm.controls.economiccycle.value != ""  && 
    //       this.iSForm.controls.economiccycle.value != null && !(/^\d+$/.test(this.iSForm.controls.economiccycle.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Economic Cycle Value')
    //   return false
    // }


    // if (this.iSForm.controls.foundation.value != ""  && 
    //       this.iSForm.controls.foundation.value != null && !(/^\d+$/.test(this.iSForm.controls.foundation.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Foundation Value')
    //   return false
    // }


    // if (this.iSForm.controls.logicinvestglobalsector.value != ""  && 
    //       this.iSForm.controls.logicinvestglobalsector.value != null && !(/^\d+$/.test(this.iSForm.controls.logicinvestglobalsector.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Logic Invest Global Sector Value')
    //   return false
    // }


    // if (this.iSForm.controls.primedividendinternational.value != ""  && 
    //       this.iSForm.controls.primedividendinternational.value != null && !(/^\d+$/.test(this.iSForm.controls.primedividendinternational.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Prime Dividend International Value')
    //   return false
    // }

    // if (this.iSForm.controls.strategicmidcap.value != ""  && 
    //       this.iSForm.controls.strategicmidcap.value != null && !(/^\d+$/.test(this.iSForm.controls.strategicmidcap.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Strategic Mid Cap Value')
    //   return false
    // }


    // if (this.iSForm.controls.usprimedividend.value != ""  && 
    //       this.iSForm.controls.usprimedividend.value != null && !(/^\d+$/.test(this.iSForm.controls.usprimedividend.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid U.S Prime Dividend Value')
    //   return false
    // }    


    // if (this.iSForm.controls.NASDAQleaders.value != ""  && 
    //       this.iSForm.controls.NASDAQleaders.value != null && !(/^\d+$/.test(this.iSForm.controls.NASDAQleaders.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid NASDAQ Leaders Value')
    //   return false
    // }

    // if (this.iSForm.controls.sectorrotation.value != ""  && 
    //       this.iSForm.controls.sectorrotation.value != null && !(/^\d+$/.test(this.iSForm.controls.sectorrotation.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Sector Rotation Value')
    //   return false
    // }


    // if (this.iSForm.controls.valuediscount.value != ""  && 
    //       this.iSForm.controls.valuediscount.value != null && !(/^\d+$/.test(this.iSForm.controls.valuediscount.value)))
    // { 
    //   this.alertService.clear()
    //   this.alertService.error('Invalid Value Discount Value')
    //   return false
    // }


    if ((this.fileInfos == undefined  || this.fileInfos.length == 0 ||
      this.fileInfos == null ))
    { 
      this.alertService.clear()
      this.alertService.error('No file is selected')
      return false
    } 

    //Minimum investment validation
    let passedMinInvestmentValidation: boolean = true;
    this.rowData1.forEach(data => {
      if(data.Percentage != ""){ 
        if (!this.ValidateMinimumInvestment(data.MinInvestment,Number(data.Percentage),data.InvestmentName))
        {
          passedMinInvestmentValidation = false;
        }
      }  
    });

    this.rowData2.forEach(data => {
      if(data.Percentage != ""){ 
        if (!this.ValidateMinimumInvestment(data.MinInvestment,Number(data.Percentage),data.InvestmentName))
        {
          passedMinInvestmentValidation = false;
        }
      }  
    });

    this.rowData3.forEach(data => {
      if(data.Percentage != ""){ 
        if (!this.ValidateMinimumInvestment(data.MinInvestment,Number(data.Percentage),data.InvestmentName))
        {
          passedMinInvestmentValidation = false;
        }
      }  
    });

    if (!passedMinInvestmentValidation)
    {
      return false;
    }
    return true
  }  


  initializeFormData(){
    this.advisorname = this.advisorsArray[0].id
    this.login = this.loggedAgent.FirstName + " " + this.loggedAgent.LastName
    this.companyname = this.loggedAgent.OrganizationName
    this.lastname = ""
    this.firstname = ""
    this.lastnameclient2 = ""
    this.firstnameclient2 = ""
    this.accountname = ""
    this.accountnumber = ""
    this.accountclassification = ""
    this.accounttypequalified = ""
    this.accounttypenonqualified = ""
    this.accounttypeentity = ""
    this.showClient2Details = false
    this.custodian = ""
    this.instructiontype = ""
    this.highyieldcorporatebond = ""
    this.strategicenhancedbond = ""
    this.logicinvestsleepwellbond =""
    this.strategichedgedincome = ""
    this.economiccycle = ""
    this.foundation = ""
    this.logicinvestglobalsector = ""
    this.logicinvestcountryrotation = ""
    this.primedividendinternational = ""
    this.strategicmidcap = ""
    this.usprimedividend = ""
    this.usprimedividendjr = ""
    this.employmenttrendsstrategy = ""
    this.NASDAQleaders = ""
    this.sectorrotation = ""  
    this.valuediscount = ""
    this.valueexposure =""
    this.reflationstrategy = ""
    this.accountsize =""
    this.pendingannuities = ""
    this.heldpositions = ""
    this.notes = ""
    this.file = ""
    this.totalPercentage = 0
    this.fileInfos = []
    this.rowData1.forEach(data => {
      data.Percentage = ""
    });
    this.rowData2.forEach(data => {
      data.Percentage = ""
    });
    this.rowData3.forEach(data => {
      data.Percentage = ""
    });
    this.gridOptions1.api.setRowData(this.rowData1);
    this.gridOptions2.api.setRowData(this.rowData2);
    this.gridOptions3.api.setRowData(this.rowData3);
    this.pinnedBottomRowData = createData(1, "Bottom", this.totalPercentage + "%");
    if(this.gridApi3 != undefined){
      this.gridApi3.setPinnedBottomRowData(this.pinnedBottomRowData);
    }
  }

  assignStratergies(){
    this.clearalert = true

    this.gridOptions1.api.stopEditing();
    this.gridOptions2.api.stopEditing();
    this.gridOptions3.api.stopEditing();

    this.gridOptions1.api.setRowData(this.rowData1);
    this.gridOptions2.api.setRowData(this.rowData2);
    this.gridOptions3.api.setRowData(this.rowData3);

    this.totalPercentage = 0

    this.rowData1.forEach(data => {
      if(data.Percentage != ""){
        this.totalPercentage = this.totalPercentage + Number(data.Percentage)
      } 
      switch (data.InvestmentName) {
        case "High Yield Corporate Bond":
          this.highyieldcorporatebond = data.Percentage
          break;
        case "Strategic Enhanced Bond": 
          this.strategicenhancedbond = data.Percentage
          break;
        case "Sleep Well Bond":
          this.logicinvestsleepwellbond = data.Percentage
          break;
        case "Strategic Hedged Income":
          this.strategichedgedincome = data.Percentage
          break;
         default:
      } 
    });
    this.rowData2.forEach(data => {
      if(data.Percentage != ""){
        this.totalPercentage = this.totalPercentage + Number(data.Percentage)
      } 
      switch (data.InvestmentName) {
        case "Economic Cycle":
          this.economiccycle = data.Percentage
          break;
        case "Foundation":
          this.foundation = data.Percentage
          break;
        case "Global Sector":
          this.logicinvestglobalsector = data.Percentage
          break;
        case "Country Rotation":
          this.logicinvestcountryrotation = data.Percentage
          break;
        case "Prime Dividend International":
          this.primedividendinternational = data.Percentage
          break;
        case "Strategic Mid Cap":
          this.strategicmidcap = data.Percentage
          break;
        case "U. S. Prime Dividend":
          this.usprimedividend = data.Percentage
          break;
        case "U. S. Prime Dividend Jr":
          this.usprimedividendjr= data.Percentage
          break;
        case "Employment Trends Strategy":
          this.employmenttrendsstrategy= data.Percentage
          break;   
        default:
      }
    });
    this.rowData3.forEach(data => {
      if(data.Percentage != ""){
        this.totalPercentage = this.totalPercentage + Number(data.Percentage)
      } 
      switch (data.InvestmentName) {
        case "NASDAQ Leaders":
          this.NASDAQleaders = data.Percentage
          break;
        case "Sector Growth":
          this.sectorrotation = data.Percentage
          break;
        case "Value Discount":
          this.valuediscount = data.Percentage
          break;
        case "Broad Value":
          this.valueexposure = data.Percentage
          break;
        case "Reflation Strategy":
          this.reflationstrategy = data.Percentage
          break;  
        default:
      }
    });
    this.clearalert = false
    //return true
  }


  saveMondayAllocationDetails(){
    this.alertService.clear()
    this.assignStratergies()
      
      if (this.validateMondayAllocationForm()){
          this.disablesubmitbtn = true
          this.alertService.info('Allocation request is submitting. Please wait.')
        
        if (this.accountsize == undefined || this.accountsize == null )
        { 
          this.accountsize = "";
        }  
    
        if (this.pendingannuities == undefined || this.pendingannuities == null )
        { 
          this.pendingannuities = "";
        }  

        this.mondayallocation = new MondayAllocation()
        this.mondayallocation.AdvisorName = this.advisorname
        this.mondayallocation.CompanyName = this.companyname
        this.mondayallocation.Login = this.login
        this.mondayallocation.Email = this.loggedAgent.Email
        this.mondayallocation.AccountName = this.accountname
        this.mondayallocation.AccountNumber = this.accountnumber
        this.mondayallocation.AccountClassification = this.accountclassification
        let accounttypequalified = (this.accounttypequalified == "" || this.accounttypequalified == null || this.accounttypequalified == undefined) 
        let accounttypenonqualified = (this.accounttypenonqualified == "" || this.accounttypenonqualified == null || this.accounttypenonqualified == undefined)
        let accounttypeentity = (this.accounttypeentity == "" || this.accounttypeentity == null || this.accounttypeentity == undefined) 
        
        if(accounttypequalified && accounttypeentity && this.accounttypenonqualified != "" && this.accounttypenonqualified != null && this.accounttypenonqualified != undefined){
          this.mondayallocation.AccountType = this.accounttypenonqualified
        } else if(accounttypenonqualified && accounttypeentity && this.accounttypequalified != "" && this.accounttypequalified != null && this.accounttypequalified != undefined){
          this.mondayallocation.AccountType = this.accounttypequalified
        } else if(accounttypequalified && accounttypenonqualified && this.accounttypeentity != "" && this.accounttypeentity != null && this.accounttypeentity != undefined){
          this.mondayallocation.AccountType = this.accounttypeentity
        }
        this.mondayallocation.Custodian = this.custodian
        this.mondayallocation.InstructionType = this.instructiontype
        this.mondayallocation.HighYieldCorporateBond = this.highyieldcorporatebond
        this.mondayallocation.StrategicEnhancedBond = this.strategicenhancedbond
        this.mondayallocation.LogicInvestSleepWellBond = this.logicinvestsleepwellbond
        this.mondayallocation.StrategicHedgedIncome = this.strategichedgedincome
        this.mondayallocation.EconomicCycle = this.economiccycle
        this.mondayallocation.Foundation = this.foundation
        this.mondayallocation.LogicInvestGlobalSector = this.logicinvestglobalsector
        this.mondayallocation.LogicInvestCountryRotation = this.logicinvestcountryrotation
        this.mondayallocation.PrimeDividendInternational = this.primedividendinternational
        this.mondayallocation.StrategicMidCap = this.strategicmidcap
        this.mondayallocation.USPrimeDividend = this.usprimedividend
        this.mondayallocation.USPrimeDividendJr = this.usprimedividendjr
        this.mondayallocation.EmploymentTrendsStrategy = this.employmenttrendsstrategy
        this.mondayallocation.NASDAQLeaders = this.NASDAQleaders
        this.mondayallocation.SectorRotation = this.sectorrotation
        this.mondayallocation.ValueDiscount = this.valuediscount
        this.mondayallocation.ValueExposure = this.valueexposure
        this.mondayallocation.ReflationStrategy = this.reflationstrategy
        this.mondayallocation.AccountSize = this.accountsize.toString().replace(/,/g,'')
        this.mondayallocation.PendingAnnuities = this.pendingannuities.toString().replace(/,/g,'')
        this.mondayallocation.HeldPositions = this.heldpositions
        this.mondayallocation.Notes = this.notes
  
        const allocationdata: FormData = new FormData();
        this.fileInfos.forEach(data => {
          allocationdata.append("fileUploads", data);
        }); 
        allocationdata.append("mondayallocation", JSON.stringify(this.mondayallocation));

        this.mondayallocationrequestdataservice.mondayAllocationRequestData(allocationdata, this.loggedAgent).subscribe(date =>
        {
          this.alertService.clear()
          this.alertService.success('Successfully saved')
          this.disablesubmitbtn = false
          this.initializeFormData()
        },
        error => { 
          this.alertService.clear()
          if (error.error.toLowerCase().includes("an asynchronous module or handler completed while an asynchronous operation was still pending")) // To handle An asynchronous module or handler completed while an asynchronous operation was still pending.
          {
            this.alertService.success('Successfully saved')
            this.disablesubmitbtn = false
            this.initializeFormData()
          } else{
            this.alertService.error('Error occured while saving data')
            this.disablesubmitbtn = false
          }
          
        });

      }
    
  }

  handleFileInput(event: any) {
	  if(event.target.files != null && event.target.files != undefined){
      var i
      for(i=0; i < event.target.files.length; i++){
        this.fileInfos.push(event.target.files[i])
      }     
    }  
  }


  removeFile(file){
    this.fileInfos.forEach(data => {
      if(file == data){
        this.fileInfos.splice(this.fileInfos.indexOf(data),1)
      }
    }); 
  }

  FilterInput(event){ 
    // skip for arrow keys
    if(event.which >= 37 && event.which <= 40) return;
  
    switch (event.currentTarget.id) {
      case "pendingannuities":
        this.pendingannuities = event.target.value
                  .replace(/\D/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")  
        break;
      case "accountsize":
        this.accountsize = event.target.value
                        .replace(/\D/g, "")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")  
        break;
      default:
    }  
  }

  /* FilterInput(event){
    if(event.key != "Backspace" && event.key != "Tab" && event.key != "Enter"){
      this.alertService.clear();
      if(/^\d+$/.test(event.key)){
        this.alertService.clear();
      } else{
        this.alertService.error('Please enter a number');

      switch (event.currentTarget.id) {
        case "highyieldcorporatebond":
          this.highyieldcorporatebond = ""
          break;
        case "strategicenhancedbond": 
          this.strategicenhancedbond = ""
          break;
        case "logicinvestsleepwellbond":
          this.logicinvestsleepwellbond = ""
          break;
        case "strategichedgedincome":
          this.strategichedgedincome = ""
          break;
        case "economiccycle":
          this.economiccycle = ""
          break;
        case "foundation":
          this.foundation = ""
          break;
        case "logicinvestglobalsector":
          this.logicinvestglobalsector = ""
          break;
        case "logicinvestcountryrotation":
          this.logicinvestcountryrotation = ""
          break;
        case "primedividendinternational":
          this.primedividendinternational = ""
          break;
        case "strategicmidcap":
          this.strategicmidcap = ""
          break;
        case "usprimedividend":
          this.usprimedividend = ""
          break;
        case "NASDAQleaders":
          this.NASDAQleaders = ""
          break;
        case "sectorrotation":
          this.sectorrotation = ""
          break;
        case "valuediscount":
          this.valuediscount = ""
          break;
        case "pendingannuities":
          this.pendingannuities = ""
          break;
        case "accountsize":
          this.accountsize = ""
          break;
        default:
      }
    }
    } else{
      this.alertService.clear();
    }
  } */

  clearAlert(){
    this.alertService.clear();
  }

  resetAccountType(event){
    if(event.currentTarget.value == "---"){
      this.accounttypequalified = ""
      this.accounttypenonqualified = ""
      this.accounttypeentity = ""
    }else{
      this.accounttype = event.currentTarget.value
    }
    this.GenerateAccountName();
  }

  resetAccountClassification(event){
    if(event.currentTarget.value == "---"){
      this.accountclassification = ""
      this.accounttypequalified = ""
      this.accounttypenonqualified = ""
      this.accounttypeentity = ""
      this.showAccountTypeQualified = true
      this.showAccountTypeNonQualified = true
      this.showAccountTypeEntity = true
    } else if(event.currentTarget.value == "Qualified"){
      this.showAccountTypeQualified = false
      this.showAccountTypeNonQualified = true
      this.showAccountTypeEntity = true
    } else if(event.currentTarget.value == "Non-Qualified"){
      this.showAccountTypeQualified = true
      this.showAccountTypeNonQualified = false
      this.showAccountTypeEntity = true
    } else{
      this.showAccountTypeQualified = true
      this.showAccountTypeNonQualified = true
      this.showAccountTypeEntity = false
    }
  }

  GenerateAccountName()
  {
    let accounttype
    let accounttypequalified = (this.accounttypequalified == "" || this.accounttypequalified == null || this.accounttypequalified == undefined) 
    let accounttypenonqualified = (this.accounttypenonqualified == "" || this.accounttypenonqualified == null || this.accounttypenonqualified == undefined)
    let accounttypeentity = (this.accounttypeentity == "" || this.accounttypeentity == null || this.accounttypeentity == undefined) 
    
    if(accounttypequalified && accounttypeentity && this.accounttypenonqualified != "" && this.accounttypenonqualified != null && this.accounttypenonqualified != undefined){
      accounttype = this.accounttypenonqualified
    } else if(accounttypenonqualified && accounttypeentity && this.accounttypequalified != "" && this.accounttypequalified != null && this.accounttypequalified != undefined){
      accounttype = this.accounttypequalified
    } else if(accounttypequalified && accounttypenonqualified && this.accounttypeentity != "" && this.accounttypeentity != null && this.accounttypeentity != undefined){
      accounttype = this.accounttypeentity
    }
     
    this.accountname="";
    if ((this.lastname!="") && (this.lastname != null) && (this.lastname != undefined))
    {
      this.accountname = this.lastname  
    }
    if ((this.firstname!="") && (this.firstname != null) && (this.firstname != undefined))
    {
      this.accountname = this.accountname + (this.accountname=="" ? this.firstname  :" " + this.firstname)  
    }
    if ((this.lastnameclient2 !="") && (this.lastnameclient2 != null) && (this.lastnameclient2 != undefined) && (this.lastname != this.lastnameclient2))
    {
      this.accountname = this.accountname + (this.accountname=="" ? this.lastnameclient2  :" & " + this.lastnameclient2)
    }
    if ((this.firstnameclient2 !="") && (this.firstnameclient2 != null) && (this.firstnameclient2 != undefined))
    {
      if (this.lastname != this.lastnameclient2)
      {
        this.accountname = this.accountname + (this.accountname=="" ? this.firstnameclient2  :" " + this.firstnameclient2)
      } else{
        this.accountname = this.accountname + (this.accountname=="" ? this.firstnameclient2  :" & " + this.firstnameclient2)
      }
    }
    if ((accounttype!="") && (accounttype != null) && (accounttype != undefined))
    {
      this.accountname = this.accountname + " " + accounttype  
    }

     
  }

  resetCustodian(event){
    if(event.currentTarget.value == "---"){
      this.custodian = ""
    }
  }

  
  resetInstructionType(event){
    if(event.currentTarget.value == "---"){
      this.instructiontype = ""
    }
  }

  exitBtnClickEvent()
  {
    if(JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) != null){
      this.sharedService.changeSideBarRequest()   
			this.router.navigateByUrl('home')
    } else{
      this.router.navigateByUrl('landing'); 
    }
    
  }
  ValidateMinInvestmentOnFocusOut()
  {
   //Minimum investment validation
   this.alertService.clear()
   let passedMinInvestmentValidation: boolean = true;
   this.rowData1.forEach(data => {
     if(data.Percentage != ""){ 
       if (!this.ValidateMinimumInvestment(data.MinInvestment,Number(data.Percentage),data.InvestmentName))
       {
         passedMinInvestmentValidation = false;
       }
     }  
   });

   this.rowData2.forEach(data => {
     if(data.Percentage != ""){ 
       if (!this.ValidateMinimumInvestment(data.MinInvestment,Number(data.Percentage),data.InvestmentName))
       {
         passedMinInvestmentValidation = false;
       }
     }  
   });

   this.rowData3.forEach(data => {
     if(data.Percentage != ""){ 
       if (!this.ValidateMinimumInvestment(data.MinInvestment,Number(data.Percentage),data.InvestmentName))
       {
         passedMinInvestmentValidation = false;
       }
     }  
   });
  }

  addClient2(){
    this.showClient2Details = true
    this.GenerateAccountName()
  }

  removeClient2(){
    this.firstnameclient2 = ""
    this.lastnameclient2 = ""
    this.showClient2Details = false
    this.GenerateAccountName()
  }
}



function createData(count, prefix, totalPercentatge) {
  var result = [];
  for (var i = 0; i < count; i++) {
    result.push({
        InvestmentName: "Total Investment: ",
        Percentage:  totalPercentatge
    });
  }
  return result;
}