export class RiskGraphRequest {
    constructor(
        public Tickers?: string,
        public StartDate?: Date,
        public EndDate?: Date,
        public DrawdownCount?: number,
        public AgentID?: string ,        
        public CustomDrawdowns? :   Array<string> , 
        public ReportName?: string,
        public bIncludeAutoGenerated?: boolean ,
        public ClientID?: string
    ) {}
}

 