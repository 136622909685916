import { Injectable } from '@angular/core';
import { Constants } from '../util/constants';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginCredential } from '../models/login-credential.model';
import { map } from 'rxjs/operators' 
import { Agent } from '../models/agent.model';
import { BusinessCalculatorInput } from '../models/business-calculator-input.model';

@Injectable({
	providedIn: 'root'
})
export class LoginDataService {

	constructor(private http: HttpClient ) { }

	/**
	 * this is the sample method to get data from server
	 * hardcoded URL need to put 
	 */
	authenticate(loginCredential: LoginCredential): Observable<any> {
 
		const httpOptions = {
			 headers: new HttpHeaders({
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			 })
        } 
 
		return this.http.post<any>(environment.server_URL + '/authenticate', JSON.stringify(loginCredential),httpOptions)
			 .pipe()



	}

	resetpassword(loginCredential: LoginCredential): Observable<any> {
 
		const httpOptions = {
			 headers: new HttpHeaders({
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			 })
        }  
		return this.http.post<any>(environment.server_URL + '/resetpassword', JSON.stringify(loginCredential),httpOptions)
			 .pipe()

	}

	acceptagreement( loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/acceptagreement',JSON.stringify(loggedAgent) , httpOptions).pipe()
	}

	getserviceversion(): Observable<any> {
 
		const httpOptions = {
			 headers: new HttpHeaders({
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			 })
        } 
		return this.http.post<any>(environment.server_URL + '/getserviceversion',null , httpOptions).pipe()
	}

	trialusertrainingcompleted( loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/trialusertrainingcompleted',JSON.stringify(loggedAgent) , httpOptions).pipe()
	}
	
}
