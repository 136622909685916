export class IncomeSourceAccount {
    constructor(
        public IncomeSourceID?: string,
        public ChoiceID?: string,
        public Owner?: string,
        public Source?: string,
        public StartYear?: string,
        public EndYear?: string,
        public COLAFlag?: boolean,
        public COLAPct?: Number,
        public SurvivorBenefitFlag?: boolean,
        public SurvivorBenefitPct?: Number,
        public AnnualAmount?: Number,
        public IncomeForLifeComment?: string,
        public DisplayOrder?: Number,
        public StartDate?: string,
        public EndDate?: string
    ) { }
    
}