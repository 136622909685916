import { Component, OnInit , ViewChild, ElementRef, EventEmitter, ComponentFactoryResolver, ComponentRef, ViewContainerRef} from '@angular/core';
import { Router } from '@angular/router';
import { DynamicSidebarService } from '../../sidebar/dynamic-sidebar.service';
import { AlertService } from '../../util/alert/alert.service';
import { LocalStorage } from '../../util/localstorage.service';
import { SharedServiceService } from '../../util/shared-service.service';
import { Constants } from '../../util/constants.js';
import { Agent } from '../../models/agent.model';
import { FP4FADataService } from '../fp4fa-data.service';
import { BusinessCalculatorInput } from 'src/app/models/business-calculator-input.model';
import { BusinessCalculatorOutput } from 'src/app/models/business-calculator-output.model';
import { AppCompLoaderService } from 'src/app/appComp/app-comp-loader.service';
import { BusinessCalculatorDefaults } from 'src/app/models/business-calculator-defaults.model';


@Component({
  selector: 'app-landingfp4fa',
  templateUrl: './landingfp4fa.component.html',
  styleUrls: ['./landingfp4fa.component.scss']
})
export class Landingfp4faComponent implements OnInit { 

	public loggedAgent : Agent 
	public paddingtop: number = 60;
	public bDeleteClicked : boolean = false
	public isShowFP4FA: boolean = false
	public componentNames: any = Constants.COMP_NAME
	component: any;
    @ViewChild('longHost', { read: ViewContainerRef }) componentHost: ViewContainerRef
	componentRef: ComponentRef<Component>

	public businessPlansArray: Array<any> = []
	public searchTerm: string = ''
	private businesscalculatorinput: BusinessCalculatorInput = new BusinessCalculatorInput()
	private businesscalculatoroutput: BusinessCalculatorOutput = new BusinessCalculatorOutput()
	public businessplandeleted: boolean = false;

	constructor(private router: Router, 
					 private alertService: AlertService, 
					  private dynSidebarService: DynamicSidebarService,
					  private sharedService: SharedServiceService,
					  private FP4FAdataService: FP4FADataService,
					  private aclService: AppCompLoaderService,
					  private resolver: ComponentFactoryResolver) {


		//console.log(localStorage.getItem(LocalStorage.LOGGED_AGENT))
		this.loggedAgent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent;
		// if ((this.loggedAgent != null) && (this.loggedAgent != undefined)) { 
		// 	// check whther the user is RIA Only 
		// 	if (this.loggedAgent.UserRoleID== Constants.UserRoleID.RIAOnly){
		// 		this.router.navigateByUrl('MondayAllocationRequest'); 
		// 	}
		
		
		// 	// check whther the user allowed to see FP4FA
		// 	if (this.loggedAgent.bShowBizIQ == true)
		// 		this.isShowFP4FA = true
		// 	else
		// 		this.isShowFP4FA = false   
		// }

		//this.ldService.getclientsbyagentorganization(this.loggedAgent).subscribe(data => this.getData(data))
		//get all business plans
    	this.getAllBusinessPlans()
	}

	ngOnInit() {
	}

	//get all business plan and create an array
	getAllBusinessPlans(){
		this.alertService.clear()
		this.FP4FAdataService.getallbusinessplansbyagentid(this.loggedAgent).subscribe(data => {
		  this.businessPlansArray = []
		  if(data != null && data != undefined){
			this.businessPlansArray = data
		  }
		  return this.businessPlansArray   	
		},
		error => { 
		  this.alertService.error('An error occurred while getting FP4FA data')
		});
	  }


	//redirect to the dashboard with the selected business plan
	public sampleClickEvent(businessPlan: BusinessCalculatorInput) {  
		if(this.businessplandeleted){
			this.businessplandeleted = false
		}else if(businessPlan != undefined && businessPlan != ""){
			this.FP4FAdataService.getbusinessplanbyid(businessPlan.BusinessPlanID, this.loggedAgent).subscribe(data => {
			  this.businesscalculatorinput  = data 
			  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
			  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
			  this.FP4FAdataService.generatebusinesscalculator(this.loggedAgent , this.businesscalculatorinput).subscribe(data => {
				this.businesscalculatoroutput = new BusinessCalculatorOutput  
				this.businesscalculatoroutput  = data 	 
				localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_OUTPUT, JSON.stringify(this.businesscalculatoroutput));
				localStorage.setItem(LocalStorage.COMPANY_CONFIG, "0");
				this.loadFP4FA()  
			  },
			  error => { 
				this.alertService.error('An error occurred while getting FP4FA data')
			  });   
			  
			},
			error => { 
			  this.alertService.error('An error occurred while getting FP4FA data')
			});
		  }
	}

	//delete selected business plan
	public deleteClickEvent(event:any, businessPlan: BusinessCalculatorInput)
	{   
		this.alertService.clear()
		this.businessplandeleted = true
		if (confirm("Are you sure you want to delete selected Business Plan?")) { 
			this.businesscalculatorinput = new BusinessCalculatorInput()
			this.FP4FAdataService.getbusinessplanbyid(businessPlan.BusinessPlanID, this.loggedAgent).subscribe(data => {
				this.businesscalculatorinput  = data 
				this.FP4FAdataService.generatebusinesscalculator(this.loggedAgent , this.businesscalculatorinput).subscribe(data => {
					this.businesscalculatoroutput = new BusinessCalculatorOutput  
					this.businesscalculatoroutput  = data 	 
					this.FP4FAdataService.deletebusinessplanbyid(this.loggedAgent, this.businesscalculatorinput).subscribe(data => {
						localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
						//get all business plans
						this.getAllBusinessPlans()
						this.alertService.success('Deleted the Business Plan Successfully')
					},
					error => { 
						this.alertService.error('An error occurred while getting FP4FA data')
					});
				},
				error => { 
					this.alertService.error('An error occurred while getting FP4FA data')
				});   
				
			},
			error => { 
				this.alertService.error('An error occurred while getting FP4FA data')
			});
		}
	}

	//create new business plan
	public createNewBusinessPlan(){
		this.getBusinessCalculatorInputWithDefaults(this.loggedAgent) 
	}

	//get business calcultaor defaults
	public getBusinessCalculatorInputWithDefaults(agent)
	{
		this.businesscalculatorinput = new BusinessCalculatorInput()
		this.FP4FAdataService.getbusinesscalculatorinputwithdefaults(agent, this.businesscalculatorinput).subscribe(data => {
			this.businesscalculatorinput  = data 
			this.businesscalculatorinput.BusinessPlanName == ""
			this.businesscalculatorinput.OrganizationID = agent.OrganizationID
			this.businesscalculatorinput.OwnerID = agent.AgentID
			
			localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
			localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
			this.FP4FAdataService.generatebusinesscalculator(agent , this.businesscalculatorinput).subscribe(data => {
				let businesscalculatoroutput: BusinessCalculatorOutput  
				businesscalculatoroutput  = data 	 
				localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_OUTPUT, JSON.stringify(businesscalculatoroutput)); 
				
				//get business calculator defaults 
				this.FP4FAdataService.getbusinesscalculatordefaults(agent).subscribe(data => {
					let businesscalculatordefaults: BusinessCalculatorDefaults  
					businesscalculatordefaults  = data 	 
					localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_DEFAULTS, JSON.stringify(businesscalculatordefaults)); 
					this.router.navigateByUrl('fp4fa');
				},
				error => { 
					this.alertService.error('An error occurred while getting FP4FA data')
				});   

				this.FP4FAdataService.getemployeetypes(agent).subscribe(employeetypes =>
				{
					localStorage.setItem(LocalStorage.EMPLOYEE_TYPES, JSON.stringify(employeetypes)); 
				},
				error => { 
					this.alertService.clear()
					this.alertService.error('Error occured')       
				});
			},
			error => { 
				this.alertService.error('An error occurred while getting FP4FA data')
			});   
			
		},
		error => { 
			this.alertService.error('An error occurred while getting FP4FA data')
		});
	}


	//load the dashboard page
	public loadFP4FA()
	{
		this.router.navigateByUrl('fp4fa'); 
	}

	//redirect to retirement plans
	public loadRetirementPlans()
	{
		localStorage.setItem(LocalStorage.FP4FA, ""); 
		this.router.navigateByUrl('landing'); 
	}

}

