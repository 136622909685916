export class BusinessCalculatorOutput {
    constructor( 
        public MaxDrawDown?: string,
        public LowPositiveCashBalance?: string,
        public MaxDrawDownMonth?: string,
        public LowPositiveCashBalanceMonth?: string,
        public YearData?: Array<DataPerYear>
    ) {}
}

export class DataPerYear {
    constructor( 
        public Year?: string,
        public StartingCash?: Number,
        public Income?: Number,
        public ExistingAUM?: Number,
        public Advertising?: Number,
        public Employees?: Number,
        public AdvisorPay?: Number,
        public PayrollTaxes?: Number,
        public COLAIncrease?: Number,
        public StaffPerformanceBonusPool?: Number,
        public InsuranceBenefits?: Number,
        public RetirementBenefits?: Number,
        public Rent?: Number,
        public EAndOInsurance?: Number,
        public BusinessLiabilityInsurance?: Number,
        public WorkersCompensationInsurance?: Number,
        public OtherInsuranceCosts?: Number,
        public OtherExpenses?: Number,
        public TotalExpenses?: Number,
        public NetIncome?: Number,
        public GrossOwnersTake?: Number,
        public OwnersFederalIncomeTax?: Number,
        public OwnersStateIncomeTax?: Number,
        public NetOwnersTake?: Number,
        public NetNetOwnersTake?: Number,
        public EndingCash?: Number
    ) {}
}