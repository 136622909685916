import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginDataService } from './login-data.service';
//import { AlertService } from '../util/alert/alert.service';
import { LocalStorage } from '../util/localstorage.service';
import { Agent } from '../models/agent.model';
import { LoginCredential } from '../models/login-credential.model';
import { AlertService } from '../util/alert/alert.service';
import { version } from '../../../package.json';
import { Constants } from '../util/constants.js';
import { BusinessCalculatorInput } from '../models/business-calculator-input.model';
import { BusinessCalculatorOutput } from '../models/business-calculator-output.model';
import { BusinessCalculatorDefaults } from '../models/business-calculator-defaults.model';
import { FP4FADataService } from '../fp4fa/fp4fa-data.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	public appVersion: string = version

	@ViewChild('login_username') userNameInput: ElementRef;
	@ViewChild('login_password') passwordInput: ElementRef;

	public showPassword: boolean = false;
	public loginCredential: LoginCredential;
	public showAgreement: boolean = false;
	public showTrialUserTraining: boolean = false;
	private agent: Agent = new Agent()
	private businesscalculatorinput: BusinessCalculatorInput = new BusinessCalculatorInput()

	constructor(private router: Router, private ldService: LoginDataService, private alertService: AlertService,
		private FP4FAdataService: FP4FADataService) {
		//constructor(private router: Router,private ldService: LoginDataService,private alertService: AlertService) {

	}

	ngOnInit() {
		// Initializing the auth request.
		this.loginCredential = new LoginCredential();

	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.loginCredential.Email = this.userNameInput.nativeElement.value;
			this.loginCredential.Password = this.passwordInput.nativeElement.value;
		}, 1000);
	}

	public forgotPasswordEvent() {
		let randNum: number = Math.floor(Math.random() * 4) + 1
		this.alertService.clear()
		switch (randNum) {
			case 1:
				this.alertService.success('Sample SUCCESS alert message')
				break;
			case 2:
				this.alertService.error('Sample ERROR alert message')
				break;
			case 3:
				this.alertService.info('Sample INFO alert message')
				break;
			case 4:
				this.alertService.warn('Sample WARN alert message')
				break;
			default:
				break;
		}
	}

	public resetPasswordEvent() {
		this.alertService.clear()
		if (confirm("Are you sure you want to reset password?")) { 
			if (this.userNameInput.nativeElement.value=="")
			{				
				this.alertService.error('Invalid username')
			}
			else
			{
				let temploginCredential: LoginCredential = new LoginCredential();
				temploginCredential.Email = this.userNameInput.nativeElement.value; 
			
				this.ldService.resetpassword(temploginCredential).subscribe(data => {
					this.alertService.info('New password has been sent to your email.')
				},
					error => {   
						if (error.status == 400)  // email not registered on the system
						{
							this.alertService.error('Email not registered in system.')
						}
						else if (error.status == 0) // To handle An asynchronous module or handler completed while an asynchronous operation was still pending.
						{
							this.alertService.info('New password has been sent to your email.')
						}
						else // other errors... like internal server errors
						{
							this.alertService.error('Error Occured.')
						}
						//this.alertService.error('Email not registered in system.')
					});
			}
			
		}
	}

	public loginBtnClickEvent() {
		// this.ldService.authenticate().subscribe(data => this.getData(data)) ; 
		localStorage.clear();
		this.alertService.clear()
		this.ldService.getserviceversion().subscribe(version => {
			localStorage.setItem(LocalStorage.SERVICE_VERSION, JSON.stringify(version));
		},
		error => { 
			this.alertService.error('Invalid username or password')
		});
		setTimeout(()=> {
			this.ldService.authenticate(this.loginCredential).subscribe(data => {

				
				this.agent  = data  


				if (this.agent.AgreedToClickwrap==true){

					// Display training video to trail users if they have not followed training 
					if (this.agent.bTrialUser == true && this.agent.bTrainingCompleted== false)
					{
						this.showTrialUserTraining = true 
					}
					else
					{ 
						this.LogIn()
						/* localStorage.setItem(LocalStorage.LOGGED_AGENT, JSON.stringify(this.agent));
						//Gets FP4FA data if the user can have access to FP4FA
						if (this.agent.bShowBizIQ ==true)
						{ 
							this.getBusinessCalculatorInputWithDefaults(this.agent);  
						}				
						else 
						{
							// check whther the user is RIA Only 
							if (this.agent.UserRoleID== Constants.UserRoleID.RIAOnly)
								this.router.navigateByUrl('MondayAllocationRequest'); 
							else
								this.router.navigateByUrl('landing'); 
						}	  */
					}
				} 
				else 
				{
					this.showAgreement = true 
				}
			},
			error => { 				
				if (error.status==406)
				{
					this.alertService.warn('Your trial period has ended.')
				}
				else
				{
					this.alertService.error('Invalid username or password')
				}
				
			});
		}, 50); 
		//	this.router.navigateByUrl('landing');
	}

	public LogIn()
	{
		localStorage.setItem(LocalStorage.LOGGED_AGENT, JSON.stringify(this.agent));
		//Gets FP4FA data if the user can have access to FP4FA
		if (this.agent.bShowBizIQ ==true)
		{ 
			this.getBusinessCalculatorInputWithDefaults(this.agent);  
		}				
		else 
		{
			// check whther the user is RIA Only 
			if (this.agent.UserRoleID== Constants.UserRoleID.RIAOnly)
				this.router.navigateByUrl('MondayAllocationRequest'); 
			else
				this.router.navigateByUrl('landing'); 
		}	  
	}

	public CloseTrainingBtnClickEvent()
	{
		 this.ldService.trialusertrainingcompleted(this.agent).subscribe(data => {
			this.LogIn()
			//localStorage.setItem(LocalStorage.LOGGED_AGENT, JSON.stringify(this.agent));
			//this.router.navigateByUrl('landing');   
		},
			error => {
				this.alertService.clear()
				this.alertService.error('Error occured while saving data.')
			});  

	}

	public acceptBtnClickEvent()
	{
		this.ldService.acceptagreement(this.agent).subscribe(data => {
			if (this.agent.bTrialUser == true && this.agent.bTrainingCompleted== false)
			{
				this.showTrialUserTraining = true 
			}
			else
			{ 
				this.LogIn()
			}
			/* localStorage.setItem(LocalStorage.LOGGED_AGENT, JSON.stringify(this.agent));
			this.router.navigateByUrl('landing');   */
		},
			error => {
				this.alertService.clear()
				this.alertService.error('Error occured while saving data.')
			});

	}

	public rejectBtnClickEvent()
	{
		this.showAgreement = false 
		localStorage.clear();
	}

	private getData(data: any) {

		//	console.log(data);
		//let modified = JSON.parse(JSON.stringify(data));
		let agent: Agent = data
		//agent.FirstName = modified.FirstName  
		console.log(agent);
		/*data.forEach((element: any) => {
			let agent: Agent = element
			agent.FirstName = element.FirstName  
			console.log(agent.FirstName);
		});*/
		//console.log("333333333333333333333");
		//console.log(data);
	}

	public getBusinessCalculatorInputWithDefaults(agent)
	{
		this.FP4FAdataService.getbusinesscalculatorinputwithdefaults(agent, this.businesscalculatorinput).subscribe(data => {
			this.businesscalculatorinput  = data 
			this.businesscalculatorinput.OrganizationID = agent.OrganizationID
			this.businesscalculatorinput.OwnerID = agent.AgentID
			
			localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));
			localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
			this.FP4FAdataService.generatebusinesscalculator(agent , this.businesscalculatorinput).subscribe(data => {
				let businesscalculatoroutput: BusinessCalculatorOutput  
				businesscalculatoroutput  = data 	 
				localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_OUTPUT, JSON.stringify(businesscalculatoroutput)); 
				
				//get business calculator defaults 
				this.FP4FAdataService.getbusinesscalculatordefaults(agent).subscribe(data => {
					let businesscalculatordefaults: BusinessCalculatorDefaults  
					businesscalculatordefaults  = data 	 
					localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_DEFAULTS, JSON.stringify(businesscalculatordefaults)); 
					this.router.navigateByUrl('landing'); 
				},
				error => { 
					this.alertService.error('An error occurred while getting FP4FA data')
				});   

				this.FP4FAdataService.getemployeetypes(agent).subscribe(employeetypes =>
				{
					localStorage.setItem(LocalStorage.EMPLOYEE_TYPES, JSON.stringify(employeetypes)); 
				},
				error => { 
					this.alertService.clear()
					this.alertService.error('Error occured')       
				});
			},
			error => { 
				this.alertService.error('An error occurred while getting FP4FA data')
			});   
			
		},
		error => { 
			this.alertService.error('An error occurred while getting FP4FA data')
		});
	}
}
