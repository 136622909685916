import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../util/localstorage.service'; 
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Agent } from '../models/agent.model';
import { AlertService } from '../util/alert/alert.service';
import { BsModalService,BsModalRef}   from 'ngx-bootstrap/modal';
import { SharedServiceService } from '../util/shared-service.service';
import { MondayDepositWithdrawCashDataService } from './mondaydepositwithdrawcash-data.service';
import { MondayDepositWithrawalCash } from '../models/monday-deposit-withdraw-cash.model';
import { GridOptions } from 'ag-grid-community'
import { CustomHeader} from '../util//customHeader'
  
@Component({
  selector: 'app-mondaydepositwithdrawcash',
  templateUrl: './mondaydepositwithdrawcash.component.html',
  styleUrls: ['./mondaydepositwithdrawcash.component.scss']
})

export class MondayDepositWithdrawCashComponent implements OnInit {
  
  public paddingtop: number = 60;
  public selectedChoiceID: string = ""; 
  public loggedAgent: Agent 
  public iSForm: FormGroup;
  public uploadforms: FormData;
  public fileInfos: Array<any> = [];
  public file: string;
  public mondaydepositwithdrwalcashmodel: MondayDepositWithrawalCash

  public name:any
  public advisorsArray: Array<any>
  public advisorname: any
  public companyname:any
  public login:any
  public accountname: any
  public accountnumber: any
  public action: any
  public type: any 
  public amount: any 
  //public paymentday: any
  public depositpaymentday: any
  public withdrawalpaymentday: any
  public notes: any
  public showpaymentday: boolean = false
  public isDeposit: boolean = false
  public disablesubmitbtn: boolean = false

  getRowStyle: (params: any) => { "font-weight": string; };
  pinnedBottomRowData: any;
  frameworkComponents: { customPinnedRowRenderer: any; };

  constructor( private router: Router,
    private formBuilder: FormBuilder, private alertService: AlertService,
    private bsModalService :BsModalService, private mondaydepositwithdrawcashdataservice: MondayDepositWithdrawCashDataService,
    private sharedService: SharedServiceService) {

      
    } 
    

  ngOnInit() {

   this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
   this.login = this.loggedAgent.FirstName + " " + this.loggedAgent.LastName
   this.companyname = this.loggedAgent.OrganizationName
   this.iSForm = this.formBuilder.group({
      name: new FormControl(null) ,
      advisorname: new FormControl(null) ,
      companyname: new FormControl(null), 
      login: new FormControl(null),
      accountname: new FormControl(null) ,
      accountnumber: new FormControl(null) ,
      action: new FormControl(null) ,
      type: new FormControl(null) , 
      amount : new FormControl(null),
      depositpaymentday: new FormControl(null),
      withdrawalpaymentday: new FormControl(null),
      notes: new FormControl(null)
    } );   

    this.getAdvisors() 
  }

  
  
  

  getAdvisors(){
    this.advisorsArray = []
    this.mondaydepositwithdrawcashdataservice.getcompanyadvisors(this.loggedAgent.OrganizationID, this.loggedAgent).subscribe(data =>
    {
      this.alertService.clear()
      if(data != null && data != undefined){
        var i
        for(i=0; i < data.length; i++){
          let AdvisorName = data[i].FirstName + " " + data[i].LastName
          this.advisorsArray.push({id: AdvisorName, text: AdvisorName})
        } 
      }
      this.advisorname = this.advisorsArray[0].id
      return this.advisorsArray
      
    },
    error => { 
        this.alertService.clear()
        this.alertService.error('Error occured')       
    });
  } 

  validateMondayDepositWithdrawCashForm():boolean
  { 
    this.alertService.clear()
    if ((this.iSForm.controls.advisorname.value == ""  || 
      this.iSForm.controls.advisorname.value == null))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Advisor Name')
      return false
    } 

    if ((this.iSForm.controls.companyname.value == ""  || 
      this.iSForm.controls.companyname.value == null))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Company Name')
      return false
    }

    if ((this.iSForm.controls.login.value == ""  || 
      this.iSForm.controls.login.value == null))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Login')
      return false
    }

    if ((this.iSForm.controls.accountname.value == ""  || 
          this.iSForm.controls.accountname.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Account Name')
      return false
    }   
 
    if ((this.iSForm.controls.accountnumber.value == ""  || 
          this.iSForm.controls.accountnumber.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Account Number')
      return false
    } 


    if ((this.iSForm.controls.action.value == ""  || this.iSForm.controls.action.value == "---" ||
          this.iSForm.controls.action.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Action')
      return false
    } 
    
    if ((this.iSForm.controls.amount.value == ""  || this.iSForm.controls.amount.value == "---" ||
    this.iSForm.controls.amount.value == null ))
    { 
    this.alertService.clear()
    this.alertService.error('Invalid Amount')
    return false
    }  

    if ((this.iSForm.controls.type.value == ""  || this.iSForm.controls.type.value == "---" ||
          this.iSForm.controls.type.value == null ))
    { 
      this.alertService.clear()
      this.alertService.error('Invalid Type')
      return false
    }  

    if(this.showpaymentday){

      if(this.action == "Deposit")
      {
        if ((this.iSForm.controls.depositpaymentday.value == ""  || this.iSForm.controls.depositpaymentday.value == "---" ||
        this.iSForm.controls.depositpaymentday.value == null ))
        {
          this.alertService.clear()
          this.alertService.error('Invalid Payment Date')
          return false
        }
      }
      else
      {
        if ((this.iSForm.controls.withdrawalpaymentday.value == ""  || this.iSForm.controls.withdrawalpaymentday.value == "---" ||
        this.iSForm.controls.withdrawalpaymentday.value == null ))
        {
          this.alertService.clear()
          this.alertService.error('Invalid Payment Date')
          return false
        }
      } 
      
    }


   /*  if ((this.fileInfos == undefined  || this.fileInfos.length == 0 ||
      this.fileInfos == null ))
    { 
      this.alertService.clear()
      this.alertService.error('No File is Selected')
      return false
    }   */

    return true
  }  


  initializeFormData(){
    this.advisorname = this.advisorsArray[0].id
    this.login = this.loggedAgent.FirstName + " " + this.loggedAgent.LastName
    this.companyname = this.loggedAgent.OrganizationName
    this.accountname = ""
    this.accountnumber = ""
    this.action = ""
    this.type = "" 
    this.amount =""  
    this.depositpaymentday =""
    this.withdrawalpaymentday =""
    this.notes = ""
    this.showpaymentday = false
    this.isDeposit = false
  }

  saveMondayDepositWithdrawCashDetails(){
    if (this.validateMondayDepositWithdrawCashForm()){
      this.disablesubmitbtn = true
      this.alertService.info('Deposit/withdraw cash request is submitting. Please wait.')
      this.mondaydepositwithdrwalcashmodel = new MondayDepositWithrawalCash()
      this.mondaydepositwithdrwalcashmodel.AdvisorName = this.advisorname
      this.mondaydepositwithdrwalcashmodel.CompanyName = this.companyname
      this.mondaydepositwithdrwalcashmodel.Login = this.login
      this.mondaydepositwithdrwalcashmodel.AccountName = this.accountname
      this.mondaydepositwithdrwalcashmodel.AccountNumber = this.accountnumber
      this.mondaydepositwithdrwalcashmodel.Action = this.action 
      this.mondaydepositwithdrwalcashmodel.Type = this.type 

      if(this.action == "Deposit"){
        if(this.depositpaymentday != null && this.depositpaymentday != undefined){
            this.mondaydepositwithdrwalcashmodel.PaymentDay = this.depositpaymentday.slice(0,-2)
        }  
      }
      else
      {
        if(this.withdrawalpaymentday != null && this.withdrawalpaymentday != undefined){
          this.mondaydepositwithdrwalcashmodel.PaymentDay = this.withdrawalpaymentday.slice(0,-2)
      } 
      }
      this.mondaydepositwithdrwalcashmodel.Amount = this.amount.replace(/,/g,'') 
      this.mondaydepositwithdrwalcashmodel.Notes = this.notes
      this.mondaydepositwithdrwalcashmodel.Email = this.loggedAgent.Email

      //console.log(this.mondaydepositwithdrwalcashmodel)
  
      this.mondaydepositwithdrawcashdataservice.DepositWithdrawCash(this.mondaydepositwithdrwalcashmodel, this.loggedAgent).subscribe(date =>
      {
        this.alertService.clear()
        this.alertService.success('Successfully saved')
        this.disablesubmitbtn = false
        this.initializeFormData()
      },
      error => { 
        if (error.error.toLowerCase().includes("an asynchronous module or handler completed while an asynchronous operation was still pending")) // To handle An asynchronous module or handler completed while an asynchronous operation was still pending.
          {
            this.alertService.success('Successfully saved')
            this.disablesubmitbtn = false
            this.initializeFormData()
          } else{
            this.alertService.error('Error occured while saving data')
            this.disablesubmitbtn = false
          }
       }); 

    }
    
  } 

  FilterInput(event){

    // skip for arrow keys
    if(event.which >= 37 && event.which <= 40) return;
  
    switch (event.currentTarget.id) {
      case "amount":
        this.amount = event.target.value
                  .replace(/\D/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")  
        break; 
      default:
    }  

  /*   if(event.key != "Backspace" && event.key != "Tab" && event.key != "Enter"){
      this.alertService.clear();
      if(/^\d+$/.test(event.key)){
        this.alertService.clear();
      } else{
        this.alertService.error('Please enter a number');

      switch (event.currentTarget.id) { 
        case "amount":
          this.amount = ""
          break;
        default:
      }
    }
    } else{
      this.alertService.clear();
    } */
  }

  clearAlert(){
    this.alertService.clear();
  }

  /* resetAccountType(event){
    if(event.currentTarget.value == "---"){
      this.accounttype = ""
    }
  }

  resetInstructionType(event){
    if(event.currentTarget.value == "---"){
      this.instructiontype = ""
    }
  }
 */

  resetType(event){
    if(event.currentTarget.value != null && event.currentTarget.value != undefined){
      if(this.type == "Recurring"){
        this.showpaymentday = true
      }else{
        this.showpaymentday = false
      }
    } else {
      this.showpaymentday = false
    }
  }

  changeAction(event){ 
    if(event.currentTarget.value != null && event.currentTarget.value != undefined){
      if(this.action == "Deposit"){
        this.isDeposit = true
      }else{
        this.isDeposit = false
      }
    } else {
      this.isDeposit = false
    }
  } 

  exitBtnClickEvent()
  {
    if(JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) != null){
      this.sharedService.changeSideBarRequest()   
			this.router.navigateByUrl('home')
    } else{
      this.router.navigateByUrl('landing'); 
    }
    
  } 
}