import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Agent } from './models/agent.model';  
import { LocalStorage } from './util/localstorage.service';
import { Router } from '@angular/router';
import { RouteUtil } from './util/route-util';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'longevity';
	public loggedAgent: Agent 

	constructor(public translate: TranslateService,private router: Router) {
		translate.addLangs(['en', 'fr'])
		translate.setDefaultLang('en')
		const browserLang = translate.getBrowserLang()
		translate.use(browserLang.match(/en|fr/) ? browserLang : 'en')
		var pathName: string = window.location.pathname.replace('/', '');
	 console.log(pathName)
		if (RouteUtil.canProceedWithoutSession().allowed) { 

			 // doing nothing
		}

		else{

			if (localStorage.hasOwnProperty(LocalStorage.LOGGED_AGENT))	{
				this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
				if ((this.loggedAgent != null) && (this.loggedAgent != undefined)) {
					
				} else {
					//Don't know the user, send to login. 
					this.router.navigateByUrl('');
				}
			}
			else
			{
				//Don't know the user, send to login. 
				this.router.navigateByUrl('');
			}
		}
	}
}
