import { Component, OnInit, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { version } from '../../../package.json';
import { Router } from '@angular/router';
import { Constants } from '../util/constants.js';
import { Agent } from '../models/agent.model';  
import { LocalStorage } from '../util/localstorage.service';
import { environment } from 'src/environments/environment.js';
import { BsModalService,BsModalRef}   from 'ngx-bootstrap/modal';


@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	inputs: ['optionType'],
	outputs: ['showHideSideBar']
})
export class HeaderComponent implements OnInit {

	
	//public projectName: string = environment.appname
	public appVersion: string = version
	public serviceVersion: string = ""
	public optionType: any = Constants.LANDING_HEADER_OPTION
	public isToggleMenu: boolean = false
	public isShowSearch: boolean = true
	public loggedInUser: any
	public loggedAgent: Agent 
	public isAdmin: boolean = false
	public isRIAOnly: boolean = false
	public isFP4FA: boolean = false
	public showMenus: boolean = false

	public showHideSideBar = new EventEmitter<any>()
	public ModalRef : BsModalRef 
	@ViewChild('rightSideBar') rightSideBar: TemplateRef<any>

	config = {
		class: "modalsidebar"
	};

	constructor(private router: Router, private bsModalService :BsModalService) { }

	ngOnInit() {
		this.serviceVersion   = JSON.parse(localStorage.getItem(LocalStorage.SERVICE_VERSION)) as string;
		this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
		if ((this.loggedAgent != null) && (this.loggedAgent != undefined)) {
			this.loggedInUser = {
				firstName: this.loggedAgent.FirstName,
				lastName: this.loggedAgent.LastName				
			}
			
			// check whther the user is Admin
			if (this.loggedAgent.UserRoleID== Constants.UserRoleID.Admin)
				this.isAdmin = true
			else
				this.isAdmin = false

			// check whther the user is RIA Only
			if (this.loggedAgent.UserRoleID== Constants.UserRoleID.RIAOnly){
				this.showMenus = false
				this.isRIAOnly = true
			}else{
				this.showMenus = true
				this.isRIAOnly = false	
			}

			// check whther the page is fp4fa
			if (localStorage.getItem(LocalStorage.FP4FA)=="fp4fa"){
				this.showMenus = false
				this.isFP4FA = true
			}else{
				this.showMenus = true
				this.isFP4FA = false
			}

		} else {
			//Don't know the user, send to login. 
			this.router.navigateByUrl('');
		}
		 
		
	}

	public showToggleMenu() {

	}

	public showHideSlideBar() {
		console.log('[showHideSlideBar]');
		this.showHideSideBar.emit()
	}

	public signOutBtnClickEvent() {
		localStorage.clear();
		this.router.navigateByUrl('')
	}
	public FTAStrategies() {
		this.router.navigateByUrl('/FTAStrategies')
	}
	public Blends() {
		localStorage.setItem(LocalStorage.ISMYBLEND, JSON.stringify(Constants.Blend.Blends));  
		this.router.navigateByUrl('/Blends')
	}
	public MyBlends() {
		localStorage.setItem(LocalStorage.ISMYBLEND, JSON.stringify(Constants.Blend.MyBlends)); 
		this.router.navigateByUrl('/MyBlends')
	}
	public IndexBlends() {
		localStorage.setItem(LocalStorage.ISMYINDEXBLEND, JSON.stringify(Constants.IndexBlend.IndexBlends));  
		this.router.navigateByUrl('/IndexBlends')
	}
	public MyIndexBlends() {
		localStorage.setItem(LocalStorage.ISMYINDEXBLEND, JSON.stringify(Constants.IndexBlend.MyIndexBlends)); 
		this.router.navigateByUrl('/MyIndexBlends')
	}
	public TickerBlends() {
		localStorage.setItem(LocalStorage.ISMYTICKERBLEND, JSON.stringify(Constants.TickerBlend.TickerBlends));  
		this.router.navigateByUrl('/TickerBlends')
	}
	public MyTickerBlends() {
		localStorage.setItem(LocalStorage.ISMYTICKERBLEND, JSON.stringify(Constants.TickerBlend.MyTickerBlends)); 
		this.router.navigateByUrl('/MyTickerBlends')
	}
	public RiskGraphReports() {
		localStorage.setItem(LocalStorage.REPORT_TYPE, JSON.stringify(Constants.SavedReportType.RiskGraph)); 
		this.router.navigateByUrl('/RiskGraphReports')
	}
	public InvestmentReports() {
		localStorage.setItem(LocalStorage.REPORT_TYPE, JSON.stringify(Constants.SavedReportType.InvestmentReport)); 
		this.router.navigateByUrl('/InvestmentReports')
	}
	public ArchivedReports() {
		localStorage.setItem(LocalStorage.REPORT_TYPE, JSON.stringify(Constants.SavedReportType.OldZephyr)); 
		this.router.navigateByUrl('/ArchivedReports')
	}
	public AllocationRequest() {
		this.router.navigateByUrl('/MondayAllocationRequest')
	}
	public DepositWithdrawCash() {
		this.router.navigateByUrl('/MondayDepositWithdrawCash')
	}
	
	openModal()
	{
		this.ModalRef = this.bsModalService.show(this.rightSideBar, this.config)
	}

	cancelModal(){
		this.ModalRef.hide()
	}
}
