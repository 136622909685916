import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '../util/localstorage.service'; 
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from "@angular/forms";
import { Agent } from '../models/agent.model';
import { TickerBlend, TickerBlendDetail } from '../models/ticker-blend.model';
import { AlertService } from '../util/alert/alert.service';
import { CommonFunctions } from '../util/common-functions';
import { Constants } from '../util/constants'; 
import { GridOptions } from 'ag-grid-community'
import { CustomHeader} from '../util//customHeader'
import { TickerBlendDataService } from './ticker-blend-data.service';
import { BsModalService,BsModalRef}   from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { SharedServiceService } from '../util/shared-service.service';
import { ClientInfoDataService } from '../appComp/client-info/client-info-data.service';
  
@Component({
  selector: 'app-ticker-blend',
  templateUrl: './ticker-blend.component.html',
  styleUrls: ['./ticker-blend.component.scss']
})

export class TickerBlendComponent implements OnInit {
  
  public paddingtop: number = 60;
  public selectedChoiceID: string = "";
  public tickerblenddetails: Array<any>
  public id: any
  public copiedid: any
  public rebalancefrequency: any = "Monthly"
  public name:any
  public ismytickerblend:any
  public tickercount:any
  public ticker:any
  public tickersymbol: any
  public selectedtickerid: any
  public tickerlist: Array<any>
  public previousBeginDate
  public createdby:any
  public createdon:any
  public begindate:any
  public loggedAgent: Agent 
  public updateMode :boolean
  public copyMode :boolean
  public deleteMode: boolean
  public change: boolean
  public activeTabName :any 
  public iSForm: FormGroup;
  public total: number
  public totalPercentatge: number
  public commonDate:any
  private hasSpouse:boolean
  public TickerBlend:TickerBlend
  public TickerBlendDetail:TickerBlendDetail
  private gridApi: any;
  private gridApi2: any;
  private gridColumnApi: any;
  private gridColumnApi2: any;

  public columnDefs: any;
  public columnDefs2: any;
  public rowData: any;	
  public rowData2: any;	
  public gridOptions : GridOptions 
  public gridOptions2 : GridOptions 
  public currentSelectedColumn;
  public strategyDataValueList = []
  public invalidDataVal = false

  private commonFunctions: CommonFunctions = new CommonFunctions
  orderID :number = 0  

  @ViewChild('editTickerDetails') editTickerDetails: TemplateRef<any>
  public ModalRef : BsModalRef 
  getRowStyle: (params: any) => { "font-weight": string; };
  pinnedBottomRowData: any;
  frameworkComponents: { customPinnedRowRenderer: any; };

  constructor( private router: Router,
    private formBuilder: FormBuilder, private alertService: AlertService, private tickerblendsService: TickerBlendDataService,
    private bsModalService :BsModalService, private ldService: ClientInfoDataService,
    private sharedService: SharedServiceService) { 

      this.loggedAgent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent;
      // check whther the user is RIA Only - Only have forms access
		  if (this.loggedAgent.UserRoleID== Constants.UserRoleID.RIAOnly){
        this.router.navigateByUrl('MondayAllocationRequest'); 
        } 

      this.gridOptions = { 
        components: {
          agColumnHeader: CustomHeader
        },
        defaultColDef: { 
          headerComponentParams: {
            setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
            getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          } 
        } 
      };

      this.columnDefs = [
        { 
          headerName: 'ID', 
          field: 'Id',
          hide: true
          //sortable: true, 
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Name', 
          field: 'TickerBlendName',
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Ticker Count', 
          field: 'TickerCount',
          cellStyle: {textAlign: "left"} 
          // sortable: true, 
          // width: 125,
          // filter: true,
          // cellClass: "rag-blue"
        },
        { 
          headerName: 'Rebalance Frequency', 
          field: 'RebalanceFrequency',
          cellStyle: {textAlign: "left"} 
          // sortable: true, 
          // width: 125,
          // filter: true,
          // cellClass: "rag-blue"
        },
        { 
          headerName: 'Begin Date', 
          field: 'BeginDate',
          cellStyle: {textAlign: "left"}//,  
          // sortable: true, 
          // filter: true,
          // cellClass: "rag-yellow"
        },
        { 
          headerName: 'Created By', 
          field: 'CreatedBy',
          hide: true,  
         // sortable: true, 
         // filter: true,
         // cellClass: "rag-yellow"
        },
        { 
          headerName: 'Created On', 
          field: 'CreatedOn',
          hide: true,  
         // sortable: true, 
         // filter: true,
         // cellClass: "rag-yellow"
        }
      ];



      this.gridOptions2 = { 
        components: {
          agColumnHeader: CustomHeader
        },
        defaultColDef: { 
          headerComponentParams: {
            setSelectedColumn: (colId) => this.setCurrentSelectedColumn(colId),
            getSelectedColumn: () =>  this.getCurrentSelectedColumn()
          } 
        } 
      };

      this.columnDefs2 = [
        { 
          headerName: 'TickerBlendDetailID', 
          field: 'TickerBlendDetailID',
          hide: true,
          //sortable: true, 
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'TickerBlendID', 
          field: 'TickerBlendID',
          hide: true
          // width: 125,
          // filter: true//,
          //cellClass: "rag-blue"
        },
        { 
          headerName: 'Ticker Symbol', 
          field: 'TickerSymbol',
          cellStyle:  function(params) {
            if (params.node.rowPinned){
              return {backgroundColor: "#dcddde", textAlign: "left"}
            }else{
              return {textAlign: "left"}
            }
          },
          // sortable: true, 
          //width: 50,
          // filter: true,
          // cellClass: "rag-blue"
        },
        { 
          headerName: 'Begin Date', 
          field: 'TickerBeginDate',
          cellStyle:  function(params) {
            if (params.node.rowPinned){
              return {backgroundColor: "#dcddde", textAlign: "left"}
            }else{
              return {textAlign: "left"}
            }
          },  
          // sortable: true, 
          // filter: true,
          // cellClass: "rag-yellow"
        },
        { 
          headerName: 'Allocation', 
          field: 'Allocation',
          cellStyle:  function(params) {
            if (params.node.rowPinned){
              return {backgroundColor: "#dcddde", textAlign: "right"}
            }else{
              return {textAlign: "right"}
            }
          },
          valueFormatter: function(params) {
            return params.value + '%'; 
          },
          editable: function(params) {
            if (params.node.rowPinned){
              return false;
            }else{
              return true;
            }
          }
          
        }
      ];

      this.getRowStyle = function(params) {
        if (params.node.rowPinned) {
          return { "font-weight": "bold"};
        }
      };

    }

    onPinnedRowBottomCount() {
      var footerRowsToFloat = 10;
      var count = Number(footerRowsToFloat);
      var rows = createData(count, "Bottom", this.total, this.totalPercentatge, this.begindate);
      setTimeout(()=> {
      this.gridApi2.setPinnedBottomRowData(rows);
    }, 50); 
    }

  ngOnInit() {

   this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
   this.iSForm = this.formBuilder.group({
			name: new FormControl(null) ,
      tickers: new FormControl(null),
      begindate: new FormControl(null),
      ismytickerblend: new FormControl(null),
      rebalancefrequency: new FormControl(null),
      tickersymbol: new FormControl(null)
    } );   
    this.loadData()
  }

  loadData()
	{
		this.ismytickerblend = JSON.parse(localStorage.getItem(LocalStorage.ISMYTICKERBLEND))
		this.tickerblendsService.gettickerblends(this.loggedAgent, this.ismytickerblend).subscribe(data => this.getData(data))
	}

  public getData(data: any) {
    let tickerblends: Array<any>=[] 
    data.forEach((element: any) => {
      //let client: Client = element
      let TickerBlend: TickerBlend = element
      TickerBlend.TickerBlendID = element.TickerBlendID
      TickerBlend.TickerBlendName = element.TickerBlendName
      TickerBlend.TickerCount = element.TickerCount
      TickerBlend.RebalanceFrequency = element.RebalanceFrequency
      var datePipe = new DatePipe('en-US');
      TickerBlend.BeginDate = datePipe.transform(element.BeginDate, 'MM/dd/yyyy');
      TickerBlend.IsMyTickerBlend = element.IsMyTickerBlend
      TickerBlend.CreatedBy = element.CreatedBy
      TickerBlend.CreatedOn = element.CreatedOn

      tickerblends.push({Id: TickerBlend.TickerBlendID, TickerBlendName: TickerBlend.TickerBlendName ,TickerCount: TickerBlend.TickerCount,
        RebalanceFrequency: TickerBlend.RebalanceFrequency, BeginDate: TickerBlend.BeginDate, IsMyTickerBlend: TickerBlend.IsMyTickerBlend,
      CreatedBy: TickerBlend.CreatedBy, CreatedOn: TickerBlend.CreatedOn})

    });
    setTimeout(()=> {
      this.gridApi.forEachNode(node => {
        if ( node.rowIndex  === 0 ){ 
          node.setSelected(true)
          this.id = node.data.Id
          this.name = node.data.TickerBlendName
          this.rebalancefrequency = node.data.RebalanceFrequency
          this.tickercount = node.data.TickerCount
          this.begindate = node.data.BeginDate
          this.previousBeginDate = node.data.BeginDate
          this.createdby = node.data.CreatedBy
          this.createdon = node.data.CreatedOn
          this.updateMode = true
          this.deleteMode = false
          this.copyMode = false
          this.copiedid = ""
          if(this.id != "" && this.id != undefined){
            this.tickerblendsService.gettickerblenddetailsbyid(this.id, this.loggedAgent).subscribe(data => this.getTickerBlendDetails(data))
          }
        } 
      }); 
    
    }, 50); 

    
    this.rowData = tickerblends;
    this.initializeTickerBlends();
    return this.rowData;
  }

  public getTickerBlendDetails(data: any) {
    this.total = 0
    this.totalPercentatge = 0
    this.gridOptions2.api.showLoadingOverlay()
    let tickerblendDetails: Array<any>=[] 
    data.forEach((element: any) => {
      let TickerBlendDetail: TickerBlendDetail = element
      TickerBlendDetail.TickerBlendDetailID = element.TickerBlendDetailID
      TickerBlendDetail.TickerBlendID = element.TickerBlendID
      TickerBlendDetail.TickerSymbol = element.TickerSymbol
      TickerBlendDetail.Allocation = element.Allocation
      this.totalPercentatge = this.totalPercentatge + parseInt(element.Allocation)

      let TickerBeginDate
      this.tickerblendsService.gettiingotickermetadata(TickerBlendDetail.TickerSymbol, this.loggedAgent).subscribe(data => {
        var datePipe = new DatePipe('en-US');
        TickerBeginDate = datePipe.transform(data.startDate, 'MM/dd/yyyy');

        tickerblendDetails.push({TickerBlendDetailID: TickerBlendDetail.TickerBlendDetailID, TickerBlendID: TickerBlendDetail.TickerBlendID ,
          TickerSymbol: TickerBlendDetail.TickerSymbol, TickerBeginDate: TickerBeginDate
          , Allocation: TickerBlendDetail.Allocation})  
          this.rowData2 = tickerblendDetails;
          this.total = this.rowData2.length
          this.getCommonDate()
          this.pinnedBottomRowData = createData(1, "Bottom", this.total, this.totalPercentatge, this.commonDate);
          if(this.gridOptions2.api != null && this.gridOptions2.api != undefined){
            this.gridOptions2.api.setRowData(this.rowData2);
          }
          this.gridApi2.setPinnedBottomRowData(this.pinnedBottomRowData);

          setTimeout(()=> {
          this.gridApi2.forEachNode(node => {
            if ( node.rowIndex  === 0 ){ 
              this.selectedtickerid = node.data.TickerBlendDetailID
              node.setSelected(true)
            } 
          }); 
         }, 50);
          this.gridOptions2.api.hideOverlay()
      })

    });  
    setTimeout(() => {this.gridApi.sizeColumnsToFit();});
  }
  

  public addTickerSymbol(event: any) {
    this.alertService.clear()
    let alreadyexist = false
    this.gridApi2.forEachNode(node => {
      if ( node.data.TickerSymbol  == this.tickersymbol ){ 
        this.alertService.error('Ticker Symbol already exists')
        alreadyexist = true
        return false
      } 
    }); 


    if (this.tickersymbol == "" || this.tickersymbol == null ){  
		  this.alertService.error('Invalid Ticker Symbol')
		  return false
    } 
	  else if(!alreadyexist){
		  this.ldService.hashistoricaldata(this.tickersymbol, this.loggedAgent).subscribe(hasdata => 
			{ 
				if (hasdata)
				{
          if(this.rowData2 == undefined){
            this.rowData2 = []
          }

          let TickerBeginDate
          this.tickerblendsService.gettiingotickermetadata(this.tickersymbol, this.loggedAgent).subscribe(data => {
            var datePipe = new DatePipe('en-US');
            TickerBeginDate = datePipe.transform(data.startDate, 'MM/dd/yyyy');
            
          this.rowData2.push({TickerBlendDetailID: this.commonFunctions.getnewguid() , TickerBlendID: this.id ,
            TickerSymbol: this.tickersymbol, TickerBeginDate: TickerBeginDate, Allocation: 0})
          this.tickersymbol = ""
          this.getCommonDate()
          this.begindate = this.commonDate
          this.pinnedBottomRowData = createData(1, "Bottom", this.total, this.totalPercentatge, this.commonDate);
          this.gridOptions2.api.setRowData(this.rowData2);
          this.gridApi2.setPinnedBottomRowData(this.pinnedBottomRowData);
          this.total = this.rowData2.length
          setTimeout(()=> {
            this.gridApi2.forEachNode(node => {
              if ( node.rowIndex  === 0 ){ 
                node.setSelected(true)
                this.selectedtickerid = node.data.TickerBlendDetailID
              } 
            });    
            }, 50); 
          })
					
				}
				else 
				{ 
          this.alertService.clear()
          this.alertService.error('Invalid Ticker Symbol')
				} 
			},
			error => {
				this.alertService.clear()
				this.alertService.error('Error occured while retrieving data')
				});  
	  } 

  }
TickerToUppercase(event: any) { 
	if (typeof event !== 'undefined') {
		this.tickersymbol = event.toString().toUpperCase(); 
	}
}
    
public getCommonDate(){
  let date = ""
  this.commonDate = ""
  this.rowData2.forEach((element: any) =>{
    let newDate = null
    newDate = new Date(element.TickerBeginDate);
    if((newDate > date) || date == ""){
      date = newDate
    }
  })
  var datePipe = new DatePipe('en-US');
  this.commonDate = datePipe.transform(date, 'MM/dd/yyyy');
}

public deleteTickers(){
  this.invalidDataVal = false
  this.totalPercentatge = 0
  this.rowData2.forEach((element: any) =>{
    if(element.TickerBlendDetailID == this.selectedtickerid){
      this.rowData2.splice(this.rowData2.indexOf(element),1)
    }
  })
  this.gridOptions2.api.setRowData(this.rowData2); 
  this.total = this.rowData2.length
  setTimeout(()=> {
    this.gridApi2.forEachNode(node => {
      this.selectedtickerid = node.data.TickerBlendDetailID
      //this.selectedTickers = ""
      this.totalPercentatge = this.totalPercentatge + parseInt(node.data.Allocation)
    }); 
    this.getCommonDate()
    this.begindate = this.commonDate
    this.pinnedBottomRowData = createData(1, "Bottom", this.total, this.totalPercentatge, this.commonDate);
    this.gridOptions2.api.setRowData(this.rowData2); 
    this.gridApi2.setPinnedBottomRowData(this.pinnedBottomRowData);

    this.gridApi2.forEachNode(node => { 
      if ( node.rowIndex  === 0 ){
        node.setSelected(true)  
        this.selectedtickerid = node.data.TickerBlendDetailID 
      }
      if(!node.data.Allocation.toString().match(/^[+\-]?(\.\d+|\d+(\.\d+)?)$/)){
        this.invalidDataVal = true
      }
      
    }); 
  }, 50);

}

  initializeTickerBlends()
  {
    this.id = ""
    this.name = ""
    this.tickercount = ""
    this.rebalancefrequency = "Monthly"
    this.begindate = ""  
    this.createdby = "" 
    this.createdon = "" 
    this.tickerlist = []
    this.tickersymbol = ""
    this.totalPercentatge = 0
    this.total = 0
    this.pinnedBottomRowData = createData(1, "Bottom", this.total, this.totalPercentatge, null);
    if(this.gridApi2 != undefined){
      this.gridApi2.setPinnedBottomRowData(this.pinnedBottomRowData);
    }
  this.updateMode = false 
  }

  onGridReady(params: any) { 
		this.gridApi  = params.api;
		this.gridColumnApi = params.columnApi; 
		setTimeout(() => { 
        this.loadData()     
      	//params.api.sizeColumnsToFit();
    }, 50);

  }

  onGridReady2(params: any) { 
		this.gridApi2  = params.api;
		this.gridColumnApi2 = params.columnApi; 
  }

  onBeginDateChange(event){
    this.begindate = event
  }

  getCurrentSelectedColumn() {
    return this.currentSelectedColumn;
    }
    
  setCurrentSelectedColumn(colId) {
    this.currentSelectedColumn = colId;
    }


  loadTickerBlendsStaticDataForNew()
  {   
    this.initializeTickerBlends()   
  }

  gridSizeChanged(params: any) {
		params.api.sizeColumnsToFit();
  }

  public onTickerClicked(event: any){
    event.node.setSelected(true);
    this.selectedtickerid = event.data.TickerBlendDetailID
  }
  
  public onCellClicked(event: any) {
    this.invalidDataVal = false
    this.alertService.clear()
    if(event.data.Id == ""){
      this.tickerblendsService.gettickerblenddetailsbyid(this.copiedid, this.loggedAgent).subscribe(data => this.getTickerBlendDetails(data))
    }
    if(event.data.IndexBlendName == ""){
      this.rowData2 = []
      this.gridOptions2.api.setRowData(this.rowData2);
      this.loadTickerBlendsStaticDataForNew()
    }
    this.updateMode = true; 
    this.copyMode = true; 
    this.deleteMode = false; 
    if(event.data.Id != "" && event.data.Id != undefined){
      this.tickerblendsService.gettickerblenddetailsbyid(event.data.Id, this.loggedAgent).subscribe(data => this.getTickerBlendDetails(data))
    }
    event.node.setSelected(true);
    var selectedTickerBlendID : string = event.data.Id
    var selectedTickerBlendName : string = event.data.TickerBlendName
    var selectedTickerBlendTickerCount : string = event.data.TickerCount
    var selectedTickerBlendRebalanceFrequency : string = event.data.RebalanceFrequency
    var selectedTickerBlendBeginDate : string = event.data.BeginDate
    var selectedTickerBlendIsMyTickerBlend : string = event.data.IsMyTickerBlend
    var selectedTickerBlendCreatedBy : string = event.data.CreatedBy
    var selectedTickerBlendCreatedOn : string = event.data.CreatedOn
    this.loadTickerBlend(selectedTickerBlendID,selectedTickerBlendName,selectedTickerBlendTickerCount, selectedTickerBlendRebalanceFrequency, selectedTickerBlendBeginDate,
      selectedTickerBlendIsMyTickerBlend, selectedTickerBlendCreatedBy,selectedTickerBlendCreatedOn) 
  }

  loadTickerBlend(selectedTickerBlendID:string,selectedTickerBlendName:string,selectedTickerBlendTickerCount:string,selectedTickerBlendRebalanceFrequency:string,selectedTickerBlendBeginDate:string,
    selectedTickerBlendIsMyTickerBlend:string,selectedTickerBlendCreatedBy:string,selectedTickerBlendCreatedOn:string)
  {
    this.id = selectedTickerBlendID;
    this.name = selectedTickerBlendName;
    this.tickercount = selectedTickerBlendTickerCount;
    this.rebalancefrequency = selectedTickerBlendRebalanceFrequency;
    this.begindate = selectedTickerBlendBeginDate;
    this.previousBeginDate = selectedTickerBlendBeginDate
    this.createdby = selectedTickerBlendCreatedBy;
    this.createdon = selectedTickerBlendCreatedOn;
  }

  searchinarray(nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
     
        if (myArray[i].value === nameKey) {
          //console.log(myArray[i].value)
            return myArray[i].value;
        }
    }
}

saveBtnClickEvent()
{  
  if (this.validateTickerBlend()){
    //if another row is clicked after adding a new row or copied a row
    if(this.id == "" || this.createdby == "" || this.createdon == ""){
      this.id =  this.commonFunctions.getnewguid() 
      this.createdby = this.loggedAgent.AgentID
      this.createdon = new Date()
    }
    this.tickerblenddetails =[]
    this.rowData2.forEach((element: any) =>{
      this.TickerBlendDetail = new TickerBlendDetail()
      this.TickerBlendDetail.TickerBlendDetailID = this.commonFunctions.getnewguid()
      this.TickerBlendDetail.TickerBlendID = this.id
      this.TickerBlendDetail.TickerSymbol = element.TickerSymbol
      this.TickerBlendDetail.Allocation = element.Allocation
      this.tickerblenddetails.push(this.TickerBlendDetail)
    })

    this.TickerBlend = new TickerBlend()
    this.TickerBlend.TickerBlendName = this.name
    this.TickerBlend.TickerCount = this.tickercount
    this.TickerBlend.RebalanceFrequency = this.rebalancefrequency
    this.TickerBlend.BeginDate =  new Date(this.begindate).toLocaleDateString() 
	  this.ismytickerblend = JSON.parse(localStorage.getItem(LocalStorage.ISMYTICKERBLEND))
    this.TickerBlend.IsMyTickerBlend = this.ismytickerblend
    this.TickerBlend.TickerBlendDetail = this.tickerblenddetails
    if(this.updateMode){
      this.TickerBlend.TickerBlendID =  this.id
      this.TickerBlend.CreatedBy = this.createdby
      this.TickerBlend.CreatedOn = this.createdon
    } else{
      this.TickerBlend.TickerBlendID =  this.id
      this.TickerBlend.CreatedBy = this.loggedAgent.AgentID
      this.TickerBlend.CreatedOn = new Date()
    }
  
  if(this.deleteMode)
  {
    this.TickerBlend.bDeleted = true
    this.TickerBlend.DeletedBy = this.loggedAgent.AgentID
    this.TickerBlend.DeletedOn = new Date()
  } else{
    this.TickerBlend.bDeleted = false
  }
  this.tickerblendsService.saveTickerBlend(this.TickerBlend, this.loggedAgent).subscribe(date =>
    {
      this.alertService.clear()
      if(this.updateMode && !this.deleteMode){
        this.alertService.success('Successfully updated')
      } else if(this.deleteMode){
        this.alertService.success('Successfully deleted')
      } else{
        this.alertService.success('Successfully saved')
      }
      
      this.loadData()
      this.tickerblenddetails = []
    },
    error => { 
      this.alertService.clear()
      this.alertService.error('Error occured while saving data')
      this.loadData()
     });
    }
}

cancelBtnClickEvent()
{
  this.alertService.clear()
  this.invalidDataVal = false
  this.id = ""
  this.loadTickerBlendsStaticDataForNew()
  this.rowData2 = []
  this.tickersymbol = ""
  this.gridOptions.api.deselectAll();
}

validateTickerBlend():boolean
  { 
    if(!this.deleteMode){
      this.alertService.clear()
      if ((this.iSForm.controls.name.value == ""  || 
        this.iSForm.controls.name.value == null))
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Name')
        return false
      } 
      if (this.rowData2.length == 0)
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Ticker count')
        return false
      }
      var i
      for(i = 0; i < this.rowData2.length; i++){
        if (this.rowData2[i].Allocation == 0)
        { 
          this.alertService.clear()
          this.alertService.error('Invalid Allocation')
          return false
        }
      }
      if ((this.iSForm.controls.rebalancefrequency.value == ""  || 
            this.iSForm.controls.rebalancefrequency.value == null ))
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Rebalance Frequency')
        return false 
      }     
  
      if ((this.iSForm.controls.begindate.value == ""  || 
      this.iSForm.controls.begindate.value == null ))
      { 
        this.alertService.clear()
        this.alertService.error('Invalid Begin Date')
        return false
      }
    }

    return true
  }  
  
  exitBtnClickEvent()
  {
    if(JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) != null){
      this.sharedService.changeSideBarRequest()   
			this.router.navigateByUrl('home')
    } else{
      this.router.navigateByUrl('landing'); 
    }
    
  }


  newBtnClickEvent()
  { 
    this.invalidDataVal = false
    this.alertService.clear()
    if(this.rowData.length != 0){
      let node
      node = this.rowData[this.rowData.length - 1]
      if(node.CreatedOn ==""){
        this.rowData.splice(this.rowData.indexOf(node),1)
      } 
    } 
    this.loadTickerBlendsStaticDataForNew()
    this.id = this.commonFunctions.getnewguid()
    this.rowData.push({Id: this.id, TickerBlendName: "" ,TickerCount: "", RebalanceFrequency: "",
      BeginDate: "", IsMyTickerBlend: "", CreatedBy: "", CreatedOn: ""})
    this.gridOptions.api.setRowData(this.rowData);
    this.gridApi.forEachNode(node => {
        node.setSelected(true)          
    }); 
    this.deleteMode = false   
    this.updateMode = false
    this.rowData2 = []
    this.gridOptions2.api.setRowData(this.rowData2);
  }

  copyBtnClickEvent()
  {
    this.invalidDataVal = false
    this.alertService.clear()
    this.copiedid = ""
    if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return
      this.updateMode = false
      if(this.rowData.length != 0){
        let node
        node = this.rowData[this.rowData.length - 1]
        if(node.CreatedOn ==""){
          this.rowData.splice(this.rowData.indexOf(node),1)
        } 
      } 
      if(this.id != "" && this.id != undefined){
        this.copiedid = this.id
        this.tickerblendsService.gettickerblenddetailsbyid(this.id, this.loggedAgent).subscribe(data => this.getTickerBlendDetails(data))
      }
      this.id = this.commonFunctions.getnewguid()
      var datePipe = new DatePipe('en-US');
      
      this.rowData.push({Id: "", TickerBlendName: this.name ,TickerCount: this.tickercount, RebalanceFrequency: this.rebalancefrequency,
      BeginDate: datePipe.transform(this.begindate, 'MM/dd/yyyy'), IsMyTickerBlend: "", CreatedBy: "", CreatedOn: ""})
      this.gridOptions.api.setRowData(this.rowData); 
      this.gridApi.forEachNode(node => {
        node.setSelected(true)          
    });
  }

  deleteBtnClickEvent()
  {     
    this.invalidDataVal = false
    this.alertService.clear()
    if(typeof this.gridApi.getSelectedRows()[0] === 'undefined') return    
    if (confirm("Are you sure you want to delete selected record?")) {  
      if(this.rowData.length != 0){
        let node
        node = this.rowData[this.rowData.length - 1]
        if(node.CreatedOn ==""){
          this.rowData.splice(this.rowData.indexOf(node),1)
          this.gridOptions.api.setRowData(this.rowData);
          this.loadData()
        } else{
          this.deleteMode = true
          this.updateMode = true
          this.saveBtnClickEvent()
        }
      }   
      this.rowData2 = []
      this.gridOptions2.api.setRowData(this.rowData2);
    }
  }

  editBtnClickEvent()
  {    
    this.ModalRef = this.bsModalService.show(this.editTickerDetails)
  }

  onCellValueChanged(event: any)
  {
    this.alertService.clear()
    if(event.newValue.match(/^[+\-]?(\.\d+|\d+(\.\d+)?)$/)){ 
      if(event.oldValue.toString().match(/^[+\-]?(\.\d+|\d+(\.\d+)?)$/)){
        this.totalPercentatge = this.totalPercentatge - parseInt(event.oldValue) 
      }
      this.totalPercentatge = this.totalPercentatge + parseInt(event.newValue)  
      this.pinnedBottomRowData = createData(1, "Bottom", this.total, this.totalPercentatge, this.commonDate);
      this.gridOptions2.api.setRowData(this.rowData2); 
      this.gridApi2.setPinnedBottomRowData(this.pinnedBottomRowData);
      setTimeout(()=> { 
        this.gridApi2.forEachNode(node => {
          if ( node.rowIndex  === 0 ){ 
            node.setSelected(true)
          } 
        }); 
      
      }, 50);
      this.invalidDataVal = false
    } else{
      this.alertService.clear()
      this.invalidDataVal = true
      this.alertService.error('Invalid value')
    }

    setTimeout(()=> { 
        this.gridApi2.forEachNode(node => {
            if(!node.data.Allocation.toString().match(/^[+\-]?(\.\d+|\d+(\.\d+)?)$/)){
              this.invalidDataVal = true
            }
        }); 
      
      }, 50);
  }
}
function createData(count, prefix, total, totalPercentatge, commonDate) {
  var result = [];
  if(commonDate == null){
    var datePipe = new DatePipe('en-US');
    commonDate = datePipe.transform(new Date(), 'MM/dd/yyyy');
  }
  var datePipe = new DatePipe('en-US');
  var today = datePipe.transform(new Date(), 'MM/dd/yyyy');
  for (var i = 0; i < count; i++) {
    result.push({
      TickerSymbol: total + " Allocations: " + commonDate + " - " + today,
      Allocation:  totalPercentatge
    });
  }
  return result;
}