import { Injectable } from '@angular/core';
import { Constants } from '../util/constants';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Agent } from '../models/agent.model';

@Injectable({
	providedIn: 'root'
})
export class LandingDataService {

	constructor(private http: HttpClient) { }

	/**
	 * this is the sample method to get data from server
	 * hardcoded URL need to put 
	 */
	getclientsbyagentorganization(loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
		}

		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getclientsbyagentorganization/' + loggedAgent.AgentID,null , httpOptions).pipe()
	}
}
