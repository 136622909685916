import { Component, OnInit,EventEmitter } from '@angular/core';
import { FP4FADataService } from '../fp4fa-data.service';
import { LocalStorage } from '../../util/localstorage.service';
import { Agent } from '../../models/agent.model';  
import { BusinessCalculatorInput } from '../../models/business-calculator-input.model';
import { BusinessCalculatorOutput } from '../../models/business-calculator-output.model';
import { DataPerYear } from '../../models/business-calculator-output.model'; 
import { AlertService } from '../../util/alert/alert.service';
import { CommonFunctions } from '../../util/common-functions';
import { SharedServiceService } from '../../util/shared-service.service';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
  outputs: ['loadCalculator']
})
export class CalculatorComponent implements OnInit {
  public loggedAgent: Agent 
  public businesscalculatorinput: BusinessCalculatorInput 
  public businesscalculatoroutput: BusinessCalculatorOutput  
  public yeardatalist: Array<DataPerYear> = []
  public commonFunctions: CommonFunctions = new CommonFunctions 
  public maxDrawDown : any
  public lowPositiveCashBalance : any
  public maxDrawDownMonth : any
  public lowPositiveCashBalanceMonth : any
  public loadCalculator = new EventEmitter<any>()
  public showCalculatorGrid: boolean;

  constructor(private FP4FAdataService: FP4FADataService, private alertService: AlertService,private sharedService: SharedServiceService,) { }

  ngOnInit() { 
    this.LoadCalculator()
  }

  ngAfterContentInit(): void {
		//Called after ngOnInit when the component's or directive's content has been initialized.
		//Add 'implements AfterContentInit' to the class.
		this.sharedService.loadCalculatorRespond().subscribe(option => this.LoadCalculator())
	 }

  public LoadCalculator(){
    
    this.showCalculatorGrid = false;
    this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent;    
    this.businesscalculatorinput   = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_INPUT)) as BusinessCalculatorInput; 
    this.businesscalculatoroutput   = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_OUTPUT)) as BusinessCalculatorOutput; 
    this.FP4FAdataService.generatebusinesscalculator(this.loggedAgent , this.businesscalculatorinput).subscribe(data => { 
      this.businesscalculatoroutput  = data 	
      this.yeardatalist = 	this.businesscalculatoroutput.YearData	
      localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_OUTPUT, JSON.stringify(this.businesscalculatoroutput)); 

      //set maxDrawDown and lowPositiveCashBalance
      let tempMaxDrawDown : any = this.businesscalculatoroutput.MaxDrawDown
      let tempLowPositiveCashBalance : any =this.businesscalculatoroutput.LowPositiveCashBalance
      if (isNaN(tempMaxDrawDown))
      {
        this.maxDrawDown=this.businesscalculatoroutput.MaxDrawDown
      }
      else 
      {
        this.maxDrawDown=this.commonFunctions.currencyFormatWithNegative(this.businesscalculatoroutput.MaxDrawDown)
      }

      if (isNaN(tempLowPositiveCashBalance))
      {
        this.lowPositiveCashBalance=this.businesscalculatoroutput.LowPositiveCashBalance
      }
      else 
      {
        this.lowPositiveCashBalance=this.commonFunctions.currencyFormatWithNegative(this.businesscalculatoroutput.LowPositiveCashBalance)
      }

      this.maxDrawDownMonth = this.businesscalculatoroutput.MaxDrawDownMonth
      this.lowPositiveCashBalanceMonth = this.businesscalculatoroutput.LowPositiveCashBalanceMonth

      if(data != null || data != undefined){
        this.showCalculatorGrid = true;
        this.alertService.clear()
      }
		},
		error => { 
      this.alertService.clear()
			this.alertService.error('An error occurred while generating Business Planning Calculator')
		});   
  }

}
