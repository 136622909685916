// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


/*export const environment = {
	production: true,
	server_URL: 'https://longevitysoftware.com/LongevityWebAPITest',
	router_prefix: '/api/v1'
}; */

/*export const environment = {
	production: true,
	server_URL: 'https://longevitysoftware.com/LongevityWebAPIV2',
	router_prefix: '/api/v1'
};*/

/*export const environment = {
	production: true,
	server_URL: 'http://localhost:65299', 
	router_prefix: '/api/v1' 
};*/

/*export const environment = {
	production: true,
	server_URL: 'http://localhost/LongevityWebAPI',
	router_prefix: '/api/v1'
};*/

/*export const environment = {
	production: true,
	server_URL: 'http://23.21.93.70:8080/LongevityWebAPITest',
	router_prefix: '/api/v1'
};*/

export const environment = {
	production: true,
	server_URL: 'http://3.90.121.205/LongevityWebAPIV2',
	router_prefix: '/api/v1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
