import { Color } from "ag-grid-community";

function CustomHeader() {
} 

CustomHeader.prototype.init = function (params) {
    this.params = params; 
    this.eGui = document.createElement('div'); 
    this.eGui.innerHTML = params.displayName; 
    this.eGui.style = "width: 100%; text-align:center; vertical-align: middle;  white-space: normal;display: inline-block;  font-weight: bold;  font-size:13px; " 
    this.eGui.addEventListener('click', this.onClickHeader.bind(this));
    
};

CustomHeader.prototype.isCurrentSelected = function() {
  return this.params.column.colId === this.params.getSelectedColumn();
}

CustomHeader.prototype.onClickHeader = function(e) {
  let currentSelection = this.params.api.getCellRanges();
  //this.params.api.clearRangeSelection();
 
  if(this.isCurrentSelected()) { 
    this.params.setSelectedColumn(null);
    this.params.api.clearRangeSelection();
  } else {
     
    if(currentSelection) {
      //this.params.api.clearRangeSelection();
    }
    let rowCount = this.params.api.getDisplayedRowCount();
    let col = this.params.column.colId; 
 
    this.params.api.addCellRange({
      rowStartIndex: 0,
      rowEndIndex: rowCount - 1,
      columnStart: this.params.column,
      columnEnd: this.params.column
    }); 

 
    this.params.setSelectedColumn(this.params.column.colId)
  }
  
}

CustomHeader.prototype.getGui = function () {
    return this.eGui;
};

CustomHeader.prototype.destroy = function () {
    
};

export { CustomHeader };

