export class TickerBlend {
    constructor( 
        public TickerBlendID?: string,
        public TickerBlendName?: string,
        public RebalanceFrequency?: string,
        public BeginDate?: string,
        public IsMyTickerBlend?: any,
        public TickerCount?: any,
        public CreatedBy?: string,
        public CreatedOn?: Date,
        public bDeleted?: any,
        public DeletedBy?: string,
        public DeletedOn?: Date, 
        public TickerBlendDetail?: any
    ) {}
}

export class TickerBlendDetail {
    constructor( 
        public TickerBlendDetailID?: string,
        public TickerBlendID?: string,
        public TickerSymbol?: string,
        public TickerName?: string,
        public TickerBeginDate?: string,
        public Allocation?: any
    ) {}
}
