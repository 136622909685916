export class DynamicSideBarItem {
    constructor(
        public id?: string,
        public text?: string,
        public orderNum?: number,
        public canEdit?: boolean,
        public canDelete?: boolean,
        public canCopy?: boolean,
        public canMove?: boolean
    ){}
}