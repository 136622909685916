export class BusinessPlanDashboard {
    constructor( 
        public BPDMonthlyData?: Array<BPDDataPerMonth>,
        public BPDYearlyData?: Array<BPDDataPerYear>,
        public BPDMarketingChannelData?: Array<BPDDataPerMarketingChannel>,
    ) {}
}

export class BPDDataPerMonth {
    constructor( 
        public Year?: string,
        public Month?: string,
        public Revenue?: Number,
        public TotalExpense?: Number,
        public EndingCash?: Number,
        public NetOwnersTake?: Number,
    ) {}
}

export class BPDDataPerYear {
    constructor( 
        public Year?: string,
        public ANNComm?: Number,
        public NoOfClients?: Number,
        public AUMComm?: Number,
    ) {}
}

export class BPDDataPerMarketingChannel {
    constructor( 
        public MarketingChannelID?: string,
        public MarketingChannel?: string,
        public Expenditure?: Number
    ) {}
}