import {
	Component,
	OnInit,
	ElementRef,
	ViewChild, 
	SimpleChanges,
	ViewContainerRef,
  ComponentRef,
  ComponentFactoryResolver,
  OnDestroy,
  TemplateRef
} from '@angular/core';
import { Router } from '@angular/router'; 
import { Constants } from '../../util/constants'; 
import { LocalStorage } from '../../util/localstorage.service'; 
import { Agent } from '../../models/agent.model'; 
import Stepper from 'bs-stepper';
import { TabsetComponent } from 'ngx-bootstrap/tabs'; 
 
import { CalculatorComponent } from '../calculator/calculator.component';
import { BusinessCalculatorInput, OtherRevenuePerYear } from '../../models/business-calculator-input.model';
import { BusinessCalculatorOutput } from '../../models/business-calculator-output.model';
import { DataPerYear } from '../../models/business-calculator-output.model';
import { RepData } from '../../models/business-calculator-input.model';
import { RepDataPerYear } from '../../models/business-calculator-input.model';
import { PercentageOfNetIncomePerYear } from '../../models/business-calculator-input.model';
import { AlertService } from 'src/app/util/alert/alert.service';
import { CommonFunctions } from '../../util/common-functions';
import { FP4FADataService } from '../fp4fa-data.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms'; 
import { SharedServiceService } from '../../util/shared-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BusinessCalculatorDefaults } from 'src/app/models/business-calculator-defaults.model';
import { FP4FACommonFunctionsService } from '../fp4fa-common-functions.service';


@Component({
  selector: 'app-revenue',
  templateUrl: './revenue.component.html',
  styleUrls: ['./revenue.component.scss']
})


export class RevenueComponent implements OnInit  {

//#region "Variable declaration"
  name = 'Angular';
  public modalRef: BsModalRef;
  public loggedAgent: Agent 
  public businesscalculatorinput: BusinessCalculatorInput 
  public businesscalculatoroutput: BusinessCalculatorOutput
  public yeardatalist: Array<DataPerYear> = []
  public ownerstakelist:Array<PercentageOfNetIncomePerYear> = [] 
  public selectedYear:any
  public repdataForm: FormGroup;
  public fillAcrossForm: FormGroup;
  public fillDownForm: FormGroup;
  public otherRevenue: FormGroup;
  public financialProductsForm: FormGroup;
  public ownersTakeForm: FormGroup;
  public otherexpensesForm: FormGroup;
  public currentStepperIndex: any  
  public currentStepperName:any  
  public saveactionshow :boolean = true
  public currentRowType: any

  //for fill across
  public fillAcrossValue: any

  //for fill down
  public fillDownValue: any

  //for fill all
  public fillAllValue: any

  //for ownsers take   
  public monthToStartOwnersTake : any  
  public firstYearMonthlyNETIncomeNeeds : any   
  public federalIncomeTax : any  
  public stateIncomeTax : any   

  //for other revenue   
  public existingAUM : any  

  private stepper: Stepper;
  //private calculatorComponent : any
  private commonFunctions: CommonFunctions = new CommonFunctions 

  status: boolean = false;
  resizestatus: boolean = false;

  //#endregion

//#region "constructor,  and ngOnInit"
  constructor(private resolver: ComponentFactoryResolver,private router: Router,
              private alertService: AlertService,private FP4FAdataService: FP4FADataService,
              private formBuilder: FormBuilder,private sharedService: SharedServiceService,
              private modalService: BsModalService, private fp4facommonFunctions: FP4FACommonFunctionsService) 
  {

  }

  ngOnDestroy()
  {
    this.alertService.clear(); 
  } 

  ngOnInit() 
  { 
    this.loggedAgent = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
    this.businesscalculatorinput   = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_INPUT)) as BusinessCalculatorInput;
    this.businesscalculatoroutput   = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_OUTPUT)) as BusinessCalculatorOutput;
    
    this.stepper = new Stepper(document.querySelector('#stepper-revenue'), {
      linear: false,
      animation: true
    })
    this.currentStepperIndex =0
	  this.changeStepper(this.currentStepperIndex) 

   this.fillAcrossForm = this.formBuilder.group({
    fillAcrossValue : new FormControl(null) 
   });

   this.fillDownForm = this.formBuilder.group({
    fillDownValue : new FormControl(null) 
   });

   this.otherRevenue = this.formBuilder.group({
    fillAllValue : new FormControl(null),
    existingAUM : new FormControl(null) 
   });

    this.ownersTakeForm= this.formBuilder.group({
      monthToStartOwnersTake : new FormControl(null) ,
      firstYearMonthlyNETIncomeNeeds : new FormControl(null) ,
      federalIncomeTax : new FormControl(null) ,
      stateIncomeTax : new FormControl(null)  
    });
 
    this.businesscalculatorinput   = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_INPUT)) as BusinessCalculatorInput;
    this.businesscalculatoroutput   = JSON.parse(localStorage.getItem(LocalStorage.BUSINESS_CALCULATOR_OUTPUT)) as BusinessCalculatorOutput;
    this.yeardatalist = 	this.businesscalculatoroutput.YearData	 
    this.yeardatalist.forEach((year: any) => { 
      let tempPercentageOfNetIncomes:PercentageOfNetIncomePerYear = this.businesscalculatorinput.OwnersTake.PercentageOfNetIncomes.find( y=> y.Year  == year.Year)
      if (tempPercentageOfNetIncomes != undefined){ 
      this.ownerstakelist.push( {Year: tempPercentageOfNetIncomes.Year, PercentageOfNetIncome: tempPercentageOfNetIncomes.PercentageOfNetIncome})
      }  
    });
    this.LoadOwnersTake() 
    this.LoadOtherRevenue()    
  }
  
//#endregion

//#region Save Action Button related Methods...
closeBottombar(){
  this.saveactionshow = false;
}

showBottombar(){
  this.saveactionshow = true;
}

SaveBusinessPlan(showBusinessPlanSavedAlert)
{
	this.alertService.clear()
	if(this.fp4facommonFunctions.ValidateSaveBusinessPlan()){
    this.fp4facommonFunctions.AddEmployees()
		this.FP4FAdataService.savebusinessplan(this.loggedAgent, this.businesscalculatorinput).subscribe(data => {
		if(showBusinessPlanSavedAlert){
			this.alertService.success('Business Plan successfully saved.')
		}
		localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
		},
		error => { 
			this.alertService.error('An error occurred while saving Business Plan')
    });
	}
}

//#endregion

//#region Stepper Methods 
next() 
{
  if (this.currentStepperIndex==1) //Last Stepper
  {
	this.sharedService.changeComponentsRequest(Constants.COMP_NAME.expense)
  }
  else
  {
	this.stepper.next();
	this.currentStepperIndex +=1
	this.changeStepper(this.currentStepperIndex)
  }    
}

  onSubmit() 
  {
    return false;
  }

  changeStepper(currentindex)
  {
    this.alertService.clear()
    // calling save business plans
	  this.SaveBusinessPlan(false)

    //show save button on stepper changes- when calculator is hidden
    if (this.status==true)
    {
      this.closeBottombar() 
    }   
    else
    {
      this.showBottombar() 
    }

    this.currentStepperIndex = currentindex
    switch (this.currentStepperIndex)
    {
      case 0:
        this.currentStepperName = "Owners Take"  
        break;
      case 1:  //Last Stepper
        this.currentStepperName = "Other Revenue" 
        break;
    }
  }
//#endregion

//#region  Calculator Methods */
  resizeRightbar()
  {
    this.resizestatus = !this.resizestatus;          
  }

  toggleRightbar()
  {
    if(this.fp4facommonFunctions.ValidateSaveBusinessPlan()){
      this.fp4facommonFunctions.AddEmployees()
      this.FP4FAdataService.savebusinessplan(this.loggedAgent, this.businesscalculatorinput).subscribe(data => {
      localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "0");
        this.status = !this.status; 
        if(this.status){
        this.alertService.info('Calculator is loading. Please wait.')
        this.closeBottombar()
        this.sharedService.loadCalculatorRequest() 
        }else
        {
          this.showBottombar() 
        }  
      },
      error => { 
        this.alertService.error('An error occurred while saving Business Plan')
      });
    }
  }

  refreshCalculator()
  {
    this.alertService.info('Calculator is loading. Please wait.')
    this.SaveBusinessPlan(false)
    this.sharedService.loadCalculatorRequest()   
  }
//#endregion

//#region Owners Take Methods */
LoadOwnersTake()
{ 

  this.monthToStartOwnersTake = this.commonFunctions.AddThousandSeparatorFormat(this.businesscalculatorinput.OwnersTake.MonthToStartOwnersTake)
  this.firstYearMonthlyNETIncomeNeeds = this.commonFunctions.AddThousandSeparatorFormat(this.businesscalculatorinput.OwnersTake.FirstYearMonthlyNETIncomeNeeds)
  this.federalIncomeTax  =this.commonFunctions.AddThousandSeparatorFormat(this.businesscalculatorinput.OwnersTake.FederalIncomeTax)
  this.stateIncomeTax =this.commonFunctions.AddThousandSeparatorFormat(this.businesscalculatorinput.OwnersTake.StateIncomeTax) 
}

AssignOwnersTake(event:any)
{ 
  if (event.target.id==="monthToStartOwnersTake") {
    if (this.monthToStartOwnersTake<=12)
    {
      this.businesscalculatorinput.OwnersTake.MonthToStartOwnersTake = this.commonFunctions.RemoveThousandSeparatorFormat(this.monthToStartOwnersTake) 
    }
    else
    {
      this.alertService.error("Month to start owner's take should not exceed 12 months");
      return
    }   
  }  else if (event.target.id==="firstYearMonthlyNETIncomeNeeds") {
    this.businesscalculatorinput.OwnersTake.FirstYearMonthlyNETIncomeNeeds = this.commonFunctions.RemoveThousandSeparatorFormat(this.firstYearMonthlyNETIncomeNeeds)  
  }  else if (event.target.id==="federalIncomeTax") {
    this.businesscalculatorinput.OwnersTake.FederalIncomeTax = this.commonFunctions.RemoveThousandSeparatorFormat(this.federalIncomeTax)  
  } else if (event.target.id==="stateIncomeTax") {
    this.businesscalculatorinput.OwnersTake.StateIncomeTax = this.commonFunctions.RemoveThousandSeparatorFormat(this.stateIncomeTax)  
  }   
 
  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
}

AssignPercentageofNetIncome(event:any,currentYear:any)
{ 
  let value :any =this.commonFunctions.RemoveThousandSeparatorFormat(event.target.value)
  if (value>100)
  {
    value = 100
    this.alertService.error("Percentage should not exceed 100");
  }
   
  let selectedPercentageofNetIncome : PercentageOfNetIncomePerYear
  selectedPercentageofNetIncome = this.businesscalculatorinput.OwnersTake.PercentageOfNetIncomes.find( x=> x.Year.toString()  ==  currentYear.toString())  
  if (selectedPercentageofNetIncome != undefined)
  {
      selectedPercentageofNetIncome.PercentageOfNetIncome = value
  } 

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");

}

FormatPercentageofNetIncome(event:any)
{
  this.alertService.clear()
  if(event.which >= 37 && event.which <= 40) return; 
  event.target.value=this.commonFunctions.AddThousandSeparatorFormat(event.target.value) 
}
//#endregion

//#region "Other Revenue Methods"
LoadOtherRevenue()
{ 
  this.existingAUM = this.commonFunctions.AddThousandSeparatorFormat(this.businesscalculatorinput.FinancialProducts.ExistingAUM) 
}

GetOtherRevenueAmount(currentYear:string,currentMonth:string)
{ 
    let revenueAmount: any
    let selectedOtherRevenue : OtherRevenuePerYear
    selectedOtherRevenue = this.businesscalculatorinput.OtherRevenue.find( x=> x.Year.toString()  ==  currentYear.toString() && x.Month.toString()  ==  currentMonth.toString())  
    if (selectedOtherRevenue != undefined)
    {
      revenueAmount = this.commonFunctions.AddThousandSeparatorFormat(selectedOtherRevenue.OtherRevenueAmount)
    } 
    else 
    { 
      revenueAmount =0
    }
    return revenueAmount;
}

FormatOtherRevenueAmount(event:any)
{
  this.alertService.clear()
  if(event.which >= 37 && event.which <= 40) return; 
  event.target.value=this.commonFunctions.AddThousandSeparatorFormat(event.target.value) 
}

AssignOtherRevenue(event:any,currentYear:any,currentMonth:any)
{ 
  let value :any
  if(event.target == undefined || event == undefined){
    value =this.commonFunctions.RemoveThousandSeparatorFormat(event)
  } else{
    value =this.commonFunctions.RemoveThousandSeparatorFormat(event.target.value)
  }
   
  let selectedOtherRevenue : OtherRevenuePerYear
  selectedOtherRevenue = this.businesscalculatorinput.OtherRevenue.find( x=> x.Year.toString()  ==  currentYear.toString() && x.Month.toString()  ==  currentMonth.toString())  
  if (selectedOtherRevenue != undefined)
  {
    selectedOtherRevenue.OtherRevenueAmount = value
  } 

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput)); 
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1"); 

}

AssignExistingAUM(event:any)
{ 
  let value :any
  if(event.target == undefined || event == undefined){
    value =this.commonFunctions.RemoveThousandSeparatorFormat(event)
  } else{
    value =this.commonFunctions.RemoveThousandSeparatorFormat(event.target.value)
  }
   
  this.businesscalculatorinput.FinancialProducts.ExistingAUM = value

  //Adding businesscalculatorinput to the local storage
  localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput)); 
  localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1"); 
}

OpenFillAcrossModal(template: TemplateRef<any>, rowType: string) { 
  // console.log(template)
   this.alertService.clear()
   this.currentRowType = rowType;
   this.fillAcrossValue ="";
   this.modalRef = this.modalService.show(template);
 }

FillAcross()
{ 

 let year = ""
 //validate 
 if (this.fillAcrossValue == undefined || this.fillAcrossValue == "")
 { 
   this.alertService.error('Please enter fill across value.');
   return;
 } else{
   //validate if the value is an integer
   if(!isNaN(Number(this.currentRowType))){
     year = this.currentRowType
     this.currentRowType = "year"
   }
 }
 switch (this.currentRowType)
 {   
   case 'year':
     this.AssignOtherRevenue(this.fillAcrossValue,year,1)
     this.AssignOtherRevenue(this.fillAcrossValue,year,2)
     this.AssignOtherRevenue(this.fillAcrossValue,year,3)
     this.AssignOtherRevenue(this.fillAcrossValue,year,4)
     this.AssignOtherRevenue(this.fillAcrossValue,year,5)
     this.AssignOtherRevenue(this.fillAcrossValue,year,6)
     this.AssignOtherRevenue(this.fillAcrossValue,year,7)
     this.AssignOtherRevenue(this.fillAcrossValue,year,8)
     this.AssignOtherRevenue(this.fillAcrossValue,year,9)
     this.AssignOtherRevenue(this.fillAcrossValue,year,10)
     this.AssignOtherRevenue(this.fillAcrossValue,year,11)
     this.AssignOtherRevenue(this.fillAcrossValue,year,12)
     break;  
 }    

 //Adding businesscalculatorinput to the local storage
 localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
 localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
 this.modalRef.hide();  

}

ClearAcross(rowType: string)
{
 this.currentRowType = rowType;
 let year = ""
 //validate if the value is an integer
 if(!isNaN(Number(this.currentRowType))){
   year = this.currentRowType
   this.currentRowType = "year"
 }
 switch (this.currentRowType)
 {   
   case 'year':
       this.AssignOtherRevenue(0,year,1)
       this.AssignOtherRevenue(0,year,2)
       this.AssignOtherRevenue(0,year,3)
       this.AssignOtherRevenue(0,year,4)
       this.AssignOtherRevenue(0,year,5)
       this.AssignOtherRevenue(0,year,6)
       this.AssignOtherRevenue(0,year,7)
       this.AssignOtherRevenue(0,year,8)
       this.AssignOtherRevenue(0,year,9)
       this.AssignOtherRevenue(0,year,10)
       this.AssignOtherRevenue(0,year,11)
       this.AssignOtherRevenue(0,year,12)
       break;
 }   
 //Adding businesscalculatorinput to the local storage
 localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
 localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");
}


OpenFillDownModal(template: TemplateRef<any>, rowType: string) { 
 // console.log(template)
  this.alertService.clear()
  this.currentRowType = rowType;
  this.fillDownValue ="";
  this.modalRef = this.modalService.show(template);
}


FillDown()
{ 
//validate 
if (this.fillDownValue == undefined || this.fillDownValue == "")
{ 
  this.alertService.error('Please enter fill down value.');
  return;
}

switch (this.currentRowType)
{   
  case 'Jan':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(this.fillDownValue,element.Year,1)
     }); 
    break;  
  case 'Feb':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(this.fillDownValue,element.Year,2)
     }); 
    break; 
  case 'Mar':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(this.fillDownValue,element.Year,3)
     }); 
    break; 
  case 'Apr':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(this.fillDownValue,element.Year,4)
     }); 
    break; 
  case 'May':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(this.fillDownValue,element.Year,5)
     }); 
    break; 
  case 'Jun':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(this.fillDownValue,element.Year,6)
     }); 
    break;
  case 'Jul':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(this.fillDownValue,element.Year,7)
     }); 
    break; 
  case 'Aug':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(this.fillDownValue,element.Year,8)
     }); 
    break;
  case 'Sep':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(this.fillDownValue,element.Year,9)
     }); 
    break; 
  case 'Oct':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(this.fillDownValue,element.Year,10)
     }); 
    break; 
  case 'Nov':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(this.fillDownValue,element.Year,11)
     }); 
    break; 
  case 'Dec':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(this.fillDownValue,element.Year,12)
     }); 
    break; 
}    
this.modalRef.hide();  

}


ClearDown(rowType: string)
{
 this.currentRowType = rowType;

switch (this.currentRowType)
{   
  case 'Jan':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(0,element.Year,1)
     }); 
    break;  
  case 'Feb':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(0,element.Year,2)
     }); 
    break; 
  case 'Mar':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(0,element.Year,3)
     }); 
    break; 
  case 'Apr':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(0,element.Year,4)
     }); 
    break; 
  case 'May':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(0,element.Year,5)
     }); 
    break; 
  case 'Jun':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(0,element.Year,6)
     }); 
    break;
  case 'Jul':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(0,element.Year,7)
     }); 
    break; 
  case 'Aug':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(0,element.Year,8)
     }); 
    break;
  case 'Sep':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(0,element.Year,9)
     }); 
    break; 
  case 'Oct':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(0,element.Year,10)
     }); 
    break; 
  case 'Nov':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(0,element.Year,11)
     }); 
    break; 
  case 'Dec':
     this.yeardatalist.forEach( (element) => {
       this.AssignOtherRevenue(0,element.Year,12)
     }); 
    break; 
  }    
}


FillAll(){

let year = ""
//validate 
if (this.fillAllValue == undefined || this.fillAllValue == "")
{ 
  this.alertService.error('Please enter fill all value.');
  return;
 }else{
  //validate if the value is an integer
  
}

this.yeardatalist.forEach( (element) => {
  this.AssignOtherRevenue(this.fillAllValue,element.Year,1)
  this.AssignOtherRevenue(this.fillAllValue,element.Year,2)
  this.AssignOtherRevenue(this.fillAllValue,element.Year,3)
  this.AssignOtherRevenue(this.fillAllValue,element.Year,4)
  this.AssignOtherRevenue(this.fillAllValue,element.Year,5)
  this.AssignOtherRevenue(this.fillAllValue,element.Year,6)
  this.AssignOtherRevenue(this.fillAllValue,element.Year,7)
  this.AssignOtherRevenue(this.fillAllValue,element.Year,8)
  this.AssignOtherRevenue(this.fillAllValue,element.Year,9)
  this.AssignOtherRevenue(this.fillAllValue,element.Year,10)
  this.AssignOtherRevenue(this.fillAllValue,element.Year,11)
  this.AssignOtherRevenue(this.fillAllValue,element.Year,12)
});  

//Adding businesscalculatorinput to the local storage
localStorage.setItem(LocalStorage.BUSINESS_CALCULATOR_INPUT, JSON.stringify(this.businesscalculatorinput));  
localStorage.setItem(LocalStorage.HAS_UNSAVED_FP4FA_DATA, "1");


}

//#endregion

//#region "Common methods"
  CommaFormatted(event: any) {  
    this.alertService.clear()
    // skip for arrow keys 
    if(event.which >= 37 && event.which <= 40) return;  

    let value =  this.commonFunctions.AddThousandSeparatorFormat(event.target.value)     
    // Fill Acorss
    if (event.target.id==="fillAcrossValue") {
      this.fillAcrossValue = value 
    }  

    // Fill Down
    else if (event.target.id==="fillDownValue") {
      this.fillDownValue = value 
    }  

    // Fill All
    else if (event.target.id==="fillAllValue") {
      this.fillAllValue = value 
    } 

    //owners take 
    else if (event.target.id==="monthToStartOwnersTake") {
      this.monthToStartOwnersTake = value 
    }  else if (event.target.id==="firstYearMonthlyNETIncomeNeeds") {
      this.firstYearMonthlyNETIncomeNeeds = value 
    }  else if (event.target.id==="federalIncomeTax") {
      this.federalIncomeTax = value 
    }  else if (event.target.id==="stateIncomeTax") {
      this.stateIncomeTax = value 
    } 

    //other revenue
    else if (event.target.id==="existingAUM") {
      this.existingAUM = value 
    } 
    /*  else if (event.target.id==="percentageofNetIncome") {
      this.percentageofNetIncome = value 
    }   */    
}

  
  //#endregion

  exitBtnClickEvent()
  {
    this.router.navigateByUrl('landingfp4fa'); 
  }
}




