import { Injectable } from '@angular/core';
import { Constants } from '../util/constants';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Agent } from '../models/agent.model';

@Injectable({
  providedIn: 'root'
})
export class ReportsDataService {

  constructor(private http: HttpClient) { }

  	getreports(loggedAgent:Agent, reporttype:string): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		var agentID = loggedAgent.AgentID
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
		}
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getsavedreports/' + agentID + '/' + reporttype,null , httpOptions).pipe()
	}

	getsavedreportforpreview(savedreportid : string, reporttype : string, loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/pdf', 
				 'Content-type': 'application/json' 
				 
			}),
			responseType: 'arraybuffer'
			//responseType: 'application/pdf'
		}   
 
		 return this.http.post(environment.server_URL+ environment.router_prefix + '/getsavedreportforpreview/' + savedreportid + "/" + reporttype,null , {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/pdf', 
				 'Content-type': 'application/json' 				 
			}),
			responseType: 'arraybuffer' 
		}) // had to add inline httpoptions
		// return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getgeneratedpdf/' + choiceid,JSON.stringify(client) , httpOptions )	
	}

	getcombinedsavedreportforpreview(savedreportids : any, loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/pdf', 
				 'Content-type': 'application/json' 
				 
			}),
			responseType: 'arraybuffer'
			//responseType: 'application/pdf'
		}   
 
		 return this.http.post(environment.server_URL+ environment.router_prefix + '/getcombinedsavedreportforpreview',JSON.stringify(savedreportids), {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/pdf', 
				 'Content-type': 'application/json' 				 
			}),
			responseType: 'arraybuffer' 
		}) // had to add inline httpoptions
		// return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/getgeneratedpdf/' + choiceid,JSON.stringify(client) , httpOptions )	
	}

	deletesavedreport(savedreportid: string, reporttype : string, loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		var agentID = loggedAgent.AgentID
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
		}
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/deletesavedreport/' + savedreportid + "/" + reporttype,null , httpOptions).pipe()
	}
}
