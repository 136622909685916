/**
 * This service is use to communicate between components
 * using output
 */

import { Injectable } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

export interface IHash{
	[key:string]:boolean;
}

export interface BreadCrumbHash{
	[key:string]:Array<any>;
}
 

@Injectable({
	providedIn: 'root'
})

export class SharedServiceService {

	constructor() { }

	@Output() changeSideBar: EventEmitter<any> = new EventEmitter()
	@Output() loadCalculator: EventEmitter<any> = new EventEmitter()
	@Output() changeComponents: EventEmitter<any> = new EventEmitter()


	public changeSideBarRequest() {
		this.changeSideBar.emit()
	}
	public changeSideBarRespond() {
		return this.changeSideBar
	}

	public loadCalculatorRequest() {
		this.loadCalculator.emit()
	}
	public loadCalculatorRespond() {
		return this.loadCalculator
	}

	public changeComponentsRequest(componentName: string) {
		this.changeComponents.emit(
			{
				componentName: componentName
			}
		)
	} 
	 
	public changeComponentsRespond() {
		return this.changeComponents
	}

}
