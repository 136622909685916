import { Injectable } from '@angular/core';
import { Constants } from '../util/constants';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Agent } from '../models/agent.model';
import { TickerBlend, TickerBlendDetail } from '../models/ticker-blend.model';

@Injectable({
	providedIn: 'root'
})
export class TickerBlendDataService {

	constructor(private http: HttpClient) { }

	/**
	 * this is the sample method to get data from server
	 * hardcoded URL need to put 
	 */
	gettickerblends(loggedAgent:Agent, ismytickerblend:string): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		var agentID = loggedAgent.AgentID
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
		}
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/gettickerblends/' + agentID + '/' + ismytickerblend,null , httpOptions).pipe()
	}

	gettickers(loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				'authorization': auth
			})
		}
		return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/gettickers',null , httpOptions).pipe()
	}

	gettickerblenddetailsbyid(TickerBlendID : string ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/gettickerblenddetailsbyid/' + TickerBlendID,null , httpOptions).pipe()
	}

	gettiingotickermetadata(TickerSymbol : string ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/gettiingotickermetadata/' + TickerSymbol,null , httpOptions).pipe()
	}

	saveTickerBlend(TickerBlend : TickerBlend ,loggedAgent:Agent): Observable<any> {
		var auth: string ='basic' + ' ' + btoa(loggedAgent.Email + ':' + loggedAgent.Password)
		const httpOptions = {
			headers: new HttpHeaders({ 
				 'authorization': auth,
				 'Accept': 'application/json',
				 'Content-type': 'application/json'
			})
        }
		 return this.http.post<any>(environment.server_URL+ environment.router_prefix + '/savetickerblend',JSON.stringify(TickerBlend) , httpOptions).pipe()
	}
}