import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-custom-cell',
  templateUrl: './custom-cell.component.html'
})

export class CustomCellComponent implements OnInit {
  data: any;
  params: any;
  constructor(private http: HttpClient, private router: Router) {}

  agInit(params) {
    this.params = params;
    this.data = params.value;
  }

  ngOnInit() {}

   view() {
      this.params.context.componentParent.viewBtnClickEvent(this.params.data);
   }

   delete(): void {
    this.params.context.componentParent.deleteBtnClickEvent(this.params.data);
  }

  print(): void {
    this.params.context.componentParent.printBtnClickEvent(this.params.data);
  }
}

export type CellAction = (params) => void;